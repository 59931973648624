import * as React from 'react'

import { FeedingClientsCreateDialog } from '@modules/feeding/clients/create'
import { ReportsGeneratorFeedingClient } from '@modules/reports/user-reports/reports-generator-feeding-client'
import { ReportsGeneratorFeedingSpecialGuest } from '@modules/reports/user-reports/reports-generator-feeding-special-guest'
import { ReportsGeneratorFeedingWorker } from '@modules/reports/user-reports/reports-generator-feeding-worker'
import { ApartmentHousekeepingStatusDialog } from '@modules/housekeeping/cleaning-orders/apartment-housekeeping-status-dialog'
import { CreateCleaningOrderModal } from '@modules/housekeeping/cleaning-orders/create-cleaning-order/create-cleaning-order-modal'
import { PackageVipPaymentsAddDialog } from '@modules/promotions/package-vip/details/payments/package-vip-payments-add-dialog'
import { PackageVipPaymentsAddCodeDialog } from '@modules/promotions/package-vip/details/payments/package-vip-payments-add-code-dialog'
import { ReportsGeneratorFeeding } from '@modules/reports/user-reports/reports-generator-feeding'
import { ReportsGeneratorPackageVip } from '@modules/reports/user-reports/reports-generator-package-code'
import { ReportsGeneratorErliCode } from '@modules/reports/user-reports/reports-generator-erli-code'
import { PackageCodeConvertModal } from '@modules/package/details/codes/convert-modal'
import { ReportsGeneratorShopProduct } from '@modules/reports/user-reports/reports-generator-shop-product'
import { ReportsGeneratorStatsSaleItem } from '@modules/reports/user-reports/reports-generator-stats-sale-item'
import { ReportsGeneratorBookingPayment } from '@modules/reports/user-reports/reports-generator-booking-payment'
import { ReportsGeneratorLoadNote } from '@modules/reports/user-reports/reports-generator-load-note'
import { ReportsGeneratorReservation } from '@modules/reports/user-reports/reports-generator-reservation'
import { ReportsGeneratorClient } from '@modules/reports/user-reports/reports-generator-client'
import { ReportsGeneratorSubscription } from '@modules/reports/user-reports/reports-generator-subscription'
import { HousekeepingApplicationFaultsCreateModal } from '@modules/housekeeping/applications/fault/create-modal'
import { HousekeepingApplicationFaultsPreviewModal } from '@modules/housekeeping/applications/fault/preview-modal'
import { HousekeepingApplicationIssueCreateModal } from '@modules/housekeeping/applications/issue/create-modal'
import { HousekeepingApplicationIssuesPreviewModal } from '@modules/housekeeping/applications/issue/preview-modal'
import { TransferPackagePreviewModal } from '@modules/accounting/refund/transfer-packages/preview'
import { TransferPackageEditModal } from '@modules/accounting/refund/transfer-packages/edit'
import { ReceptionBookingCheckInImprovementAddVipDialog } from '@modules/reception/checkin/step-improvements/vip/dialogs/reception-booking-check-in-improvement-add-vip-dialog'
import { ReceptionBookingCheckInDialog } from '@modules/reception/checkin/reception-booking-check-in-dialog'
import { ReceptionBookingCheckInDateConfirmDialog } from '@modules/reception/checkin/reception-booking-check-in-date-confirm-dialog'
import { GastroCardCodeCreateDialog } from '@modules/promotions/gastro-cards/gastro-card-details/card-codes/gastro-card-code-create-dialog'
import { PackageVipDetails } from '@modules/promotions/package-vip/details'
import { PackageVipAssignmentDialog } from '@modules/promotions/package-vip/details/assignment'
import { GastroCardDetailsDialog } from '@modules/promotions/gastro-cards/gastro-card-details/gastro-card-details-dialog'
import { GastroCardDialog } from '@modules/promotions/gastro-cards/gastro-card-dialog/gastro-card-dialog'
import { ClientDialog } from '@modules/promotions/gastro-cards/gastro-card-details/client-dialog'
import { PackageVipDialog } from '@modules/promotions/package-vip/add/package-vip-dialog'
import { HousekeepingCleaningOrderGroupAssignModal } from '@modules/housekeeping/cleaning-orders/to-order/group-assign-modal'
import { HousekeepingCleaningOrderAssignModal } from '@modules/housekeeping/cleaning-orders/common/assign-modal'
import { BeddingUserDialog } from '@modules/housekeeping/cleaning-orders/bedding/bedding-user-dialog'
import { ReceptionMetersReadingDialog } from '@modules/reception/reception-meters-reading-dialog'
import { HousekeepingCleaningOrderDetailsModal } from '@modules/housekeeping/cleaning-orders/details'
import { HousekeepingCleaningOrderToCheckReopen } from '@modules/housekeeping/cleaning-orders/to-check/reopen-modal'
import { CreateImprovementOrderModal } from '@modules/housekeeping/cleaning-orders/create-improvement-order/create-improvement-order-modal'
import { FeedingWorkersDialog } from '@modules/feeding/workers/dialog'
import { FeedingSpecialGuestDialog } from '@modules/feeding/special-guests/dialog'
import { FeedingHostApartmentInfoReservationDetailsDialog } from '@modules/feeding/host/apartment-info/feeding-details-dialog'
import { FeedingHostGuestsModal } from '@modules/feeding/host/guests-modal'
import { FeedingHostSpecialGuestsModal } from '@modules/feeding/host/guests-modal/special'
import { ReceptionBookingDetailsModal } from '@modules/reception/details/reception-booking-details-modal'
import { ReceptionParkingNumbersDialog } from '@modules/reception/reception-parking-numbers-dialog'
import { ScanningDialog } from '@modules/reception/checkin/step-guests/bands/scanning-dialog'
import { ReceptionBookingCheckOutDateConfirmDialog } from '@modules/reception/checkout/reception-booking-check-out-date-confirm-dialog'
import { ReceptionBookingCheckOutDialog } from '@modules/reception/checkout/reception-booking-check-out-dialog'
import { ReceptionBookingExtendedStayDialog } from '@modules/reception/extended-stay/reception-booking-extended-stay-dialog'
import { ReceptionBookingExtendedStayWizardDialog } from '@modules/reception/extended-stay/reception-booking-extended-stay-wizard-dialog'
import { ReceptionNotificationDialog } from '@modules/reception/notification'
import { ReceptionBandScanDialog } from '@modules/reception/reception-band-scan-dialog'
import { StepGuestGroupDialog } from '@modules/reception/checkin/step-guests/group/step-guest-group-dialog'
import { ReceptionBookingCheckInImprovementRemoveVipDialog } from '@modules/reception/checkin/step-improvements/vip/dialogs/reception-booking-check-in-improvement-remove-vip-dialog'
import { ReceptionNotificationEmailDialog } from '@modules/reception/common/reception-notification-email-dialog'
import { ReceptionNotificationSmsDialog } from '@modules/reception/common/reception-notification-sms-dialog'
import { ReportsApplicationOrders } from '@modules/reports/user-reports/reports-generator-application-orders'
import { ReportsBookingReservationDiscount } from '@modules/reports/user-reports/reports-generator-booking-reservation-discount'
import { RecommendationGiftDialog } from '@modules/crm/recommendation-gifts/dialog'
import { RecommendationGiftDetailsDialog } from '@modules/crm/recommendation-gifts/recommendation-gift-details-dialog'
import { SubscriptionCreateModal } from '@modules/subscription/create/modal'
import { SubscriptionProductCreateModal } from '@modules/subscription/products/create/modal'
import { PromocodePrefixDetailsDialog } from '@modules/promocode/promocode-prefixes/promocode-prefix-details-dialog'
import { PromocodePrefixDialog } from '@modules/promocode/promocode-prefixes/promocode-prefix-dialog/promocode-prefix-dialog'
import { SubscriptionDetailsModal } from '@modules/subscription/details'
import { ClientDetailsModal } from '@modules/crm/clients/details-modal'
import { SubscriptionPaymentCreateModal } from '@modules/subscription/details/payment-create-modal'
import { SubscriptionChangeDataModal } from '@modules/subscription/options/subscription-change-data-modal'
import { SubscriptionSingleChangeDateModal } from '@modules/subscription/options/subscription-single-change-date-modal'
import { SubscriptionSingleChangeDataModal } from '@modules/subscription/options/subscription-single-change-data-modal'
import { SubscriptionEditModal } from '@modules/subscription/edit-modal'
import { SubscriptionCodeConvertModal } from '@modules/subscription/details/code-convert'
import { SubscriptionTouristVoucherModal } from '@modules/subscription/details/tourist-voucher-modal'
import { SubscriptionCodeEditModal } from '@modules/subscription/details/code-edit'
import { SubscriptionChangeLocalizationModal } from '@modules/subscription/options/subscription-change-localization-modal'
import { CmsReservationAppAccommodationTypeModal } from '@modules/cms/reservation-app/accommodation-type-modal'
import { AccountUserSetPinCodeModal } from '@modules/account/users/set-pin-code-modal'
import { CmsReservationAppResortModal } from '@modules/cms/reservation-app/resort-modal'
import { CmsReservationAppFaqModal } from '@modules/cms/reservation-app/faq-modal'
import { CmsReservationAppAttractionModal } from '@modules/cms/reservation-app/attraction-modal'
import { CmsReservationAppTextBlockModal } from '@modules/cms/reservation-app/text-block-modal'
import { CmsReservationAppImprovementModal } from '@modules/cms/reservation-app/improvement-modal'
import { NotificationTemplatesSmsModal } from '@modules/cms/notification-templates/sms/modal'
import { NotificationTemplatesEmailModal } from '@modules/cms/notification-templates/email/modal'
import { ClientCreateModal } from '@modules/crm/clients/create-modal'
import { ClientProfileGuestModal } from '@modules/crm/clients/client-profile-guest-modal'
import { PackageDetailsModal } from '@modules/package/details'
import { PackageCreateModal } from '@modules/package/create/modal'
import { ReservationCreateModal } from '@modules/reservations/create'
import { PackagePaymentCreateModal } from '@modules/package/details/payments/payment-create-modal'
import { PackageCodeEditModal } from '@modules/package/details/codes/edit-modal'

import { ReportsGeneratorPackagesModal } from '@modules/reports/user-reports/reports-generator-packages'
import { PackageInvoiceCreateModal } from '@modules/package/details/payments/invoice-create-modal'
import { AddSellerModal } from '@modules/seller/add-seller-modal'
import { CreateDocumentModal } from '@components/documents/create-document-modal'
import { ReportsGeneratorAlternativeFeeding } from '@modules/reports/user-reports/reports-generator-alternative-feeding'
import { SubscriptionPaymentUpdateModal } from '@modules/subscription/details/payment-update-modal'
import { PackageParamsEditModal } from '@modules/package/details/params'
import { ReportsPaymentRegisterModal } from '@modules/reports/user-reports/reports-generator-payment-register'
import { CashRegisterStateListModal } from '@modules/sale/cash-register/list/state-list-modal'
import { CashRegisterReportModal } from '@modules/sale/cash-register/list/report-modal'
import { CashRegisterAddModal } from '@modules/sale/cash-register/list/add-modal'
import { ReportsGeneratorGastroCard } from '@modules/reports/user-reports/reports-generator-gastro-card'
import { ReportsGeneratorCashRegister } from '@modules/reports/user-reports/reports-generator-cash-register'
import { ReportsGeneratorShopReceipt } from '@modules/reports/user-reports/reports-generator-shop-receipt'
import { PackageWholesaleProductCreateModal } from '@modules/package-wholesale/products/create/modal'
import { PackageWholesaleCreateModal } from '@modules/package-wholesale/create/modal'
import { PackageWholesaleDetailsModal } from '@modules/package-wholesale/details'
import { PackageWholesaleProductUpdateModal } from '@modules/package-wholesale/products/update'
import { SubscriptionGenerateProforma } from '@modules/subscription/generate-proforma'
import { AccountUserDesktopModal } from '@modules/account/user-desktop-modal'
import { BarrierTicketModal } from '@modules/barriers/barrier-ticket-modal'
import { CmsReservationAppResortMapModal } from '@modules/cms/reservation-app/resort-modal/maps/modal'
import { GastroCardWalletItemsMove } from '@modules/promotions/gastro-cards/gastro-card-details/gastro-card-wallet-items/gastro-card-wallet-items-move'
import { CashCountModal } from '@modules/payment-registers/cash-count-modal'
import { PaymentRegisterPointModal } from '@modules/payment-registers/cashbox-day-details/payment-register-point-modal'
import { PaymentRegisterReceptionCasketStartModal } from '@modules/payment-registers/cashbox-day-details/payment-register-reception-casket-start-modal'
import { CashboxSafeModal } from '@modules/payment-registers/cashbox-safe-modal'
import { PaymentUserOpenDayModal } from '@modules/payment-registers/payment-user-open-day-modal'
import { ReportsGeneratorPaymentMove } from '@modules/reports/user-reports/reports-generator-payment-move'
import { SubscriptionDetailsCodesConvertModal } from '@modules/subscription/details/codes-convert-modal'
import { UserCashboxCloseDayModal } from '@modules/payment-registers/user-cashbox/close-day-modal'
import { TransactionListModal } from '@modules/payment-registers/transaction-list-modal'
import { BandExtensionInformationModal } from '@modules/reception/checkin/step-improvements/band-extension-information-modal'
import { CmsAssetCategoryModal } from '@modules/cms/assets/categories/modal'
import { CmsAssetModal } from '@modules/cms/assets/list/modal'
import { ReportsHousekeepingCleaningOrderModal } from '@modules/reports/user-reports/report-generator-housekeeping/reports-housekeeping-cleaning-order-modal'
import { ReportsHousekeepingUsersModal } from '@modules/reports/user-reports/report-generator-housekeeping/reports-housekeeping-users-modal'
import { ReportsHousekeepingUserModal } from '@modules/reports/user-reports/report-generator-housekeeping/reports-housekeeping-user-modal'
import { BookingImprovementAddByCodeModal } from '@modules/reception/checkin/step-improvements/helicopter-flight/moda'
import { ReceptionPinPadPaymentSuccessModal } from '@modules/reception/common/pin-pad/reception-pin-pad-payment-success-modal'
import { SpecialWorkingTimeModal } from '@modules/housekeeping/cleaning-orders/common-tasks/special-working-time'
import { SmartWatchAddModal } from '@modules/reception/checkin/step-improvements/smart-watch-add-modal'
import { ReceptionPinPadPreauthorizationInfoModal } from '@modules/reception/common/pin-pad/reception-pin-pad-preauthorization-info-modal'
import { TransferDepositModal } from '@modules/reception/checkout/step-settlement/transfer-deposit-modal'
import { ReceptionBookingCheckInPreviewDialog } from '@modules/reception/checkin/reception-booking-check-in-preview-dialog'
import { ReceptionBookingCheckOutPreviewDialog } from '@modules/reception/checkout/preview/reception-booking-check-out-preview-dialog'
import { PromocodeTouristVoucherCreateDialog } from '@modules/promotions/promocode-tourist-voucher/create-dialog'
import { PromocodeTouristVoucherDetailsDialog } from '@modules/promotions/promocode-tourist-voucher/details-dialog'
import { PromocodeTouristVoucherInfoEditDialog } from '@modules/promotions/promocode-tourist-voucher/info-edit-dialog'
import { PromocodeTouristVoucherCodesAddDialog } from '@modules/promotions/promocode-tourist-voucher/details-dialog/codes-add-dialog'
import { ReportResortBand } from '@modules/reports/user-reports/reports-generator-resort/report-resort-bands'
import { ReceptionBookingCheckInStepDocumentsSigningInProgressModal } from '@modules/reception/checkin/step-documents/step-documents-signing-in-progress-modal'
import { Przelewy24SendDialog } from '@modules/accounting/refund/przelewy24-send-dialog'
import { TabletSelectionModal } from '@components/tablet-selection-modal'
import { ReportsReservationBillModal } from '@modules/reports/user-reports/reports-generator-reservation-bill'
import { TimelineFiltersModal } from '@modules/reservations/timeline/modals/timeline-filters-modal'
import { ReportsApartmentMeterModal } from '@modules/reports/user-reports/reports-generator-apartment-meter'
import { PaymentDAyUserReportOperationModal } from '@modules/payment-registers/cashbox-day-details/payment-day-user-report-operation-modal'
import { ReportsGastroCardVatModal } from '@modules/reports/user-reports/reports-generator-gastro-card-vat'
import { ReportsMarketingRODOModal } from '@modules/reports/user-reports/reports-generator-marketing-rodo'
import { ReportsPackageWholesaleCodeModal } from '@modules/reports/user-reports/reports-generator-package-wholesale'
import { LocalSelectionMapModal } from '@modules/reservations/create/local-selection-map/local-selection-map-modal'
import { ReservationBlockadeModal } from '@modules/reservations/blockade/reservation-blockade-modal'
import { PackageWholesaleDetailsMassNotificationsModal } from '@modules/package-wholesale/details/notification/mass-notifications/mass-notifications-modal'
import { ReportsReservationBillItemModal } from '@modules/reports/user-reports/report-generator-reservation-bill-item'
import { ReportsReservationLatePaymentModal } from '@modules/reports/user-reports/reports-generator-reservation-late-payment-deposit'
import { ReportPromocodeTouristVoucherModal } from '@modules/reports/user-reports/reports-generator-promocode-tourist-voucher'
import { SubscriptionDetailsCodesGlobalMergeModal } from '@modules/subscription/details/codes-global-merge-modal'
import { SubscriptionContractCreateModal } from '@modules/subscription-contract/create/modal'
import { SubscriptionContractDetailsModal } from '@modules/subscription-contract/details'
import { PaymentCardDetailsModal } from '@components/payment-card-details-modal'
import { ReportsGeneratorShopReceiptProducts } from '@modules/reports/user-reports/reports-generator-shop-receipt-products'
import { RegenerateUserReportConfirmationModal } from '@modules/reports/user-reports/regenerate-user-report-confirmation-modal'
import { ReportZooTicketsModal } from '@modules/reports/user-reports/reports-generator-zoo-tickets'
import { ReportSuntagoTicketsModal } from '@modules/reports/user-reports/reports-generator-suntago-tickets'
import { ReportsContractModal } from '@modules/reports/user-reports/reports-generator-contract'
import { ReservationBlockadeSuccessModal } from '@modules/reservations/blockade/result/reservation-blockade-success-modal'
import { ReservationDepositReturnModal } from '@modules/reservations/details/deposit-return/modal'
import { SubscriptionEntranceTickets } from '@modules/subscription/options/subscription-entrance-ticket'
import { ReportsPackageFullCodeModal } from '@modules/reports/user-reports/reports-generator-package-full-code'
import { ReservationExtraChargesModal } from '@modules/reservations/details/extra-charges/modal'
import { SubscriptionSingleChangeLocalizationModal } from '@modules/subscription/options/subscription-single-change-localization-modal'
import { ReservationBistroVoucherAddModal } from '@modules/reservations/details/bistro-vouchers/modals/reservation-bistro-voucher-add-modal'
import { ReservationBistroVoucherUseModal } from '@modules/reservations/details/bistro-vouchers/modals/reservation-bistro-voucher-use-modal'
import { ReportsClimaticModal } from '@modules/reports/user-reports/report-generator-climatic/reports-generator-climatic'
import { ReservationPromocodeModal } from '@modules/reservations/details/modals/promocode'
import { ReservationFeedingPromotionModal } from '@modules/reservations/details/modals/feeding-promotion'
import { ReservationHprDiscountModal } from '@modules/reservations/details/modals/hpr-discount'
import { ReservationDisableAutoCancelModal } from '@modules/reservations/details/modals/disable-auto-cancel'
import { ReservationMarketingModal } from '@modules/reservations/details/modals/marketing'
import { ReservationRoomBillTransferModal } from '@modules/reservations/details/reservation-room-bill/modals/reservation-room-bill-transfer-modal'
import { ReservationRoomBillTransactionsListModal } from '@modules/reservations/details/reservation-room-bill/modals/reservation-room-bill-transactions-list-modal'
import { ConfirmModal } from '@hyper/modals/confirm-modal'
import { RentalProductCreateModal } from '@modules/rental/products/modals/rental-products-create-modal'
import { RentalProductsImportModal } from '@modules/rental/products/modals/rental-products-import-modal'
import { RentalProductDetailsModal } from '@modules/rental/products/modals/details/rental-product-details-modal'
import { RentalWarehouseCreateModal } from '@modules/rental/warehouses/modals/rental-warehouse-create-modal'
import { RentalProductCategoryCreateModal } from '@modules/rental/product-categories/modals/rental-product-category-create-modal'
import { ReservationClientChangeDialog } from '@modules/reservations/details/guest-reservation-data/reservation-client-change-dialog'
import { ReservationLoadNoteAddModal } from '@modules/reservations/details/load-notes/modals/reservation-load-note-add-modal'
import { ReservationLoadNotePreviewModal } from '@modules/reservations/details/load-notes/modals/reservation-load-note-preview-modal'
import { ReservationLoadNoteEditModal } from '@modules/reservations/details/load-notes/modals/reservation-load-note-edit-modal'
import { CafeteriaCreateModal } from '@modules/cafeteria/create/modal'
import { CafeteriaDetailsModal } from '@modules/cafeteria/details'
import { ReportsSubscriptionContractModal } from '@modules/reports/user-reports/report-generator-subscription-contract'
import { PromocodeGroupDetailsDialog } from '@modules/promocode/promocode-list/promocode-groups/promocode-group-details-dialog'
import { PromocodeGroupEditDialog } from '@modules/promocode/promocode-list/promocode-edit/promocode-group-edit-dialog'
import { PromocodeGroupGenerateCodesDialog } from '@modules/promocode/promocode-list/promocode-groups/promocode-group-generate-codes-dialog'
import { PromocodeCreateDialog } from '@modules/promocode/promocode-list/promocode-create/promocode-create-dialog'
import { PromocodeCodeSendDialog } from '@modules/promocode/promocode-list/promocode-codes/promocode-code-send-dialog'
import { PromocodeCodeUsageDetailsDialog } from '@modules/promocode/promocode-list/promocode-codes/promocode-code-usage-details-dialog'
import { PaymentCloseDayModal } from '@modules/payment-registers/payment-close-day-modal'
import { ReservationPaymentAddModal } from '@modules/reservations/details/payment-history/modals/payment/reservation-payment-add-modal'
import { PackageWholesaleProductStatusModal } from '@modules/package-wholesale/products/status-modal'
import { ShopReceiptNotFulfilledModal } from '@modules/shop/pos/shop-receipt-not-fulfilled-modal'
import { ReportsBookingsStatisticsModal } from '@modules/reports/user-reports/report-booking-statistics/reports-bookings-statistics'
import { HousekeepingOrderNoteModal } from '@modules/housekeeping/cleaning-orders/common/housekeeping-order-note-modal'
import { SubscriptionProductStatusModal } from '@modules/subscription/products/status-modal'
import { HousekeepingUserModal } from '@modules/housekeeping/users/modal'
import { AccountUserChangePassword } from '@modules/account/users/change-password-modal'
import { CallCenterUserModal } from '@modules/account/call-center-users/modal'
import { ReceptionistUserModal } from '@modules/account/receptionist-users/modal'
import { ReservationPaymentTransferModal } from '@modules/reservations/details/payment-history/modals/payment/reservation-payment-transfer-modal'
import { AgreementCreateModal } from '@modules/hr/agreement/create/modal'
import { AgreementDetailsModal } from '@modules/hr/agreement/details'
import { AgreementEditModal } from '@modules/hr/agreement/edit'
import { ReportsPermissionModal } from '@modules/reports/user-reports/reports-generator-permission'
import { ReservationPaymentSynchronizationStatusModal } from '@modules/reservations/details/payment-history/modals/payment/reservation-payment-synchronization-status-modal'
import { ReportsCommonMetersModal } from '@modules/reports/user-reports/reports-generator-common-meters'
import { ReportsGastroCouponModal } from '@modules/reports/user-reports/reports-generator-gastro-coupon'
import { SubscriptionExpirationExtendModal } from '@modules/subscription/options/subscription-expiration-extension-modal'
import { ReceptionBookingCartModal } from '@modules/reception/cart/reception-booking-cart-modal'
import { GastroGenericProductDialog } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-product-dialog'
import { TimelineBlockadeRemoveModal } from '@modules/reservations/timeline/modals/timeline-blockade-remove-modal'
import { ServiceBandCreateDialog } from '@modules/bands/service-bands/create-dialog'
import { ServiceBandDetailsDialog } from '@modules/bands/service-bands/details-dialog'
import { ServiceBandInfoEditDialog } from '@modules/bands/service-bands/info-edit-dialog'
import { SubscriptionCodeKindConvertModal } from '@modules/subscription/options/subscription-code-kind-convert-modal'
import { FloorHeatingDetailsParametersModalChange } from '@modules/floor-heating/modals/parameter-change-modal'
import { ReservationChangeDatesDialog } from '@modules/reservations/details/reservation-data/reservation-change-dates-dialog'
import { ReceptionBookingNewFeedingModal } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-feeding-modal'
import { ReportSmsNotificationsModal } from '@modules/reports/user-reports/reports-generator-nofifications/reports-sms-notifications-modal'
import { ReportsFloorHeatingModal } from '@modules/reports/user-reports/reports-generator-floor-heating'
import { ReportSubscriptionConversionModal } from '@modules/reports/user-reports/reports-generator-subscription-conversion/reports-subscription-conversion-modal'
import { ReportsMarketingRODOCanceledModal } from '@modules/reports/user-reports/reports-generator-marketing-rodo-cancelled'
import { AgreementStatusChangeModal } from '@modules/hr/agreement/details/status-change-modal'
import { AgreementCreationDiscardModal } from '@modules/hr/agreement/create/agreemenet-creation-discard-modal'

import { TechnicalOrderModal } from '@modules/technical-orders/technical-order-modal'
import { TechnicalOrderAssignModal } from '@modules/technical-orders/technical-order-assign-modal'
import { TechnicalOrderCloseModal } from '@modules/technical-orders/technical-order-close-modal'
import { TechnicalOrderWorkTimeModal } from '@modules/technical-orders/technical-order-work-time-modal'
import { ReceptionBookingNewFeedingDiscountModal } from '@modules/reception/checkin/step-feeding/new-feeding/discounts/reception-booking-new-feeding-discount-modal'
import { CouponTypeCreateModal } from '@modules/coupons/types/modal/coupon-type-create-modal'
import { TechnicalOrderRejectModal } from '@modules/technical-orders/technical-order-reject-modal'
import { TechnicalOrderPreviewModal } from '@modules/technical-orders/technical-order-preview-modal'
import { CashbackVoucherModal } from '@modules/products/cashback-vouchers/cashback-voucher-modal'
import { ReceptionTechnicalOrderModal } from '@modules/reception-technical-orders/reception-technical-order-modal'
import { ReceptionTechnicalOrderStatusModal } from '@modules/reception-technical-orders/reception-technical-order-status-modal'
import { GuestsTechnicalOrderModal } from '@modules/guests-technical-orders/guests-technical-order-modal'
import { GuestsTechnicalOrderStatusModal } from '@modules/guests-technical-orders/guests-technical-order-status-modal'
import { BookingOptionCreateDialog } from '@modules/promotions/booking-options/create/booking-option-create-dialog'
import { BookingOptionDetailsDialog } from '@modules/promotions/booking-options/details/booking-option-details-dialog'
import { ReceptionBookingNewFeedingPriceListModal } from '@modules/reception/checkin/step-feeding/new-feeding/pricelist/reception-booking-new-feeding-pricelist-modal'
import { ReportsGeneratorFeedingSoldMeals } from '@modules/reports/user-reports/reports-generator-feeding-sold-meals'
import { ReportTechnicalOrdersModal } from '@modules/reports/user-reports/reports-generator-technical-orders/reports-technical-orders-modal'
import { GenericProductPaymentsAddDialog } from '@modules/generic-product-payments/dialog'
import { UpsellBookingsDateFiltersModal } from '@modules/upselling/upsell-bookings/filters/modal/upsell-bookings-date-filters-modal'
import { UpsellBookingsStatusChangeModal } from '@modules/upselling/upsell-bookings/status-change/upsell-bookings-status-change-modal'
import { UpsellBookingAddNoteModal } from '@modules/upselling/upsell-booking-details/upsell-booking-note-add-modal'
import { CouponTypeConfigurationCreateModal } from '@modules/coupons/types-configuration/modal/coupon-type-configuration-create-modal'
import { CouponItemDetailsModal } from '@modules/coupons/list/client-details/modal/details/coupon-item-details-modal'
import { CouponItemEditDetailsModal } from '@modules/coupons/list/client-details/modal/coupon-item-edit-modal'
import { RodoAgreementsModal } from '@modules/crm/rodo-agreements/benefits/modal/rodo-agreements-modal'
import { RodoAgreementsIssueConfirmationModal } from '@modules/crm/rodo-agreements/benefits/modal/rodo-agreements-issue-confirmation-modal'
import { ReceptionBookingCheckOutShorteningStayConfirmDialog } from '@modules/reception/checkout/reception-booking-check-out-shortening-stay-confirm-dialog'
import { UpSellUserModal } from '@modules/upselling/upsell-users/assign-modal'
import { RodoAgreementsModalClientDataEdit } from '@modules/crm/rodo-agreements/benefits/modal/rodo-agreements-modal-client-data-edit'
import { RodoAgreementsAcquiringPointCreateModal } from '@modules/crm/rodo-agreements/point-of-acquirng/create-modal/rodo-agreements-acquiring-point-create-modal'
import { HrWorkerCreateModal } from '@modules/hr/workers/create/modal'
import { HrWorkerDetailsModal } from '@modules/hr/workers/details'
import { ShopParkingConfigurationModal } from '@modules/shop/pos/product-selection/product-configuration-modals/shop-parking-configuration-modal'
import { GenericVoucherDetailsModal } from '@modules/generic-vouchers/details'
import { BandScanningTimeConfigurationModal } from '@components/band-scanning/configuration/modals/band-scanning-time-configuration-modal'
import { BandScanningScannerConfigurationModal } from '@components/band-scanning/configuration/modals/band-scanning-scanner-configuration-modal'
import { AgreementAnnexDetailsModal } from '@modules/hr/agreement/details/annexes/annex-details-modal'
import { ReportGenericVoucherCode } from '@modules/reports/user-reports/reports-generator-generic-voucher-code/reports-generic-voucher-code-modal'
import { AgreementHourlyRateAnnexModal } from '@modules/hr/agreement/annexes/modals/agreemenet-hourly-rate-annex-modal'
import { AgreementWithdrawalAnnexModal } from '@modules/hr/agreement/annexes/modals/agreemenet-withdrawal-annex-modal'
import { ReceptionBookingExtendedStayCollisionWarningModal } from '@modules/reception/extended-stay/reception-booking-extended-stay-collision-warning-modal'
import { ReservationDeclaredArrivalTimeModal } from '@modules/reservations/details/modals/reservation-declared-arrival-time-modal'
import { BenefitProgramCreateModal } from '@modules/benefit-program/create'
import { BenefitProgramUserAddModal } from '@modules/benefit-program/user-add-modal'
import { ReservationCreateDataResetModal } from '@modules/reservations/create/reservation-create-data-reset-modal'
import { ReceptionBookingImprovementSubscriptionModal } from '@modules/reception/checkin/step-improvements/improvement-subscription/modal/reception-booking-improvement-subscription-modal'
import { ReceptionBookingCheckOutUnavailableModal } from '@modules/reception/checkout/checkout-unavailable/reception-booking-check-out-unavailable-modal'
import { NotificationPreviewModal } from '@components/notification-box/notification-preview-modal'
import { HistoryListModal } from '@components/client-history/history-list-modal'
import { ReservationAddVoucherModal } from '@modules/reservations/details/vouchers/modals/add-modal/reservation-add-voucher-modal'
import { BenefitProgramAgreementsCreateModal } from '@modules/benefit-program/details/agreements-create-modal'
import { BenefitProgramAgreementCreateModal } from '@modules/benefit-program/agreements/create/modal'
import { ReportGenericCourtyardOccupancyModal } from '@modules/reports/user-reports/reports-generator-courtyard-occupancy/reports-generic-courtyard-occupancy-modal'
import { ReservationDepositChangeModal } from '@modules/reservations/details/deposit/modals/reservation-deposit-change-modal'
import { ReportCashbackVoucherModal } from '@modules/reports/user-reports/reports-cashback-voucher-modal'
import { EndlessHolidayCreateModal } from '@modules/endless-holiday/invites/create/modal'
import { EndlessHolidayDetailsModal } from '@modules/endless-holiday/transactions/details/modal'

export type ModalParams = any

type ModalsMapType = Record<string, [React.FC, ModalParams]>

export const ModalsMap: ModalsMapType = {
  SubscriptionProductStatusModal: [SubscriptionProductStatusModal, {}],
  PackageWholesaleProductStatusModal: [PackageWholesaleProductStatusModal, {}],
  PaymentCloseDayModal: [PaymentCloseDayModal, { size: 'semi-lg' }],
  PromocodeGroupCodeRowUsedDialog: [PromocodeCodeUsageDetailsDialog, {}],
  PromocodeSendDialog: [PromocodeCodeSendDialog, { size: 'lg' }],
  CafeteriaCreateModal: [CafeteriaCreateModal, {}],
  ReservationClientChangeDialog: [ReservationClientChangeDialog, { size: '' }],
  ConfirmModal: [ConfirmModal, { size: 'md' }],
  ReservationMarketingModal: [ReservationMarketingModal, {}],
  ReservationDeclaredArrivalTimeModal: [ReservationDeclaredArrivalTimeModal, { size: 'sm' }],
  ReservationDisableAutoCancelModal: [ReservationDisableAutoCancelModal, {}],
  ReservationHprDiscountModal: [ReservationHprDiscountModal, {}],
  ReservationFeedingPromotionModal: [ReservationFeedingPromotionModal, {}],
  ReservationPromocodeModal: [ReservationPromocodeModal, {}],
  ReservationExtraChargesModal: [ReservationExtraChargesModal, {}],
  SubscriptionEntranceTickets: [SubscriptionEntranceTickets, {}],
  ReservationPaymentSynchronizationStatusModal: [ReservationPaymentSynchronizationStatusModal, {}],
  ReservationDepositReturnModal: [ReservationDepositReturnModal, {}],
  PaymentDayUserReportOperationModal: [PaymentDAyUserReportOperationModal, {}],
  ApartmentHousekeepingStatusDialog: [ApartmentHousekeepingStatusDialog, { size: 'xs' }],
  BeddingUserDialog: [BeddingUserDialog, {}],
  PromocodeTouristVoucherCodesAddDialog: [PromocodeTouristVoucherCodesAddDialog, {}],
  CashRegisterAddModal: [CashRegisterAddModal, {}],
  CashRegisterReportModal: [CashRegisterReportModal, {}],
  SmartWatchAddModal: [SmartWatchAddModal, {}],
  TechnicalOrderCloseModal: [TechnicalOrderCloseModal, { size: 'xs' }],
  TechnicalOrderAssignModal: [TechnicalOrderAssignModal, { size: 'xs' }],
  GuestsTechnicalOrderModal: [GuestsTechnicalOrderModal, { size: 'semi-lg' }],
  ReceptionTechnicalOrderModal: [ReceptionTechnicalOrderModal, { size: 'semi-lg' }],
  TechnicalOrderModal: [TechnicalOrderModal, { size: 'semi-lg' }],
  CashbackVoucherModal: [CashbackVoucherModal, { size: 'xl' }],
  CashRegisterStateListModal: [CashRegisterStateListModal, {}],
  ClientDialog: [ClientDialog, {}],
  CmsAssetCategoryModal: [CmsAssetCategoryModal, {}],
  CmsAssetModal: [CmsAssetModal, {}],
  PaymentRegisterReceptionCasketStartModal: [PaymentRegisterReceptionCasketStartModal, {}],
  CreateCleaningOrderModal: [CreateCleaningOrderModal, { size: 'semi-lg' }],
  CreateImprovementOrderModal: [CreateImprovementOrderModal, { size: 'md' }],
  FeedingClientsCreateDialog: [FeedingClientsCreateDialog, { size: 'lg' }],
  FeedingHostApartmentInfoReservationDetailsDialog: [FeedingHostApartmentInfoReservationDetailsDialog, { size: 'lg' }],
  PromocodeCreateDialog: [PromocodeCreateDialog, { size: 'semi-lg' }],
  FeedingHostGuestsModal: [FeedingHostGuestsModal, { size: 'semi-lg' }],
  TransactionListModal: [TransactionListModal, { size: 'lg' }],
  PromocodeGroupGenerateCodesDialog: [PromocodeGroupGenerateCodesDialog, { size: 'semi-lg' }],
  PromocodeGroupEditDialog: [PromocodeGroupEditDialog, { size: 'semi-lg' }],
  PromocodeGroupDetailsDialog: [PromocodeGroupDetailsDialog, { size: 'semi-lg' }],
  FeedingHostSpecialGuestsModal: [FeedingHostSpecialGuestsModal, { size: 'semi-lg' }],
  FeedingSpecialGuestDialog: [FeedingSpecialGuestDialog, {}],
  FeedingWorkersDialog: [FeedingWorkersDialog, { size: 'lg' }],
  GastroCardCodeCreateDialog: [GastroCardCodeCreateDialog, {}],
  PromocodeTouristVoucherInfoEditDialog: [PromocodeTouristVoucherInfoEditDialog, {}],
  SpecialWorkingTimeModal: [SpecialWorkingTimeModal, {}],
  SubscriptionCreateModal: [SubscriptionCreateModal, { size: 'lg', role: 'subscription-modal' }],
  SubscriptionContractCreateModal: [SubscriptionContractCreateModal, { size: 'lg', role: 'subscription-modal' }],
  PackageWholesaleProductUpdateModal: [PackageWholesaleProductUpdateModal, { size: 'semi-lg' }],
  PackageWholesaleProductCreateModal: [PackageWholesaleProductCreateModal, { size: 'semi-lg' }],
  SubscriptionGenerateProforma: [SubscriptionGenerateProforma, {}],
  PaymentRegisterPointModal: [PaymentRegisterPointModal, {}],
  BookingImprovementAddByCodeModal: [BookingImprovementAddByCodeModal, {}],
  AccountUserDesktopModal: [AccountUserDesktopModal, { size: 'semi-lg' }],
  CmsReservationAppResortMapModal: [CmsReservationAppResortMapModal, {}],
  GastroCardDetailsDialog: [GastroCardDetailsDialog, { size: 'xl', id: 'gastro-card-details-dialog' }],
  UpSellUserModal: [UpSellUserModal, { size: 'semi-lg' }],
  Przelewy24SendDialog: [Przelewy24SendDialog, {}],
  GastroCardDialog: [GastroCardDialog, { size: 'lg' }],
  GastroGenericProductDialog: [GastroGenericProductDialog, { size: 'lg' }],
  PromocodeTouristVoucherCreateDialog: [PromocodeTouristVoucherCreateDialog, { size: 'lg' }],
  GastroCardWalletItemsMove: [GastroCardWalletItemsMove, {}],
  HousekeepingApplicationFaultsCreateModal: [HousekeepingApplicationFaultsCreateModal, { size: 'md' }],
  HousekeepingApplicationFaultsPreviewModal: [HousekeepingApplicationFaultsPreviewModal, { size: 'semi-lg' }],
  HousekeepingApplicationIssueCreateModal: [HousekeepingApplicationIssueCreateModal, { size: 'md' }],
  HousekeepingApplicationIssuesPreviewModal: [HousekeepingApplicationIssuesPreviewModal, { size: 'semi-lg' }],
  HousekeepingCleaningOrderAssignModal: [HousekeepingCleaningOrderAssignModal, {}],
  HousekeepingCleaningOrderDetailsModal: [HousekeepingCleaningOrderDetailsModal, { size: 'lg' }],
  HousekeepingCleaningOrderGroupAssignModal: [HousekeepingCleaningOrderGroupAssignModal, {}],
  HousekeepingOrderNoteModal: [HousekeepingOrderNoteModal, { size: 'xs' }],
  SubscriptionDetailsCodesConvertModal: [SubscriptionDetailsCodesConvertModal, {}],
  HousekeepingCleaningOrderToCheckReopen: [HousekeepingCleaningOrderToCheckReopen, {}],
  PackageCodeEditModal: [PackageCodeEditModal, {}],
  PackageDetailsModal: [PackageDetailsModal, { size: 'xl', role: 'package-modal', id: 'package-modal' }],
  PackagePaymentCreateModal: [PackagePaymentCreateModal, {}],
  PackageInvoiceCreateModal: [PackageInvoiceCreateModal, {}],
  PackageVipAssignmentDialog: [PackageVipAssignmentDialog, {}],
  PackageVipDetails: [PackageVipDetails, { size: 'xl', id: 'package-vip-details' }],
  PromocodeTouristVoucherDetailsDialog: [
    PromocodeTouristVoucherDetailsDialog,
    { size: 'xl', id: 'promocode-tourist-voucher-details-dialog' },
  ],
  PackageVipDialog: [PackageVipDialog, { size: 'lg' }],
  PackageVipPaymentsAddCodeDialog: [PackageVipPaymentsAddCodeDialog, { size: 'xs' }],
  PackageVipPaymentsAddDialog: [PackageVipPaymentsAddDialog, { size: 'xs' }],
  PackageCreateModal: [PackageCreateModal, { size: 'lg', role: 'package-modal' }],
  ReceptionBandScanDialog: [ReceptionBandScanDialog, { size: 'modal-semi-md', centered: true }],
  ReceptionBookingCheckInDateConfirmDialog: [ReceptionBookingCheckInDateConfirmDialog, {}],
  CashboxSafeModal: [CashboxSafeModal, { size: 'semi-xl' }],
  CashCountModal: [CashCountModal, { size: 'xl' }],
  ReceptionBookingCheckInDialog: [
    ReceptionBookingCheckInDialog,
    { size: 'lg', keyboard: false, className: 'reception__booking-check-in' },
  ],
  ReceptionBookingCheckInPreviewDialog: [
    ReceptionBookingCheckInPreviewDialog,
    { keyboard: false, className: 'reception__booking-check-in-preview' },
  ],
  ReceptionBookingCheckInImprovementAddVipDialog: [ReceptionBookingCheckInImprovementAddVipDialog, { size: 'md' }],
  ReceptionBookingCheckInImprovementRemoveVipDialog: [
    ReceptionBookingCheckInImprovementRemoveVipDialog,
    { size: 'md', centered: true },
  ],
  ReceptionBookingCheckOutDateConfirmDialog: [ReceptionBookingCheckOutDateConfirmDialog, {}],
  ReceptionBookingCheckOutUnavailableModal: [ReceptionBookingCheckOutUnavailableModal, {}],
  ReceptionBookingCheckOutShorteningStayConfirmDialog: [ReceptionBookingCheckOutShorteningStayConfirmDialog, {}],
  ReceptionBookingCheckOutDialog: [
    ReceptionBookingCheckOutDialog,
    { size: 'lg', keyboard: false, className: 'reception__booking-check-in' },
  ],
  ReceptionBookingCheckOutPreviewDialog: [
    ReceptionBookingCheckOutPreviewDialog,
    { keyboard: false, className: 'reception__booking-check-out-preview' },
  ],
  ReceptionBookingDetailsModal: [ReceptionBookingDetailsModal, { size: 'lg' }],
  PaymentUserOpenDayModal: [PaymentUserOpenDayModal, { size: 'semi-lg', keyboard: false }],
  ReceptionBookingExtendedStayDialog: [ReceptionBookingExtendedStayDialog, { size: 'semi-lg' }],
  ReceptionBookingExtendedStayCollisionWarningModal: [
    ReceptionBookingExtendedStayCollisionWarningModal,
    { size: 'md' },
  ],
  ReceptionBookingExtendedStayWizardDialog: [
    ReceptionBookingExtendedStayWizardDialog,
    { size: 'lg', keyboard: false, role: 'modal', className: 'reception__booking-check-in' },
  ],
  ReceptionMetersReadingDialog: [ReceptionMetersReadingDialog, { size: 'semi-lg' }],
  ReceptionNotificationDialog: [
    ReceptionNotificationDialog,
    {
      size: 'lg',
      role: 'reception-notification-modal',
    },
  ],
  ReceptionNotificationEmailDialog: [
    ReceptionNotificationEmailDialog,
    { size: 'semi-lg', 'data-testid': 'booking-email-notification-modal' },
  ],
  ReceptionNotificationSmsDialog: [
    ReceptionNotificationSmsDialog,
    { size: 'semi-lg', role: 'booking-sms-notification-modal' },
  ],
  UserCashboxCloseDayModal: [UserCashboxCloseDayModal, { size: 'semi-lg' }],
  ReceptionParkingNumbersDialog: [ReceptionParkingNumbersDialog, { size: 'semi-lg' }],
  ReportsGeneratorBookingPayment: [ReportsGeneratorBookingPayment, { size: 'md' }],
  ReportsGeneratorClient: [ReportsGeneratorClient, { size: 'md' }],
  ReportsGeneratorErliCode: [ReportsGeneratorErliCode, { size: 'md' }],
  ReportsGeneratorFeeding: [ReportsGeneratorFeeding, { size: 'md' }],
  ReportsApplicationOrders: [ReportsApplicationOrders, { size: 'md' }],
  ReportsGeneratorFeedingClient: [ReportsGeneratorFeedingClient, { size: 'md' }],
  ReportsGeneratorFeedingSpecialGuest: [ReportsGeneratorFeedingSpecialGuest, { size: 'md' }],
  ReportsGeneratorFeedingSoldMeals: [ReportsGeneratorFeedingSoldMeals, { size: 'md' }],
  ReportsGeneratorFeedingWorker: [ReportsGeneratorFeedingWorker, { size: 'md' }],
  ReportsGeneratorLoadNote: [ReportsGeneratorLoadNote, { size: 'md' }],
  ReportsGeneratorPackageVip: [ReportsGeneratorPackageVip, { size: 'md' }],
  ReportsGeneratorReservation: [ReportsGeneratorReservation, { size: 'md' }],
  ReportsGeneratorShopProduct: [ReportsGeneratorShopProduct, { size: 'md' }],
  ReportsGeneratorStatsSaleItem: [ReportsGeneratorStatsSaleItem, { size: 'md' }],
  ReportsGeneratorSubscription: [ReportsGeneratorSubscription, { size: 'md' }],
  ReportsBookingReservationDiscount: [ReportsBookingReservationDiscount, { size: 'md' }],
  SubscriptionProductCreateModal: [SubscriptionProductCreateModal, { role: 'subscription-product-modal', size: 'lg' }],
  ScanningDialog: [ScanningDialog, { size: 'lg', keyboard: false }],
  StepGuestGroupDialog: [StepGuestGroupDialog, { centered: true, size: 'md' }],
  TransferPackageEditModal: [TransferPackageEditModal, { size: 'xl' }],
  TransferPackagePreviewModal: [TransferPackagePreviewModal, { size: 'xl' }],
  TransferDepositModal: [TransferDepositModal, { size: 'md' }],
  SubscriptionDetailsModal: [
    SubscriptionDetailsModal,
    { size: 'xl', role: 'subscription-modal', id: 'subscription-modal' },
  ],
  SubscriptionContractDetailsModal: [
    SubscriptionContractDetailsModal,
    { size: 'xl', role: 'subscription-contract-modal', id: 'subscription-contract-modal' },
  ],
  CafeteriaDetailsModal: [CafeteriaDetailsModal, { size: 'xl', role: 'cafeteria-modal', id: 'cafeteria-modal' }],

  PackageWholesaleDetailsModal: [
    PackageWholesaleDetailsModal,
    { size: 'xl', role: 'package-wholesale-modal', id: 'package-wholesale-modal' },
  ],
  RecommendationGiftDialog: [
    RecommendationGiftDialog,
    {
      size: 'lg',
      role: 'modal-recommendation-gift',
    },
  ],
  RecommendationGiftDetailsDialog: [RecommendationGiftDetailsDialog, { size: 'semi-lg' }],
  PromocodePrefixDetailsDialog: [PromocodePrefixDetailsDialog, { size: 'md' }],
  PromocodePrefixDialog: [PromocodePrefixDialog, { size: 'semi-lg' }],
  ClientDetailsModal: [
    ClientDetailsModal,
    {
      size: 'xl',
      role: 'client-details-modal',
      id: 'client-details-modal',
    },
  ],
  SubscriptionPaymentCreateModal: [SubscriptionPaymentCreateModal, {}],
  SubscriptionChangeDataModal: [SubscriptionChangeDataModal, {}],
  SubscriptionSingleChangeDateModal: [SubscriptionSingleChangeDateModal, {}],
  SubscriptionSingleChangeLocalizationModal: [SubscriptionSingleChangeLocalizationModal, {}],
  SubscriptionChangeLocalizationModal: [SubscriptionChangeLocalizationModal, {}],
  SubscriptionExpirationExtendModal: [SubscriptionExpirationExtendModal, {}],
  SubscriptionCodeKindConvertModal: [SubscriptionCodeKindConvertModal, {}],
  SubscriptionSingleChangeDataModal: [SubscriptionSingleChangeDataModal, {}],
  SubscriptionEditModal: [SubscriptionEditModal, { size: 'lg' }],
  SubscriptionCodeConvertModal: [SubscriptionCodeConvertModal, {}],
  SubscriptionCodeEditModal: [SubscriptionCodeEditModal, {}],
  SubscriptionTouristVoucherModal: [SubscriptionTouristVoucherModal, { size: 'semi-lg' }],
  AccountUserSetPinCodeModal: [AccountUserSetPinCodeModal, {}],
  CmsReservationAppAccommodationTypeModal: [CmsReservationAppAccommodationTypeModal, { size: 'lg' }],
  CmsReservationAppResortModal: [CmsReservationAppResortModal, { size: 'lg' }],
  CmsReservationAppFaqModal: [CmsReservationAppFaqModal, {}],
  CmsReservationAppAttractionModal: [CmsReservationAppAttractionModal, {}],
  CmsReservationAppTextBlockModal: [CmsReservationAppTextBlockModal, {}],
  CmsReservationAppImprovementModal: [CmsReservationAppImprovementModal, {}],
  NotificationTemplatesSmsModal: [NotificationTemplatesSmsModal, {}],
  NotificationTemplatesEmailModal: [NotificationTemplatesEmailModal, { size: 'lg' }],
  ClientCreateModal: [ClientCreateModal, { size: 'semi-lg' }],
  ClientProfileGuestModal: [ClientProfileGuestModal, {}],
  AddSellerModal: [AddSellerModal, {}],
  SubscriptionDetailsCodesGlobalMergeModal: [SubscriptionDetailsCodesGlobalMergeModal, {}],
  ReportsGeneratorCashRegister: [ReportsGeneratorCashRegister, {}],
  ReservationCreateModal: [
    ReservationCreateModal,
    {
      size: 'lg',
      className: 'reception__booking-check-in',
    },
  ],
  ReservationBlockadeModal: [ReservationBlockadeModal, { size: 'md' }],
  ReservationBlockadeSuccessModal: [
    ReservationBlockadeSuccessModal,
    { size: 'sm', centered: true, role: 'success-modal' },
  ],
  AddDocumentModal: [CreateDocumentModal, {}],
  ReportsGeneratorPackagesModal: [ReportsGeneratorPackagesModal, {}],
  ReportsPaymentRegisterModal: [ReportsPaymentRegisterModal, {}],
  ReportsGeneratorPaymentMove: [ReportsGeneratorPaymentMove, {}],
  ReportsGeneratorAlternativeFeeding: [ReportsGeneratorAlternativeFeeding, {}],
  SubscriptionPaymentUpdateModal: [SubscriptionPaymentUpdateModal, {}],
  PackageParamsEditModal: [PackageParamsEditModal, {}],
  PackageCodeConvertModal: [PackageCodeConvertModal, {}],
  ReportsGeneratorGastroCard: [ReportsGeneratorGastroCard, {}],
  ReportsGeneratorShopReceipt: [ReportsGeneratorShopReceipt, {}],
  ReportsGeneratorShopReceiptProducts: [ReportsGeneratorShopReceiptProducts, {}],
  BarrierTicketModal: [BarrierTicketModal, { size: 'semi-lg' }],
  PackageWholesaleCreateModal: [PackageWholesaleCreateModal, { size: 'semi-lg' }],
  BandExtensionInformationModal: [BandExtensionInformationModal, {}],
  ReportsHousekeepingCleaningOrderModal: [ReportsHousekeepingCleaningOrderModal, {}],
  ReportsHousekeepingUsersModal: [ReportsHousekeepingUsersModal, {}],
  ReportsHousekeepingUserModal: [ReportsHousekeepingUserModal, {}],
  ReportResortBand: [ReportResortBand, {}],
  ReportsReservationBillModal: [ReportsReservationBillModal, {}],
  ReportsApartmentMeterModal: [ReportsApartmentMeterModal, {}],
  ReportsMarketingRODOModal: [ReportsMarketingRODOModal, {}],
  ReportsMarketingRODOCanceledModal: [ReportsMarketingRODOCanceledModal, {}],
  ReportsPackageWholesaleCodeModal: [ReportsPackageWholesaleCodeModal, { size: 'semi-lg' }],
  ReportsGastroCardVatModal: [ReportsGastroCardVatModal, {}],
  ReportPromocodeTouristVoucherModal: [ReportPromocodeTouristVoucherModal, {}],
  ReportsReservationBillItemModal: [ReportsReservationBillItemModal, {}],
  ReportsReservationLatePaymentModal: [ReportsReservationLatePaymentModal, {}],
  ReceptionPinPadPaymentSuccessModal: [
    ReceptionPinPadPaymentSuccessModal,
    { size: 'sm', centered: true, role: 'success-modal' },
  ],
  ReceptionPinPadPreauthorizationInfoModal: [
    ReceptionPinPadPreauthorizationInfoModal,
    { size: 'sm', centered: true, role: 'success-modal' },
  ],
  ReceptionBookingCheckInStepDocumentsSigningInProgressModal: [
    ReceptionBookingCheckInStepDocumentsSigningInProgressModal,
    { size: 'sm' },
  ],
  TabletSelectionModal: [TabletSelectionModal, { size: 'md', centered: true }],
  TimelineFiltersModal: [TimelineFiltersModal, { size: 'semi-lg', centered: true }],
  TimelineBlockadeRemoveModal: [TimelineBlockadeRemoveModal, { size: 'md', centered: true }],
  LocalSelectionMapModal: [LocalSelectionMapModal, { size: 'xl', className: 'local-selection-modal' }],
  PackageWholesaleDetailsMassNotificationsModal: [
    PackageWholesaleDetailsMassNotificationsModal,
    { size: 'semi-lg', centered: true },
  ],
  PaymentCardDetailsModal: [PaymentCardDetailsModal, { size: 'sm', centered: true }],
  RegenerateUserReportConfirmationModal: [RegenerateUserReportConfirmationModal, { size: 'sm' }],
  ReportZooTicketsModal: [ReportZooTicketsModal, {}],
  ReportSuntagoTicketsModal: [ReportSuntagoTicketsModal, {}],
  ReportsContractModal: [ReportsContractModal, {}],
  ReportsPackageFullCodeModal: [ReportsPackageFullCodeModal, {}],
  ReportsClimaticModal: [ReportsClimaticModal, {}],
  ReservationBistroVoucherAddModal: [ReservationBistroVoucherAddModal, {}],
  ReservationBistroVoucherUseModal: [ReservationBistroVoucherUseModal, {}],
  ReservationRoomBillTransferModal: [ReservationRoomBillTransferModal, {}],
  ReservationPaymentTransferModal: [ReservationPaymentTransferModal, {}],
  AgreementDetailsModal: [AgreementDetailsModal, { size: 'xl', id: 'agreement-modal' }],
  AgreementStatusChangeModal: [AgreementStatusChangeModal, {}],
  AgreementEditModal: [AgreementEditModal, { size: 'lg' }],
  AgreementCreateModal: [AgreementCreateModal, { size: 'lg', keyboard: false }],
  AgreementCreationDiscardModal: [AgreementCreationDiscardModal, { keyboard: false, size: 'md' }],
  AgreementHourlyRateAnnexModal: [AgreementHourlyRateAnnexModal, { size: 'md' }],
  AgreementWithdrawalAnnexModal: [AgreementWithdrawalAnnexModal, { size: 'sm' }],
  AgreementAnnexDetailsModal: [AgreementAnnexDetailsModal, { size: 'md' }],
  ReservationRoomBillTransactionsListModal: [ReservationRoomBillTransactionsListModal, { size: 'xl' }],
  RentalProductCreateModal: [RentalProductCreateModal, {}],
  RentalProductsImportModal: [RentalProductsImportModal, {}],
  RentalProductDetailsModal: [RentalProductDetailsModal, { size: 'semi-lg' }],
  RentalWarehouseCreateModal: [RentalWarehouseCreateModal, {}],
  RentalProductCategoryCreateModal: [RentalProductCategoryCreateModal, {}],
  ReservationLoadNoteAddModal: [ReservationLoadNoteAddModal, {}],
  ReservationLoadNotePreviewModal: [ReservationLoadNotePreviewModal, {}],
  ReservationLoadNoteEditModal: [ReservationLoadNoteEditModal, {}],
  ReportsSubscriptionContractModal: [ReportsSubscriptionContractModal, {}],
  ReportsPermissionsModal: [ReportsPermissionModal, {}],
  ReservationPaymentAddModal: [ReservationPaymentAddModal, {}],
  ReportsBookingStatisticsModal: [ReportsBookingsStatisticsModal, {}],
  ShopReceiptNotFulfilledModal: [ShopReceiptNotFulfilledModal, { size: 'md', keyboard: false }],
  HousekeepingUserModal: [HousekeepingUserModal, {}],
  AccountUserChangePassword: [AccountUserChangePassword, {}],
  CallCenterUserModal: [CallCenterUserModal, {}],
  ReceptionistUserModal: [ReceptionistUserModal, {}],
  ReportsCommonMetersModal: [ReportsCommonMetersModal, {}],
  ReceptionBookingCartModal: [ReceptionBookingCartModal, { size: 'xl' }],
  ReportsGastroCouponModal: [ReportsGastroCouponModal, {}],
  ServiceBandCreateDialog: [ServiceBandCreateDialog, { size: 'lg' }],
  ServiceBandDetailsDialog: [ServiceBandDetailsDialog, { size: 'lg' }],
  ServiceBandInfoEditDialog: [ServiceBandInfoEditDialog, {}],
  FloorHeatingDetailsParametersModalChange: [FloorHeatingDetailsParametersModalChange, {}],
  ReservationChangeDatesDialog: [ReservationChangeDatesDialog, {}],
  ReportsFloorHeatingModal: [ReportsFloorHeatingModal, {}],
  ReportSmsNotificationsModal: [ReportSmsNotificationsModal, {}],
  ReceptionBookingNewFeedingModal: [ReceptionBookingNewFeedingModal, { size: 'lg' }],
  ReceptionBookingNewFeedingDiscountModal: [ReceptionBookingNewFeedingDiscountModal, { size: 'semi-lg' }],
  ReceptionBookingNewFeedingPriceListModal: [ReceptionBookingNewFeedingPriceListModal, { size: 'auto' }],
  ReportSubscriptionConversionModal: [ReportSubscriptionConversionModal, {}],
  ReportTechnicalOrdersModal: [ReportTechnicalOrdersModal, {}],
  ReportGenericVoucherCode: [ReportGenericVoucherCode, {}],
  ReportGenericCourtyardOccupancyModal: [ReportGenericCourtyardOccupancyModal, {}],
  ReportCashbackVoucherModal: [ReportCashbackVoucherModal, {}],
  TechnicalOrderWorkTimeModal: [TechnicalOrderWorkTimeModal, {}],
  GuestsTechnicalOrderStatusModal: [GuestsTechnicalOrderStatusModal, { size: 'md' }],
  ReceptionTechnicalOrderStatusModal: [ReceptionTechnicalOrderStatusModal, { size: 'md' }],
  CouponTypeCreateModal: [CouponTypeCreateModal, {}],
  CouponTypeDetailsModal: [CouponItemDetailsModal, {}],
  CouponTypeConfigurationCreateModal: [CouponTypeConfigurationCreateModal, {}],
  CouponItemEditDetailsModal: [CouponItemEditDetailsModal, {}],
  CouponItemDetailsModal: [CouponItemDetailsModal, { size: 'semi-lg' }],
  TechnicalOrderRejectModal: [TechnicalOrderRejectModal, {}],
  GenericProductPaymentsAddDialog: [GenericProductPaymentsAddDialog, {}],
  TechnicalOrderPreviewModal: [TechnicalOrderPreviewModal, { size: 'semi-lg' }],
  BookingOptionCreateDialog: [BookingOptionCreateDialog, { size: 'semi-lg' }],
  BookingOptionDetailsDialog: [BookingOptionDetailsDialog, { size: 'xl' }],
  UpsellBookingsDateFiltersModal: [UpsellBookingsDateFiltersModal, { size: 'semi-lg' }],
  UpsellBookingsStatusChangeModal: [UpsellBookingsStatusChangeModal, { size: 'md' }],
  UpsellBookingAddNoteModal: [UpsellBookingAddNoteModal, { size: 'md' }],
  RodoAgreementsModal: [RodoAgreementsModal, { size: 'xl', keyboard: false }],
  RodoAgreementsIssueConfirmationModal: [RodoAgreementsIssueConfirmationModal, { size: 'md' }],
  RodoAgreementsModalClientDataEdit: [RodoAgreementsModalClientDataEdit, { size: 'md' }],
  RodoAgreementsAcquiringPointCreateModal: [RodoAgreementsAcquiringPointCreateModal, { size: 'semi-lg' }],
  HrWorkerCreateModal: [HrWorkerCreateModal, { size: 'lg' }],
  HrWorkerDetailsModal: [HrWorkerDetailsModal, { size: 'xl' }],
  BenefitProgramCreateModal: [BenefitProgramCreateModal, { size: 'semi-lg' }],
  GenericVoucherDetailsModal: [GenericVoucherDetailsModal, { size: 'xl' }],
  ShopParkingConfigurationModal: [ShopParkingConfigurationModal, { size: 'md' }],
  BandScanningTimeConfigurationModal: [BandScanningTimeConfigurationModal, { size: 'sm', centered: true }],
  BandScanningScannerConfigurationModal: [BandScanningScannerConfigurationModal, { size: 'md', centered: true }],
  BenefitProgramAgreementsCreateModal: [BenefitProgramAgreementsCreateModal, { size: 'md' }],
  BenefitProgramUserAddModal: [BenefitProgramUserAddModal, { size: 'md' }],
  ReservationCreateDataResetModal: [ReservationCreateDataResetModal, { size: 'sm', centered: true }],
  NotificationPreviewModal: [NotificationPreviewModal, { size: 'lg' }],
  HistoryListModal: [HistoryListModal, { size: 'semi-lg' }],
  ReservationAddVoucherModal: [ReservationAddVoucherModal, { size: 'semi-lg' }],
  ReservationDepositChangeModal: [ReservationDepositChangeModal, { size: 'sm' }],
  ReceptionBookingImprovementSubscriptionModal: [ReceptionBookingImprovementSubscriptionModal, { size: 'semi-lg' }],
  BenefitProgramAgreementCreateModal: [BenefitProgramAgreementCreateModal, { size: 'semi-lg' }],
  EndlessHolidayCreateModal: [EndlessHolidayCreateModal, { size: 'semi-lg' }],
  EndlessHolidayDetailsModal: [EndlessHolidayDetailsModal, { size: 'xl' }],
}
export type ModalTypes = keyof typeof ModalsMap

export interface ModalConfig {
  persist?: boolean // nie usuwa modala po wyjściu z widoku
  onClose?: () => void
}

export interface BaseModalProps {
  toggleIsVisible: () => void
}

import * as React from 'react'
import { ReceptionBookingClientWallet, ReceptionBookingWalletDetails } from '@models/reception'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  wallet: ReceptionBookingClientWallet
  children: React.ReactNode
}

export const ReservationVoucherActivationToggle = ({ wallet, children }: Props): React.ReactNode => {
  const { addSuccessNotification } = useNotificationHook()

  const { action: toggleWalletState } = useApiRequest(async () => {
    addSuccessNotification(wallet.is_active ? 'Voucher został dezaktywowany' : 'Voucher został aktywowany')

    await commonObjectPatch<ReceptionBookingWalletDetails>(wallet.urls.assigment, {
      is_active: !wallet.is_active,
    })
  })

  if (!wallet.can_change_is_active) return null

  return (
    <CommonObjectConfirmAction
      handleAccept={toggleWalletState}
      submitLabel={wallet.is_active ? 'Dezaktywuj' : 'Aktywuj'}
      buttonAcceptClassName={wallet.is_active ? 'btn btn-danger' : 'btn btn-green'}
      message={
        <div className="d-flex flex-column">
          <div>Czy na pewno chcesz {wallet.is_active ? 'dezaktywować' : 'aktywować'}</div>
          <IconWithText
            icon="uil-tag-alt"
            wrapperClassNames="my-1"
            text={
              <span>
                <strong>{wallet.name}</strong>
                <span className="fw-semi-bold"> {wallet.code}?</span>
              </span>
            }
          />
        </div>
      }
    >
      {children}
    </CommonObjectConfirmAction>
  )
}

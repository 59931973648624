import * as React from 'react'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { User } from '@models/account'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useCreateOrUpdateBenefitProgramMutation } from '@api/benefit-programs'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { BenefitProgramDetails } from '@modules/benefit-program/models'

interface Props {
  user: User
  benefitProgram: BenefitProgramDetails
}

export const BenefitProgramDetailsUsersTableRow: React.FC<Props> = ({ user, benefitProgram }) => {
  const [createOrUpdateBenefitProgram, { isLoading }] = useCreateOrUpdateBenefitProgramMutation()
  const { addSuccessNotification } = useNotificationHook()

  const handleAccept = async () => {
    await createOrUpdateBenefitProgram({
      url: `${benefitProgram.urls.users}${user.id}/`,
      method: 'DELETE',
    })

    addSuccessNotification('Użytkownik został skasowany')
  }

  return (
    <div className="mb-2">
      <div className="font-14 text-semi-strong">
        {user.first_name} {user.last_name}
        <CommonObjectConfirmAction
          title="Potwierdź anulowanie kafeterii"
          isLoading={isLoading}
          handleAccept={handleAccept}
          message="Czy na pewno chcesz anulować wybraną kafeterię ? Operacja ta jest nieodwracalna."
        >
          <i className="uil-trash-alt cursor-pointer font-12 text-muted" title="Skasuj" />
        </CommonObjectConfirmAction>
      </div>
      <div>
        {user.email} {user.last_login && `(ostatnie logowanie: ${toDefaultDateTimeFormat(user.last_login)})`}
      </div>
      <div>{user.phone}</div>
    </div>
  )
}

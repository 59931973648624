import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { ReceptionBookingDetails } from '@models/reception'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'
import { commonObjectPut } from '@store/actions/generic-actions'
import { FormRadio, FormRadioGroup } from '@hyper/forms/form-radio'
import { asDecimal, formatPrice } from '@helpers/utils'
import classNames from 'classnames'
import { FormInput } from '@hyper/forms'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface FormInputs {
  default_deposit_amount: string
  custom_deposit_amount: string | null
  reason: string
}

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

export const ReservationDepositChangeModal: React.FC<Props> = ({ toggleIsVisible, booking }) => {
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()
  const { addSuccessMessage } = useNotificationHook()

  const selectedPredefinedOption = React.useMemo(
    () => AvailableDepositChoices.find(option => asDecimal(option.value).eq(booking.prices.deposit_amount)),
    [booking.prices.deposit_amount],
  )

  const methods = useForm<FormInputs>({
    defaultValues: {
      custom_deposit_amount: selectedPredefinedOption ? null : booking.prices.deposit_amount.toString(),
      default_deposit_amount: selectedPredefinedOption?.value.toString() ?? 'custom',
    },
  })

  const currentSelected = useWatch({ control: methods.control, name: 'default_deposit_amount' })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    await dispatch(
      updateReceptionBookingDetails(
        await commonObjectPut<ReceptionBookingDetails>(booking.urls.reservation_booking_details, {
          default_deposit_amount:
            payload.default_deposit_amount === 'custom'
              ? payload.custom_deposit_amount
              : payload.default_deposit_amount,
          reason: payload.reason,
        }),
      ),
    )
    addSuccessMessage('Sukces', 'Wysokość kaucji została zmieniona')
    toggleIsVisible()
  }, methods.setError)

  const isActiveOption = (value: string) => {
    if (value === 'custom') return !selectedPredefinedOption
    return asDecimal(value).eq(booking.prices.deposit_amount)
  }

  const canSetCustomDepositPrice = user.hasPerm(UserPermission.BookingCanChangeDefaultDepositAmount)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Zmień kwotę wymaganej kaucji</ModalHeader>
      <ModalBody className="p-0">
        <FormRadioGroup
          name="default_deposit_amount"
          className="d-flex flex-column"
          formPlainProps={{ formGroupClassName: 'mb-0' }}
        >
          {AvailableDepositChoices.map(({ value, label }) => (
            <div
              key={value}
              className={classNames('py-2 border-bottom mx-n2 px-2', {
                'bg-grey': currentSelected === value,
              })}
            >
              <FormRadio
                disabled={value === 'custom' ? !canSetCustomDepositPrice : false}
                name="default_deposit_amount"
                value={value.toString()}
                label={
                  <div className="d-flex justify-content-between w-100">
                    <span className="fw-semi-bold">{value !== 'custom' ? formatPrice(value) : label}</span>
                    {isActiveOption(value) && <span className="badge badge-gray d-block">Aktualna kwota kaucji</span>}
                  </div>
                }
              />
            </div>
          ))}
          {currentSelected === 'custom' && (
            <FormInput
              disabled={!canSetCustomDepositPrice}
              registerParams={{ required: true }}
              name="custom_deposit_amount"
              type="currency"
              placeholder="Wpisz kwotę kaucji"
              formGroupClassName="mt-3 mb-0"
              colClassName="px-0"
            />
          )}
          <FormInput
            registerParams={{ required: true }}
            name="reason"
            type="textarea"
            placeholder="Wpisz powód zmiany"
            formGroupClassName="mt-3"
            colClassName="px-0"
          />
        </FormRadioGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton
          className="btn btn-green"
          isSaving={isLoading}
          label="Zmień"
          labelSaving="Zmieniam..."
          disabled={!methods.formState.isValid}
        />
      </ModalFooter>
    </Form>
  )
}

const AvailableDepositChoices = [
  { value: '1000', label: '1000' },
  { value: '999', label: '999' },
  { value: '899', label: '899' },
  { value: '750', label: '750' },
  { value: '500', label: '500' },
  { value: '199', label: '199' },
  { value: 'custom', label: 'Wpisz wysokość kaucji' },
]

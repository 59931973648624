import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'

interface Props {
  transaction: any
}

export const EndlessHolidayProductsTableRow = ({ transaction }: Props): JSX.Element => {
  const [showConfigurationModal] = useModal('EndlessHolidayTransactionModal', { transaction })

  return (
    <Table.Row>
      <Table.Cell>{transaction.name}</Table.Cell>
      <Table.Cell>{transaction.name}</Table.Cell>
      <Table.Cell>{transaction.name}</Table.Cell>
      <Table.Cell>{transaction.name}</Table.Cell>
      <Table.Cell>{transaction.name}</Table.Cell>
      <Table.Cell style={{ width: 40 }}>
        <i className="uil-pen font-14 lh-1 cursor-pointer" onClick={showConfigurationModal} />
      </Table.Cell>
    </Table.Row>
  )
}

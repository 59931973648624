import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { ProductPackage } from '@modules/endless-holiday/models'
import { StatusBadge } from '@modules/promotions/common/status-badge'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { useSellers } from '@components/hooks/use-grouped-sellers'

interface Props {
  transaction: ProductPackage
  index: number
}

export const EndlessHolidayTransactionsTableRow = ({ transaction, index }: Props): React.ReactNode => {
  const [showConfigurationModal] = useModal('EndlessHolidayDetailsModal', { product: transaction })

  const sellers = useSellers()
  const seller = sellers.find(seller => seller.id === transaction.seller)

  return (
    <Table.Row>
      <Table.Cell>{index + 1}.</Table.Cell>
      <Table.Cell>{transaction.number}</Table.Cell>
      <Table.Cell>{transaction.client.email}</Table.Cell>
      <Table.Cell>
        <StatusBadge element={transaction} />
      </Table.Cell>
      <Table.Cell>{seller?.name ?? '-'}</Table.Cell>
      <Table.Cell>-</Table.Cell>
      <Table.Cell>{toDefaultDateTimeFormat(transaction.created)}</Table.Cell>
      <Table.Cell style={{ width: 40 }}>
        <i className="uil-eye font-14 lh-1 cursor-pointer" onClick={showConfigurationModal} />
      </Table.Cell>
    </Table.Row>
  )
}

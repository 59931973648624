import * as React from 'react'
import { Label } from 'reactstrap'
import Col from 'reactstrap/lib/Col'
import DateRangeInput from '@components/date/date-range-input'
import { FormPlainError } from '@hyper/forms'
import { FieldError, useFormContext } from 'react-hook-form'
import * as R from 'ramda'
import { CSSProperties } from 'react'
import classNames from 'classnames'

interface DateFromToInputProps {
  startDateName: string
  endDateName: string
  label: string
  colSize?: number | string
  minDate?: Date | null
  isClearable?: boolean
  className?: string
  style?: CSSProperties
  dateFormat?: string
  inputGroupClassName?: string
}

export const DateFromToInput: React.FC<DateFromToInputProps> = ({
  label,
  minDate,
  startDateName,
  endDateName,
  colSize,
  isClearable = true,
  className,
  style,
  dateFormat,
  inputGroupClassName,
}) => {
  const {
    formState: { errors },
  } = useFormContext<{ [name: string]: Date }>()

  const startDateError = R.path<FieldError>(startDateName.split('.'), errors)
  const endDateError = R.path<FieldError>(endDateName.split('.'), errors)

  return (
    <Col md={colSize || 12} className={className} style={style}>
      {label && (
        <div className="d-flex">
          <Label className="mr-1">{label}:</Label>
        </div>
      )}

      <DateRangeInput
        dateFormat={dateFormat}
        isClearable={isClearable}
        wrapperClassName="flex-grow-1"
        inputGroupClassName={classNames('flex-grow-1', inputGroupClassName)}
        minDate={minDate}
        startDateName={startDateName}
        endDateName={endDateName}
      />

      <FormPlainError name={startDateName} className="mt-n1 mb-1" />

      {startDateError?.message !== endDateError?.message && (
        <FormPlainError name={endDateName} className="mt-n1 mb-1" />
      )}
    </Col>
  )
}

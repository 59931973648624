import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { startOfToday, subDays } from 'date-fns'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'
import { SaveButton } from '@hyper/button'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { FormCheckbox } from '@hyper/forms/form-checkbox'

const ConversionTypesOptions = [
  createSelectOption('Wszystkie', 'all'),
  createSelectOption('Upgrade', 'upgrade'),
  createSelectOption('Lokalizacja', 'localization'),
]

const SourcesOption = [
  createSelectOption('Wszystkie', null),
  createSelectOption('PMS', 'pms'),
  createSelectOption('E-Sklep', 'ecommerce'),
]

interface FormInputs {
  date_before: Date
  date_after: Date
  payment_date_before: Date | null
  payment_date_after: Date | null
  source: CustomReactSelectOption | null
  upgrade_type: CustomReactSelectOption | null
  kind: CustomReactSelectOption | null
  converted_codes_only: boolean
}

export const ReportSubscriptionConversionModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const user = useAuthenticatedUser()
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_subscription_conversion_report,
  )

  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      date_before: startOfToday(),
      date_after: subDays(startOfToday(), 3),
      payment_date_after: null,
      payment_date_before: null,
      source: null,
      kind: null,
      upgrade_type: null,
      converted_codes_only: false,
    },
  })

  const SubscriptionKindOptions = [
    createSelectOption('Wszystkie', 'all'),
    ...(user.hasPerm(UserPermission.ReportsSubscriptionConversionPrivateReport)
      ? [createSelectOption('Pakiet prywatny', 'DEFAULT')]
      : []),
    ...(user.hasPerm(UserPermission.ReportsSubscriptionConversionWholesaleReport)
      ? [createSelectOption('Pakiet prywatny (hurtowy)', 'WHOLESALE')]
      : []),
    ...(user.hasPerm(UserPermission.ReportsSubscriptionConversionWithBenefitsReport)
      ? [createSelectOption('Pakiet korzyści', 'WITH_BENEFIT')]
      : []),
  ]

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(createReportTask([reportUrl, extractSelectOptionsValues(payload)]))
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport ulepszonych kodów</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput
            startDateName="date_after"
            endDateName="date_before"
            label="Data zamiany"
            isClearable={false}
          />
          <DateFromToInput
            startDateName="payment_date_after"
            endDateName="payment_date_before"
            label="Data wpłaty"
            isClearable={true}
          />
          <FormSelect options={ConversionTypesOptions} name="upgrade_type" label="Rodzaj konwersji" />
          <FormSelect options={SourcesOption} name="source" label="Źródło" />
          <FormSelect options={SubscriptionKindOptions} name="kind" label="Typ pakietu" />
          <FormCheckbox name="converted_codes_only" label="Tylko przekonwertowane kody" className="mx-2" />
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}

import * as React from 'react'
import { PrivateRoute } from '@components/private-route'
import { NavigationPath } from '@models/routes'
import { UnauthorizedView } from '@modules/unauthorized/unauthorized'
import { UsersView } from '@modules/rcp/users'
import { ReservationView } from '@modules/reservations/reservations'
import { GastroCardsView } from '@modules/promotions/gastro-cards/gastro-cards-view'
import { PromoCodeListView } from '@modules/promocode/promocode-list/promocode-list-view'
import { PromoCodePrefixesView } from '@modules/promocode/promocode-prefixes/promocode-prefixes-view'
import { RcpUserEventsView } from '@modules/rcp/user/events'
import { RecommendationGiftsView } from '@modules/crm/recommendation-gifts/recommendation-gifts-view'
import { ReceptionView } from '@modules/reception/reception-view'
import { ReceptionWithParamsView } from '@modules/reception/reception-with-params-view'
import { ReceptionBookingCheckInView } from '@modules/reception/reception-booking-check-in-view'
import { ReservationDetailsView } from '@modules/reservations/details'
import { ReceptionBookingCheckOutView } from '@modules/reception/reception-booking-check-out-view'
import { UserReportsView } from '@modules/reports/user-reports'
import { HomeView } from '@modules/home/home'
import { FailureView } from '@modules/failure/failure'
import { ReceptionPosView } from '@modules/reception/pos'
import { ReservationListView } from '@modules/reservations/list'
import { ReservationListWithParamsView } from '@modules/reservations/list/reservation-list-with-params'
import { ShopWarehouseInvoicesView } from '@modules/shop/warehouse/invoices'
import { StatsSaleItemView } from '@modules/reports/stats-sale-item'
import { PaymentsReportView } from '@modules/reports/payments-report'
import { HouseKeepingReportView } from '@modules/reports/housekeeping-users-report'
import { HouseKeepingUserReportView } from '@modules/reports/housekeeping-user-report'
import { PackageVipView } from '@modules/promotions/package-vip'
import { ShopPOSView } from '@modules/shop/pos'
import { ShopPOSWithParamsView } from '@modules/shop/pos/shop-pos-with-params'
import { ReservationCreateView } from '@modules/reservations/create/reservation-create-view'
import { MediaReports } from '@modules/reports/media'
import { ReservationsTimelineDefault } from '@modules/reservations/reservations-timeline-default'
import { ErliPromocodeView } from '@modules/promotions/erli-promocode'
import { ReceptionSellersReport } from '@modules/reports/reception-sellers'
import { HousekeepingCleaningOrdersView } from '@modules/housekeeping/cleaning-orders'
import { HousekeepingCleaningOrdersParams } from '@modules/housekeeping/cleaning-orders/housekeeping-cleaning-orders-params'
import { FeedingHostView } from '@modules/feeding/host'
import { FeedingHostWithParamsView } from '@modules/feeding/host/feeding-host-with-params-view'
import { FeedingClientsView } from '@modules/feeding/clients'
import { FeedingSpecialGuest } from '@modules/feeding/special-guests'
import { LoginView } from '@modules/authorization/login'
import { FeedingWorkerView } from '@modules/feeding/workers'
import { RefundBookingsView } from '@modules/accounting/refund/bookings'
import { HousekeepingApplicationsView } from '@modules/housekeeping/applications'
import { HousekeepingApplicationsWithParamsView } from '@modules/housekeeping/applications/applications-with-params'
import { TransferPackagesView } from '@modules/accounting/refund/transfer-packages'
import { ReservationStatsBoardView } from '@modules/reservations/stats-board'
import { AuthorizationResetPasswordLinkView } from '@modules/authorization/reset-password-link'
import { AuthorizationResetPasswordView } from '@modules/authorization/reset-password'
import { LogoutView } from '@modules/authorization/logut'
import { SubscriptionListView } from '@modules/subscription/list'
import { PackageListView } from '@modules/package/list'
import { SubscriptionProductListView } from '@modules/subscription/products/list'
import { CrmClientsView } from '@modules/crm/clients/list-view'
import { PinCodeLoginView } from '@modules/authorization/pin-code-login'
import { AccountUserListView } from '@modules/account/users/list'
import { CmsReservationAppView } from '@modules/cms/reservation-app'
import { NotificationTemplatesView } from '@modules/cms/notification-templates'
import { SellerList } from '@modules/seller/list'
import { Route, Routes } from 'react-router-dom'
import { PaymentRegistersView } from '@modules/payment-registers/list'
import { PackageWholesaleProductListView } from '@modules/package-wholesale/products/list'
import { PackageWholesaleListView } from '@modules/package-wholesale/list'
import { BarriersTicketsView } from '@modules/barriers/tickets'
import { BarriersSpecialTicketsView } from '@modules/barriers/special-tickets'
import { PaymentRegisterCashboxView } from '@modules/payment-registers/cashbox'
import { PaymentRegisterCashboxWithParams } from '@modules/payment-registers/cashbox/cashbox-with-params'
import { PaymentRegisterCashboxDayDetailsView } from '@modules/payment-registers/cashbox-day-details'
import { PaymentRegisterUserCashBoxView } from '@modules/payment-registers/user-cashbox'
import { CmsAssetCategoriesView } from '@modules/cms/assets/categories'
import { CmsAssetsView } from '@modules/cms/assets/list'
import { CashRegisterListWithParamsView } from '@modules/sale/cash-register/list/cash-register-list-with-params'
import { CashRegisterListView } from '@modules/sale/cash-register/list'
import { PackageWholesalePackagesView } from '@modules/package-wholesale/packages'
import { PromocodeTouristVoucherView } from '@modules/promotions/promocode-tourist-voucher'
import { ServiceBandView } from '@modules/bands/service-bands'
import { PackageWholesaleGastroCardsView } from '@modules/package-wholesale/gastro-cards'
import { SubscriptionContractListView } from '@modules/subscription-contract/list'
import { RentalProductsView } from '@modules/rental/products'
import { RentalWarehousesView } from '@modules/rental/warehouses'
import { RentalProductCategoriesView } from '@modules/rental/product-categories'
import { ReservationImprovementsStatsView } from '@modules/reservations/statistics/improvements-stats'
import { ReservationStatsView } from '@modules/reservations/statistics/booking-stats'
import { CafeteriaListView } from '@modules/cafeteria/list'
import { HousekeepingUsersListView } from '@modules/housekeeping/users/list'
import { CallCenterUsersListView } from '@modules/account/call-center-users/list'
import { ReceptionistUsersListView } from '@modules/account/receptionist-users/list'
import { AgreementListView } from '@modules/hr/agreement/list'
import { GastroGenericProductView } from '@modules/promotions/gastro-generic-product/gastro-generic-product-view'
import { CouponsTypesView } from '@modules/coupons/types'
import { CouponsListView } from '@modules/coupons/list/clients'
import { CouponsClientDetailsView } from '@modules/coupons/list/client-details'
import { SubscriptionGenericView } from '@modules/subscription-generic/list'
import { FloorHeatingListView } from '@modules/floor-heating/list'
import { FloorHeatingDetailsView } from '@modules/floor-heating/details'
import { TechnicalOrdersView } from '@modules/technical-orders'
import { TechnicalOrdersDefaultView } from '@modules/technical-orders/technical-orders-default-view'
import { EcommerceStatsView } from '@modules/ecommerce/stats/ecommerce-stats-view'
import { CashbackVouchersView } from '@modules/products/cashback-vouchers'
import { PromoCodeCodesView } from '@modules/promocode/promocode-list/promocode-codes/promocode-codes-view'
import { ReceptionTechnicalOrdersDefaultView } from '@modules/reception-technical-orders/reception-technical-orders-default-view'
import { ReceptionTechnicalOrdersView } from '@modules/reception-technical-orders'
import { GuestsTechnicalOrdersView } from '@modules/guests-technical-orders'
import { GuestsTechnicalOrdersDefaultView } from '@modules/guests-technical-orders/guests-technical-orders-default-view'
import { UnitsDefaultView } from '@modules/units/list/units-default-view'
import { UnitsView } from '@modules/units/list'
import { UnitDetailsView } from '@modules/units/details'
import { ReservationDetailsPreviewView } from '@modules/reservations/details/reservation-details-preview-view'
import { BookingOptionsView } from '@modules/promotions/booking-options'
import { UpSellBookingsDefaultView } from '@modules/upselling/upsell-bookings/upsell-bookings-default-view'
import { UpsellBookingDetailsView } from '@modules/upselling/upsell-booking-details'
import { UpSellBookingsView } from '@modules/upselling/upsell-bookings'
import { UpSellBookingsOutdatedView } from '@modules/upselling/upsell-booking-outdated'
import { CouponsTypesConfigurationView } from '@modules/coupons/types-configuration'
import { UpSellUsersView } from '@modules/upselling/upsell-users'
import { CrmClientsBenefitsForRodoView } from '@modules/crm/rodo-agreements/benefits'
import { RodoAgreementsAcquiringPointsView } from '@modules/crm/rodo-agreements/point-of-acquirng'
import { BookingOffersView } from '@modules/booking-offers'
import { HrWorkersListView } from '@modules/hr/workers/list'
import { GenericVoucherListView } from '@modules/generic-vouchers/list'
import { BenefitProgramListView } from '@modules/benefit-program/list'
import { BenefitProgramDetailsView } from '@modules/benefit-program/details'
import { BenefitProgramAgreementDetailsView } from '@modules/benefit-program/agreements/details'
import { EndlessHolidayView } from '@modules/endless-holiday'
import { EndlessHolidayConfigurationView } from '@modules/endless-holiday/configuration'

export const AppRoutes: React.FC = () => (
  <Routes>
    <Route
      path={NavigationPath.BarriersSpecialTickets}
      element={<PrivateRoute component={BarriersSpecialTicketsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReceptionistUsers}
      element={<PrivateRoute component={ReceptionistUsersListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.UpSellBookings}
      element={<PrivateRoute component={UpSellBookingsDefaultView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.BookingOffers}
      element={<PrivateRoute component={BookingOffersView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.BenefitProgramList}
      element={<PrivateRoute component={BenefitProgramListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.BenefitProgramAgreementsDetails}
      element={<PrivateRoute component={BenefitProgramAgreementDetailsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.BenefitProgramDetails}
      element={<PrivateRoute component={BenefitProgramDetailsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.GenericVoucherList}
      element={<PrivateRoute component={GenericVoucherListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.GenericVoucherListWithParams}
      element={<PrivateRoute component={GenericVoucherListView} isLayout={true} />}
    />
    <Route path={NavigationPath.UpSellUsers} element={<PrivateRoute component={UpSellUsersView} isLayout={true} />} />
    <Route
      path={NavigationPath.UpSellBookingDetails}
      element={<PrivateRoute component={UpsellBookingDetailsView} isLayout={false} />}
    />
    <Route
      path={NavigationPath.UpSellBookingsWithParams}
      element={<PrivateRoute component={UpSellBookingsView} isLayout={false} />}
    />
    <Route
      path={NavigationPath.UpSellBookingsOutdatedWithParams}
      element={<PrivateRoute component={UpSellBookingsOutdatedView} isLayout={false} />}
    />
    <Route
      path={NavigationPath.CallCenterUsers}
      element={<PrivateRoute component={CallCenterUsersListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.BarriersTickets}
      element={<PrivateRoute component={BarriersTicketsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.AccountingRefundBookings}
      element={<PrivateRoute component={RefundBookingsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.AccountingTransferPackages}
      element={<PrivateRoute component={TransferPackagesView} isLayout={true} />}
    />
    <Route path={NavigationPath.CmsAssets} element={<PrivateRoute component={CmsAssetsView} isLayout={true} />} />
    <Route
      path={NavigationPath.CmsAssetCategories}
      element={<PrivateRoute component={CmsAssetCategoriesView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.CrmRecommendationGifts}
      element={<PrivateRoute component={RecommendationGiftsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.CrmRecommendationGiftsWithParams}
      element={<PrivateRoute component={RecommendationGiftsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.Home}
      element={<PrivateRoute component={HomeView} redirectTo={NavigationPath.AuthorizationLogin} isLayout={true} />}
    />
    <Route
      path={NavigationPath.HouseKeepingReport}
      element={<PrivateRoute component={HouseKeepingReportView} isLayout={true} />}
    />
    <Route path={NavigationPath.CrmClients} element={<PrivateRoute component={CrmClientsView} isLayout={true} />} />
    <Route
      path={NavigationPath.CrmClientsBenefitsForRodo}
      element={<PrivateRoute component={CrmClientsBenefitsForRodoView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.RodoAgreementsAcquiringPoints}
      element={<PrivateRoute component={RodoAgreementsAcquiringPointsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.CrmClientsWitParams}
      element={<PrivateRoute component={CrmClientsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.HouseKeepingUserReport}
      element={<PrivateRoute component={HouseKeepingUserReportView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.CmsReservationApp}
      element={<PrivateRoute component={CmsReservationAppView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.NotificationTemplates}
      element={<PrivateRoute component={NotificationTemplatesView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.SubscriptionProductList}
      element={
        <PrivateRoute component={SubscriptionProductListView} componentParams={{ type: 'DEFAULT' }} isLayout={true} />
      }
    />
    <Route
      path={NavigationPath.SubscriptionWithBenefitProductList}
      element={
        <PrivateRoute
          component={SubscriptionProductListView}
          componentParams={{ type: 'WITH_BENEFIT' }}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.SubscriptionWithBenefit20ProductList}
      element={
        <PrivateRoute
          component={SubscriptionProductListView}
          componentParams={{ type: 'WITH_BENEFIT_20' }}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.SubscriptionGoHolidayProductList}
      element={
        <PrivateRoute
          component={SubscriptionProductListView}
          componentParams={{ type: 'GO_HOLIDAY' }}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.SubscriptionContractListWithParams}
      element={<PrivateRoute component={SubscriptionContractListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.SubscriptionContractList}
      element={<PrivateRoute component={SubscriptionContractListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.SubscriptionListWithParams}
      element={<PrivateRoute component={SubscriptionListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.SubscriptionList}
      element={<PrivateRoute component={SubscriptionListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.SubscriptionWithBenefitListWithParams}
      element={
        <PrivateRoute component={SubscriptionGenericView} componentParams={{ type: 'WITH_BENEFIT' }} isLayout={true} />
      }
    />
    <Route
      path={NavigationPath.SubscriptionWithBenefitList}
      element={
        <PrivateRoute component={SubscriptionGenericView} componentParams={{ type: 'WITH_BENEFIT' }} isLayout={true} />
      }
    />
    <Route
      path={NavigationPath.SubscriptionWithBenefit20ListWithParams}
      element={
        <PrivateRoute
          component={SubscriptionGenericView}
          componentParams={{ type: 'WITH_BENEFIT_20' }}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.SubscriptionWithBenefit20List}
      element={
        <PrivateRoute
          component={SubscriptionGenericView}
          componentParams={{ type: 'WITH_BENEFIT_20' }}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.SubscriptionBookingImprovementListWithParams}
      element={
        <PrivateRoute
          component={SubscriptionGenericView}
          componentParams={{ type: 'BOOKING_IMPROVEMENT' }}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.SubscriptionBookingImprovementList}
      element={
        <PrivateRoute
          component={SubscriptionGenericView}
          componentParams={{ type: 'BOOKING_IMPROVEMENT' }}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.SubscriptionGoHolidayListWithParams}
      element={
        <PrivateRoute component={SubscriptionGenericView} componentParams={{ type: 'GO_HOLIDAY' }} isLayout={true} />
      }
    />
    <Route
      path={NavigationPath.SubscriptionCafeteriaListWithParams}
      element={
        <PrivateRoute component={SubscriptionGenericView} componentParams={{ type: 'CAFETERIA' }} isLayout={true} />
      }
    />
    <Route
      path={NavigationPath.SubscriptionGoHolidayList}
      element={
        <PrivateRoute component={SubscriptionGenericView} componentParams={{ type: 'GO_HOLIDAY' }} isLayout={true} />
      }
    />
    <Route
      path={NavigationPath.PackageListWithParams}
      element={<PrivateRoute component={PackageListView} isLayout={true} />}
    />
    <Route path={NavigationPath.PackageList} element={<PrivateRoute component={PackageListView} isLayout={true} />} />
    <Route
      path={NavigationPath.CashbackVouchers}
      element={<PrivateRoute component={CashbackVouchersView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.CashbackVouchersWithParams}
      element={<PrivateRoute component={CashbackVouchersView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReceptionTechnicalOrders}
      element={<PrivateRoute component={ReceptionTechnicalOrdersDefaultView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReceptionTechnicalOrdersWithParams}
      element={<PrivateRoute component={ReceptionTechnicalOrdersView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReceptionTechnicalOrdersWithParamsAndId}
      element={<PrivateRoute component={ReceptionTechnicalOrdersView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.GuestsTechnicalOrders}
      element={<PrivateRoute component={GuestsTechnicalOrdersDefaultView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.GuestsTechnicalOrdersWithParams}
      element={<PrivateRoute component={GuestsTechnicalOrdersView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.GuestsTechnicalOrdersWithParamsAndId}
      element={<PrivateRoute component={GuestsTechnicalOrdersView} isLayout={true} />}
    />
    <Route path={NavigationPath.Units} element={<PrivateRoute component={UnitsDefaultView} isLayout={true} />} />
    <Route path={NavigationPath.UnitsWithParams} element={<PrivateRoute component={UnitsView} isLayout={true} />} />
    <Route
      path={NavigationPath.UnitsWithParamsAndId}
      element={<PrivateRoute component={UnitDetailsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.UnitsWithParamsAndId}
      element={<PrivateRoute component={UnitsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.TechnicalOrders}
      element={<PrivateRoute component={TechnicalOrdersDefaultView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.TechnicalOrdersWithParams}
      element={<PrivateRoute component={TechnicalOrdersView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.TechnicalOrdersWithParamsAndId}
      element={<PrivateRoute component={TechnicalOrdersView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.HousekeepingApplications}
      element={<PrivateRoute component={HousekeepingApplicationsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.HousekeepingApplicationsWithParams}
      element={<PrivateRoute component={HousekeepingApplicationsWithParamsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PromotionsPackageVip}
      element={<PrivateRoute component={PackageVipView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PromotionsPackageVipWithParams}
      element={<PrivateRoute component={PackageVipView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PromoCodePrefixes}
      element={<PrivateRoute component={PromoCodePrefixesView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.BookingOptionsWithParams}
      element={<PrivateRoute component={BookingOptionsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.BookingOptions}
      element={<PrivateRoute component={BookingOptionsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PromotionsErliPromocode}
      element={<PrivateRoute component={ErliPromocodeView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PromoCodePromoCodes}
      element={<PrivateRoute component={PromoCodeListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PromoCodePromoCodesList}
      element={<PrivateRoute component={PromoCodeCodesView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PromotionsGastroCards}
      element={<PrivateRoute component={GastroCardsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PromotionsGastroCardsWithParams}
      element={<PrivateRoute component={GastroCardsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PromotionsGastroCoupon}
      element={<PrivateRoute component={GastroGenericProductView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PromotionsGastroCouponWithParams}
      element={<PrivateRoute component={GastroGenericProductView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PromocodeTouristVoucher}
      element={<PrivateRoute component={PromocodeTouristVoucherView} isLayout={true} />}
    />
    <Route path={NavigationPath.SellerList} element={<PrivateRoute component={SellerList} isLayout={true} />} />
    <Route
      path={NavigationPath.RcpUserEvents}
      element={<PrivateRoute component={RcpUserEventsView} isLayout={true} />}
    />
    <Route path={NavigationPath.Rcp} element={<PrivateRoute component={UsersView} isLayout={true} />} />
    <Route
      path={NavigationPath.ReceptionBookingCheckIn}
      element={<PrivateRoute component={ReceptionBookingCheckInView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReceptionBookingCheckOut}
      element={<PrivateRoute component={ReceptionBookingCheckOutView} isLayout={true} />}
    />
    <Route path={NavigationPath.ReportsReceptionSellers}>
      <Route index element={<PrivateRoute component={ReceptionSellersReport} isLayout={true} />} />
      <Route path=":resort_id" element={<PrivateRoute component={ReceptionSellersReport} isLayout={true} />} />
    </Route>
    <Route
      path={NavigationPath.HrAgreementList}
      element={<PrivateRoute component={AgreementListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.HrWorkersList}
      element={<PrivateRoute component={HrWorkersListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.CafeteriaList}
      element={<PrivateRoute component={CafeteriaListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.CafeteriaListWithParams}
      element={<PrivateRoute component={CafeteriaListView} isLayout={true} />}
    />
    <Route path={NavigationPath.ReceptionPos} element={<PrivateRoute component={ReceptionPosView} isLayout={true} />} />
    <Route
      path={NavigationPath.ReceptionWithParams}
      element={<PrivateRoute component={ReceptionWithParamsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.HousekeepingCleaningOrdersWithParams}
      element={<PrivateRoute component={HousekeepingCleaningOrdersParams} isLayout={true} />}
    />
    <Route
      path={NavigationPath.HousekeepingCleaningOrders}
      element={<PrivateRoute component={HousekeepingCleaningOrdersView} isLayout={true} />}
    />
    <Route path={NavigationPath.Reception} element={<PrivateRoute component={ReceptionView} isLayout={true} />} />
    <Route
      path={NavigationPath.ReportsPaymentsReportClimatic}
      element={
        <PrivateRoute
          component={() => <PaymentsReportView url={NavigationPath.ReportsPaymentsReportClimatic} kind="climatic" />}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.ReportsPaymentsReportNormal}
      element={
        <PrivateRoute
          component={() => <PaymentsReportView url={NavigationPath.ReportsPaymentsReportNormal} kind="normal" />}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.ReportsStatsSaleItem}
      element={<PrivateRoute component={StatsSaleItemView} isLayout={true} />}
    />
    <Route path={NavigationPath.MediaReport} element={<PrivateRoute component={MediaReports} isLayout={true} />} />
    <Route
      path={NavigationPath.ReportsUserReport}
      element={<PrivateRoute component={UserReportsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReservationDetails}
      element={<PrivateRoute component={ReservationDetailsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReservationDetailsPreview}
      element={<PrivateRoute component={ReservationDetailsPreviewView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReservationListWithParams}
      element={<PrivateRoute component={ReservationListWithParamsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReservationList}
      element={<PrivateRoute component={ReservationListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReservationStats}
      element={<PrivateRoute component={ReservationStatsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReservationImprovementsStats}
      element={<PrivateRoute component={ReservationImprovementsStatsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PaymentRegisterList}
      element={<PrivateRoute component={PaymentRegistersView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PaymentRegisterCashbox}
      element={<PrivateRoute component={PaymentRegisterCashboxView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PaymentRegisterCashboxWithParam}
      element={<PrivateRoute component={PaymentRegisterCashboxWithParams} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PaymentRegisterUserCashbox}
      element={<PrivateRoute component={PaymentRegisterUserCashBoxView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PaymentRegisterCashboxDayDetails}
      element={<PrivateRoute component={PaymentRegisterCashboxDayDetailsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReservationStatsBoard}
      element={<PrivateRoute component={ReservationStatsBoardView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReservationCreate}
      element={<PrivateRoute component={ReservationCreateView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReservationCreateWithParams}
      element={<PrivateRoute component={ReservationCreateView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.Reservations}
      element={<PrivateRoute component={ReservationsTimelineDefault} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ReservationsWithParams}
      element={<PrivateRoute component={ReservationView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ShopWarehouseInvoices}
      element={<PrivateRoute component={ShopWarehouseInvoicesView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.FeedingSpecialGuest}
      element={<PrivateRoute component={FeedingSpecialGuest} isLayout={true} />}
    />
    <Route
      path={NavigationPath.FeedingClients}
      element={<PrivateRoute component={FeedingClientsView} isLayout={true} />}
    />
    <Route path={NavigationPath.FeedingHost} element={<PrivateRoute component={FeedingHostView} isLayout={true} />} />
    <Route
      path={NavigationPath.FeedingWorker}
      element={<PrivateRoute component={FeedingWorkerView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.FeedingHostWithParams}
      element={<PrivateRoute component={FeedingHostWithParamsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.AccountUsersList}
      element={<PrivateRoute component={AccountUserListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.HousekeepingUsers}
      element={<PrivateRoute component={HousekeepingUsersListView} isLayout={true} />}
    />
    <Route path={NavigationPath.ShopPOS} element={<PrivateRoute component={ShopPOSView} isLayout={true} />} />
    <Route
      path={NavigationPath.ShopPOSWithParams}
      element={<PrivateRoute component={ShopPOSWithParamsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.ShopPOSWithParamsAndToken}
      element={<PrivateRoute component={ShopPOSWithParamsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.CashRegisterList}
      element={<PrivateRoute component={CashRegisterListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.CashRegisterListWithParams}
      element={<PrivateRoute component={CashRegisterListWithParamsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PackageWholesalePackages}
      element={<PrivateRoute component={PackageWholesalePackagesView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PackageWholesalePackagesWithPrams}
      element={<PrivateRoute component={PackageWholesalePackagesView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PackageWholesaleGastroCards}
      element={
        <PrivateRoute
          component={() => <PackageWholesaleGastroCardsView kind="wholesale" name="Podwójna radość" />}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.PackageWholesaleGastroCardsWithParams}
      element={
        <PrivateRoute
          component={() => <PackageWholesaleGastroCardsView kind="wholesale" name="Podwójna radość" />}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.PackageWholesaleGastroCardsBoost}
      element={
        <PrivateRoute
          component={() => (
            <PackageWholesaleGastroCardsView kind="gastro_card_boost_wholesale" name="Doładowanie Smaku" />
          )}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.PackageWholesaleGastroCardsBoostWithParams}
      element={
        <PrivateRoute
          component={() => (
            <PackageWholesaleGastroCardsView kind="gastro_card_boost_wholesale" name="Doładowanie Smaku" />
          )}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.PackageWholesaleGastroCards2024}
      element={
        <PrivateRoute
          component={() => (
            <PackageWholesaleGastroCardsView kind="gastro_card_2024_wholesale" name="Podwójna radość 2024" />
          )}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.PackageWholesaleGastroCards2024WithParams}
      element={
        <PrivateRoute
          component={() => (
            <PackageWholesaleGastroCardsView kind="gastro_card_2024_wholesale" name="Podwójna radość 2024" />
          )}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.PackageWholesaleGastroCards2025}
      element={
        <PrivateRoute
          component={() => (
            <PackageWholesaleGastroCardsView kind="gastro_card_2025_wholesale" name="Podwójna radość 2025" />
          )}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.PackageWholesaleGastroCards2025WithParams}
      element={
        <PrivateRoute
          component={() => (
            <PackageWholesaleGastroCardsView kind="gastro_card_2025_wholesale" name="Podwójna radość 2025" />
          )}
          isLayout={true}
        />
      }
    />
    <Route
      path={NavigationPath.PackageWholesaleProductList}
      element={<PrivateRoute component={PackageWholesaleProductListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PackageWholesaleList}
      element={<PrivateRoute component={PackageWholesaleListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.PackageWholesaleListWithParams}
      element={<PrivateRoute component={PackageWholesaleListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.RentalProducts}
      element={<PrivateRoute component={RentalProductsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.RentalWarehouses}
      element={<PrivateRoute component={RentalWarehousesView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.RentalProductCategories}
      element={<PrivateRoute component={RentalProductCategoriesView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.FloorHeating}
      element={<PrivateRoute component={FloorHeatingListView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.FloorHeatingDetails}
      element={<PrivateRoute component={FloorHeatingDetailsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.EcommerceStats}
      element={<PrivateRoute component={EcommerceStatsView} isLayout={true} />}
    />
    <Route path={NavigationPath.ServiceBands} element={<PrivateRoute component={ServiceBandView} isLayout={true} />} />
    <Route
      path={NavigationPath.CouponsClient}
      element={<PrivateRoute component={CouponsClientDetailsView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.CouponsTypesConfiguration}
      element={<PrivateRoute component={CouponsTypesConfigurationView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.EndlessHoliday}
      element={<PrivateRoute component={EndlessHolidayView} isLayout={true} />}
    />
    <Route
      path={NavigationPath.EndlessHolidayConfiguration}
      element={<PrivateRoute component={EndlessHolidayConfigurationView} isLayout={true} />}
    />
    <Route path={NavigationPath.CouponsTypes} element={<PrivateRoute component={CouponsTypesView} isLayout={true} />} />
    <Route path={NavigationPath.CouponsList} element={<PrivateRoute component={CouponsListView} isLayout={true} />} />
    <Route path={NavigationPath.Unauthorized} element={<UnauthorizedView />} />
    <Route path={NavigationPath.AuthorizationPiCodeLogin} element={<PinCodeLoginView />} />
    <Route path={NavigationPath.AuthorizationLogin} element={<LoginView />} />
    <Route path={NavigationPath.AuthorizationResetPasswordLink} element={<AuthorizationResetPasswordLinkView />} />
    <Route path={NavigationPath.AuthorizationResetPassword} element={<AuthorizationResetPasswordView />} />
    <Route path={NavigationPath.AuthorizationLogout} element={<LogoutView />} />
    <Route path={NavigationPath.Failure} element={<FailureView />} />
    <Route path="*" element={<HomeView />} />
  </Routes>
)

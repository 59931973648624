import { GastroGenericProductTag, GastroProductKind } from '@models/products'
import { DashboardUrlsState, UserPermission } from '@models/dashboard'
import { asDecimal } from '@helpers/utils'
import { GastroCardKind } from '@models/promotions'

export const getGastroProductApiUrl = (kind: GastroProductKind, urls: DashboardUrlsState) =>
  urls.promotions.gastro_generic.replace('replace-me', kind)

export const getGastroProductPercentageBonus = <T extends { promotion_multiplier: string }>(gastroCard: T) =>
  `${asDecimal(gastroCard.promotion_multiplier).minus(1).mul(100).toString()}%`

export const getGastroProductApiInfoUrl = (kind: GastroProductKind, urls: DashboardUrlsState) =>
  `${getGastroProductApiUrl(kind, urls)}info/`

export const getGastroGenericProductTag = (tagKey: string, appDataTags: GastroGenericProductTag[]) =>
  appDataTags.find((appDataTag: GastroGenericProductTag) => appDataTag.id === tagKey)

export const GastroGenericPermissions: Partial<
  Record<GastroCardKind, { initial: UserPermission; any: UserPermission }>
> = {
  gastro_card_boost: {
    initial: UserPermission.PromotionsGastroCardBoostCanChangeSellerInInitialVoucherStatus,
    any: UserPermission.PromotionsGastroCardBoostCanChangeSellerInAnyVoucherStatus,
  },
  gastro_card_2024: {
    initial: UserPermission.PromotionsGastroCard2024CanChangeSellerInInitialVoucherStatus,
    any: UserPermission.PromotionsGastroCard2024CanChangeSellerInAnyVoucherStatus,
  },
  gastro_card_2025: {
    initial: UserPermission.PromotionsGastroCard2025CanChangeSellerInInitialVoucherStatus,
    any: UserPermission.PromotionsGastroCard2025CanChangeSellerInAnyVoucherStatus,
  },
  gastro_card_2026: {
    initial: UserPermission.PromotionsGastroCard2026CanChangeSellerInInitialVoucherStatus,
    any: UserPermission.PromotionsGastroCard2026CanChangeSellerInAnyVoucherStatus,
  },
  gastro_card_2027: {
    initial: UserPermission.PromotionsGastroCard2027CanChangeSellerInInitialVoucherStatus,
    any: UserPermission.PromotionsGastroCard2027CanChangeSellerInAnyVoucherStatus,
  },
  gastro_card_dynamic: {
    initial: UserPermission.PromotionsGastroCardDynamicCanChangeSellerInInitialVoucherStatus,
    any: UserPermission.PromotionsGastroCardDynamicCanChangeSellerInAnyVoucherStatus,
  },
}

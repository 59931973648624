import * as React from 'react'
import { Button, Card, CardBody, Col } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { BenefitProgramAgreementDetails } from '@modules/benefit-program/models'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'

interface Props {
  benefitProgramAgreement: BenefitProgramAgreementDetails
}

export const BenefitProgramAgreementDetailsInformation: React.FC<Props> = ({ benefitProgramAgreement }) => {
  const user = useAuthenticatedUser()
  const [handleClientDetails] = useModal('ClientDetailsModal', { client: benefitProgramAgreement.client })

  return (
    <Col md={6}>
      <Card>
        <CardBody>
          <ProductCardTitle
            title="Dane pracownika"
            icon="uil-user"
            adminUrl={benefitProgramAgreement.urls.admin}
            isEditAllowed={user.is_superuser}
          />
          <table className="table table-sm mb-0">
            <tbody>
              <SubscriptionDetailsInformationRow name="Identyfikator pracownika" borderless={true}>
                {benefitProgramAgreement.number}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Imię i nazwisko">
                {benefitProgramAgreement.client.name}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Pracodawca">
                {benefitProgramAgreement.benefit_program.name}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Adres e-mail">
                {benefitProgramAgreement.client.email}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Telefon">
                {benefitProgramAgreement.client.email}
              </SubscriptionDetailsInformationRow>
              <tr>
                <td colSpan={2} />
              </tr>
            </tbody>
          </table>
          <div className="pt-2 d-flex">
            <Button color="primary" size="sm" onClick={handleClientDetails}>
              Zobacz profil gościa
            </Button>
            <ButtonWithIcon
              color="danger"
              icon="uil-multiply"
              text="Rezygnacja pracownika"
              btnSize="sm"
              btnClass="ml-auto"
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

import { ThunkAction } from 'redux-thunk'
import { RootState } from '@store/index'
import axios, { CancelTokenSource } from 'axios'
import { Apartment, ApartmentBookingDayInfo, ApartmentBookingFilter, ApartmentFetchDate } from '@models/apartment'
import {
  ContextMenuStateActions,
  RemoveCalendarBookingAction,
  SetActiveFilterAction,
  SetBookingModalDataAction,
  SetBookingModalDataWarningAction,
  SetCalendarApartmentsAction,
  SetCalendarBookingsAction,
  SetCalendarDatesAction,
  SetCalendarRentDaysAction,
  SetCompactCalendarAction,
  SetDayStatsAction,
  SetEditModeForItemAction,
  SetFetchDateAction,
  SetInitialDragDataAction,
  SetResortMapAction,
  SetSelectedColsActions,
  SetSelectedRowsActions,
  SetTimelineScrollPositionAction,
  TimelineActionTypesKeys,
  ToggleTimelineMaskAction,
  UpdateCalendarApartmentsAction,
  UpdateCalendarBookingDetailsAction,
  UpdateCalendarBookingsAction,
} from '@store/actions-types/timeline-actions-types'
import {
  CalendarDate,
  CalendarDatePosition,
  EditingBookingItem,
  InitialDragData,
  TimelineBooking,
  TimelineDailyStatsPayload,
  TimelineReceptionBooking,
  TimelineRentDays,
  TimelineScrollPosition,
  TimelineUpdateBookingItemPayload,
} from '@store/reducers/timeline-reducers'
import dateHelper, { formatDate } from '@helpers/date-helper'
import { ReceptionBookingDetails } from '@models/reception'
import { format } from 'date-fns'
import { BookingModalData } from '@modules/reservations/timeline/modals/timeline-booking-modal'
import timelineHelper from '@helpers/timeline-helper'
import * as R from 'ramda'
import { commonObjectGet, commonObjectPut } from '@store/actions/generic-actions'
import { BookingStats } from '@modules/reservations/models'
import { ACCOMMODATION_TYPES } from '@helpers/consts'

export function getApartmentList(
  resortId?: string | number,
  accommodationIds?: string[],
): ThunkAction<Promise<Apartment[]>, RootState, null, SetCalendarApartmentsAction | SetFetchDateAction> {
  return async (dispatch, getState) => {
    const params = {
      resort: resortId,
    }

    if (accommodationIds) {
      params['accommodation_type'] = accommodationIds
    }

    const url = getState().appState.appData.urls.rent.apartment_list

    const { data } = await axios.get(url, { params })
    const now = Date.now()
    await dispatch(
      setFetchDate({
        date: format(now, 'dd.LL'),
        hour: format(now, 'HH:mm'),
      }),
    )
    await dispatch(setApartments(data))
    return data
  }
}

export function getBookingsData(
  resortId: string,
  accommodationIds: string[],
  fromDate: string,
  toDate: string,
): ThunkAction<Promise<void>, RootState, null, SetCalendarBookingsAction | SetCalendarRentDaysAction> {
  return async (dispatch, getState) => {
    const url = getState().appState.appData.urls.rent.calendar_bookings

    const params = {
      resort: resortId,
      accommodation_type: accommodationIds,
      date_after: fromDate,
      date_before: toDate,
    }

    const response = await commonObjectGet<{ bookings: TimelineBooking[]; rent_days: TimelineRentDays[] }>(url, params)
    await dispatch(setBookings(response.bookings))
    await dispatch(setRentDays(response.rent_days))
  }
}

export function getBookingDetails(
  bookingId: number,
  url: string,
  cancelToken: CancelTokenSource,
): ThunkAction<Promise<ReceptionBookingDetails | undefined>, RootState, null, UpdateCalendarBookingDetailsAction> {
  return async (dispatch, getState) => {
    const bookingDetails = getState().timelineState.bookingsDetails.find(el => el.id === bookingId)
    if (bookingDetails) {
      return bookingDetails
    }

    const response = await commonObjectGet<ReceptionBookingDetails>(url, undefined, cancelToken)
    if (response) {
      await dispatch(updateBookingDetails(response))
    }
    return response
  }
}

export function fetchDailyStats(
  resort: string,
  date: string,
  accommodationTypeIds: string[],
  cancelToken: CancelTokenSource,
): ThunkAction<Promise<void>, RootState, null, SetDayStatsAction> {
  return async (dispatch, getState) => {
    const state = getState()

    const params: Partial<TimelineDailyStatsPayload> = {
      resort,
      accommodation_type: accommodationTypeIds,
      date_after: formatDate(date),
      date_before: formatDate(date),
    }

    const { details } = await commonObjectGet<BookingStats>(
      state.appState.appData.urls.rent.booking_stats,
      params,
      cancelToken,
    )

    if (details.length) {
      const data = details[0]

      const accommodationTypePrefix = {
        1: 'houses',
        4: 'houses',
        2: 'apartments_40',
        5: 'apartments_40',
        3: 'apartments_55',
      }

      const dailyChangePrefix = accommodationTypeIds.some(id => ACCOMMODATION_TYPES.HOUSES.includes(parseInt(id, 10)))
        ? 'houses'
        : 'apartments'

      const newStats = {
        available_apartments: data[`free_${accommodationTypePrefix[parseInt(accommodationTypeIds[0])]}`],
        dinners: data.dinner,
        breakfasts: data.breakfast,
        adults: data.adults,
        children: data.children,
        babies: data.babies,
        day_in: data[`${dailyChangePrefix}_day_in`],
        day_in_out: data[`${dailyChangePrefix}_day_in_out`],
        day_out: data[`${dailyChangePrefix}_day_out`],
      }

      await dispatch(setDayStats({ stats: newStats, date }))
    }
  }
}

export function getResortMap(
  resortId: string,
  hideFn: () => void,
): ThunkAction<Promise<string>, RootState, null, SetResortMapAction> {
  return async (dispatch, getState) => {
    const state = getState()
    const resort = state.appState.appData.resorts.find(res => res.id === parseInt(resortId, 10))

    if (!resort) {
      return
    }

    try {
      const { data } = await axios.get(resort.map, {
        headers: {
          accept: '*/*',
        },
      })
      await dispatch(setResortMap(data))
      return data
    } catch (error) {
      hideFn()
    }
  }
}

export function updateBookingApartment({
  url,
  dateTo,
  dateFrom,
  apartmentId,
}: TimelineUpdateBookingItemPayload): ThunkAction<Promise<void>, RootState, null, UpdateCalendarBookingsAction> {
  return async dispatch => {
    await dispatch(
      updateBookings(
        await commonObjectPut<ReceptionBookingDetails>(url, {
          new_apartment: apartmentId,
          date_to: dateTo,
          date_from: dateFrom,
        }),
      ),
    )
  }
}

export function showBookingModal(payload: BookingModalData): SetBookingModalDataAction {
  return { type: TimelineActionTypesKeys.SET_BOOKING_MODAL_DATA, payload }
}

export function setBookingModalWarning(payload: boolean): SetBookingModalDataWarningAction {
  return { type: TimelineActionTypesKeys.SET_BOOKING_MODAL_DATA_WARNING, payload }
}

export function resetBookingModalData(): SetBookingModalDataAction {
  return { type: TimelineActionTypesKeys.SET_BOOKING_MODAL_DATA, payload: null }
}

export function setDayStats(payload: ApartmentBookingDayInfo): SetDayStatsAction {
  return { type: TimelineActionTypesKeys.SET_DAY_STATS, payload }
}

export function setResortMap(payload: string): SetResortMapAction {
  return { type: TimelineActionTypesKeys.SET_RESORT_MAP, payload }
}

export function setBookings(payload: TimelineReceptionBooking[]): SetCalendarBookingsAction {
  return { type: TimelineActionTypesKeys.SET_CALENDAR_BOOKINGS, payload }
}

export function setRentDays(payload: TimelineRentDays[]): SetCalendarRentDaysAction {
  return { type: TimelineActionTypesKeys.SET_CALENDAR_RENT_DAYS, payload }
}

export function updateBookings(payload: ReceptionBookingDetails): UpdateCalendarBookingsAction {
  return { type: TimelineActionTypesKeys.UPDATE_CALENDAR_BOOKINGS, payload }
}

export function removeBooking(payload: number): RemoveCalendarBookingAction {
  return { type: TimelineActionTypesKeys.REMOVE_CALENDAR_BOOKING, payload }
}

export function updateBookingDetails(payload: ReceptionBookingDetails): UpdateCalendarBookingDetailsAction {
  return { type: TimelineActionTypesKeys.UPDATE_CALENDAR_BOOKING_DETAILS, payload }
}

export function setFetchDate(payload: ApartmentFetchDate): SetFetchDateAction {
  return { type: TimelineActionTypesKeys.SET_FETCH_DATE, payload }
}

export function setApartments(payload: Apartment[]): SetCalendarApartmentsAction {
  return { type: TimelineActionTypesKeys.SET_CALENDAR_APARTMENTS, payload: R.sortBy(R.prop('position'))(payload) }
}

export function updateApartments(payload: Apartment): UpdateCalendarApartmentsAction {
  return { type: TimelineActionTypesKeys.UPDATE_CALENDAR_APARTMENTS, payload }
}

export function setCalendarDates(
  { month, year }: CalendarDate,
  position: CalendarDatePosition,
): SetCalendarDatesAction {
  const payload = {
    year: dateHelper.isPrevYear(month) ? year - 1 : dateHelper.isNextYear(month) ? year + 1 : year,
    month: dateHelper.isPrevYear(month) ? 12 : dateHelper.isNextYear(month) ? 1 : month,
  }
  return {
    type: TimelineActionTypesKeys.SET_CALENDAR_DATES,
    payload: {
      dates: timelineHelper.createCalendarDates(payload),
      position,
    },
  }
}

export function setEditModeForItem(payload: EditingBookingItem | null): SetEditModeForItemAction {
  return { type: TimelineActionTypesKeys.SET_EDIT_MODE_FOR_ITEM, payload }
}

export function setScrollPosition(payload: TimelineScrollPosition): SetTimelineScrollPositionAction {
  return { type: TimelineActionTypesKeys.SET_SCROLL_POSITION, payload }
}

export function setCompactCalendar(payload: boolean): SetCompactCalendarAction {
  return { type: TimelineActionTypesKeys.SET_COMPACT_CALENDAR, payload }
}

export function toggleTimelineMask(): ToggleTimelineMaskAction {
  return { type: TimelineActionTypesKeys.TOGGLE_TIMELINE_MASK }
}

export function setInitialDragData(payload: InitialDragData): SetInitialDragDataAction {
  return { type: TimelineActionTypesKeys.SET_INITIAL_DRAG_DATA, payload }
}

export function setActiveFilter(payload: ApartmentBookingFilter[]): SetActiveFilterAction {
  return { type: TimelineActionTypesKeys.SET_ACTIVE_FILTER, payload }
}

export function setSelectedCols(payload: string[]): SetSelectedColsActions {
  return { type: TimelineActionTypesKeys.SET_SELECTED_COLS, payload }
}

export function setSelectedRows(payload: Apartment[]): SetSelectedRowsActions {
  return { type: TimelineActionTypesKeys.SET_SELECTED_ROWS, payload }
}

export function setContextMenuIsVisible(payload: boolean): ContextMenuStateActions {
  return { type: TimelineActionTypesKeys.SET_CONTEXT_MENU_IS_VISIBLE, payload }
}

import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { Card, CardBody } from 'reactstrap'
import { SubscriptionDetailsInformationOptionsAdd } from '@modules/subscription/details/options/add'
import { SubscriptionDetailsInformationOptionsRow } from '@modules/subscription/details/options/row'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsInformationOptions = ({ subscriptionDetails }: Props): JSX.Element => (
  <Card>
    <CardBody>
      <h4 className="mt-0 text-secondary mb-3">Opcje</h4>
      {subscriptionDetails.options.length ? (
        <table className="table table-sm">
          <tbody>
            {subscriptionDetails.options.map((option, index) => (
              <SubscriptionDetailsInformationOptionsRow key={option.id} option={option} index={index} />
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-center">Brak dodanych opcji</p>
      )}
      {!subscriptionDetails.read_only && subscriptionDetails.status !== 'cancelled' && (
        <SubscriptionDetailsInformationOptionsAdd subscriptionDetails={subscriptionDetails} />
      )}
    </CardBody>
  </Card>
)

import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { EndlessHolidayConfigurationTab } from '@modules/endless-holiday/configuration/index'

interface Props {
  activeTab: EndlessHolidayConfigurationTab
}

export const EndlessHolidayConfigurationAddButton = ({ activeTab }: Props): React.ReactNode => {
  const [showSetAddModal] = useModal('EndlessHolidaySetModal')
  const [showProductAddModal] = useModal('EndlessHolidayProductModal')

  const handleAdd = () => {
    if (activeTab === 'sets') {
      showSetAddModal()
    } else {
      showProductAddModal()
    }
  }

  return (
    <ButtonWithIcon
      icon="uil-plus lh-0"
      text="Dodaj"
      handleClick={handleAdd}
      color="green-dark"
      btnClass="btn-tall"
      disabled
    />
  )
}

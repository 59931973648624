import * as React from 'react'

import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { EndlessHolidayTransactionsTable } from '@modules/endless-holiday/transactions/table'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'
import { useGetEndlessHolidayTransactionsQuery } from '@api/endless-holiday'
import { EndlessHolidaySetsFilters } from '@modules/endless-holiday/configuration/sets/filters'

const defaultFilters: BaseTableFilters = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
}

export const EndlessHolidaySets: React.FC = () => {
  const [filters, setFilters] = React.useState<BaseTableFilters>(defaultFilters)

  const { data: transactions, isLoading } = useQueryWithPaginationResponse<any>(
    useGetEndlessHolidayTransactionsQuery(filters),
  )

  return (
    <>
      <EndlessHolidaySetsFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
      <EndlessHolidayTransactionsTable
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
        transactions={transactions}
      />
    </>
  )
}

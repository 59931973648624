import * as React from 'react'
import { UserPermission } from '@models/dashboard'
import { FormInput, FormPlain } from '@hyper/forms'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { GastroCardDetails } from '@models/promotions'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { FormSelect } from '@hyper/forms/form-select'
import { GastroProductKind, ProductsAppDataGastroProduct } from '@models/products'
import { GastroGenericProductPaymentMethodOptions } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-product-dialog'
import { useGastroProductProvider } from '@modules/products/hooks/use-gastro-product-provider'
import DatePickerInput from '@components/date/date-picker-input'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'

interface Props {
  gastroCoupon?: GastroCardDetails
  gastroProduct: ProductsAppDataGastroProduct
  kind: GastroProductKind
}

export const GastroGenericProductDialogForm: React.FC<Props> = ({ kind, gastroProduct, gastroCoupon }) => {
  const user = useAuthenticatedUser()
  const sellersOptions = useGroupedSellers([])

  const isProductConfirmed = gastroCoupon?.status === 'confirmed'

  const { crud_permissions, wallet_kind, can_set_source_marketing } = useGastroProductProvider(kind)

  const isDeclaredSellPriceDisabled = Boolean(
    gastroCoupon && (isProductConfirmed || !user.hasPerm(crud_permissions.can_change_declared_sell_price)),
  )
  const canSetEcommerceSource = user.hasPerm(crud_permissions.list_ecommerce)
  const sources = useGroupedSources().filter(source => (canSetEcommerceSource ? true : source.label !== 'E-Sklep'))

  const isSellerEditDisable = () => {
    if (gastroCoupon?.kind === 'gastro_coupon')
      return !user.hasPerm(UserPermission.PromotionsGastroCouponCanChangeSeller)

    if (gastroCoupon?.kind !== 'gastro_card_boost') return false

    if (gastroCoupon.status === 'initial') {
      return (
        !user.hasPerm(UserPermission.PromotionsGastroCardBoostCanChangeSellerInInitialVoucherStatus) &&
        !user.hasPerm(UserPermission.PromotionsGastroCardBoostCanChangeSellerInAnyVoucherStatus)
      )
    }

    return !user.hasPerm(UserPermission.PromotionsGastroCardBoostCanChangeSellerInAnyVoucherStatus)
  }

  return (
    <>
      {wallet_kind === 'fiscalization' && (
        <FormSelect
          options={sellersOptions}
          name="seller"
          label="Sprzedawca"
          formPlainProps={{ colSize: 4 }}
          selectProps={{
            isClearable: true,
            isDisabled: isSellerEditDisable(),
          }}
        />
      )}

      <FormInput
        disabled={isDeclaredSellPriceDisabled}
        colSize={4}
        placeholder={`min. ${gastroProduct.minimal_value} zł`}
        label={wallet_kind === 'fiscalization' ? 'Deklarowana kwota zakupu' : 'Kwota'}
        name="declared_sell_price"
      />
      {wallet_kind === 'fiscalization' && gastroProduct.allow_pay_by_cash && (
        <FormSelect
          options={GastroGenericProductPaymentMethodOptions}
          name="payment_method"
          formPlainProps={{ colSize: 4 }}
          label="Metoda płatności"
          selectProps={{
            isDisabled:
              isProductConfirmed || !user.hasPerm(UserPermission.PromotionsGastroCouponCanChangePaymentMethod),
          }}
        />
      )}
      {can_set_source_marketing && (
        <FormSelect
          options={sources}
          name="source_marketing"
          formPlainProps={{ colSize: 4 }}
          label="Źródło"
          selectProps={{ isClearable: true, isDisabled: gastroCoupon?.is_confirmed }}
        />
      )}
      {gastroCoupon && (
        <FormPlain name="auto_cancel_after_date" colSize={4}>
          <label>Auto anulowanie po</label>
          <DatePickerInput
            name="auto_cancel_after_date"
            isClearable={true}
            wrapperClassName="flex-grow-1"
            isDisabled={!user.hasPerm(UserPermission.PromotionsSubscriptionCanChangeAutoCancelAfterDate)}
          />
        </FormPlain>
      )}
    </>
  )
}

import * as React from 'react'
import { EndlessHolidayTransactionsFilters } from '@modules/endless-holiday/transactions/filters'
import { EndlessHolidayTransactionsTable } from '@modules/endless-holiday/transactions/table'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'
import { useGetEndlessHolidayTransactionsQuery } from '@api/endless-holiday'

const defaultFilters: BaseTableFilters = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
}

export const EndlessHolidayTransactions = (): React.ReactNode => {
  const [filters, setFilters] = React.useState<BaseTableFilters>(defaultFilters)

  const { data: transactions, isLoading } = useQueryWithPaginationResponse<any>(
    useGetEndlessHolidayTransactionsQuery(filters),
  )

  return (
    <div>
      <EndlessHolidayTransactionsFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
      <EndlessHolidayTransactionsTable
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
        transactions={transactions}
      />
    </div>
  )
}

import * as React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Row } from 'reactstrap'
import { BenefitProgramCreateInputs } from '@modules/benefit-program/create/index'
import { FormInput } from '@hyper/forms'
import { ButtonWithIcon } from '@components/button-with-icon'

export const BenefitProgramUsers: React.FC = () => {
  const { control } = useFormContext<BenefitProgramCreateInputs>()
  const { fields, append, remove } = useFieldArray({ control, name: 'users' })

  const handleAppendRow = () => {
    append({ name: '', email: '', phone: '' })
  }

  return (
    <div className="bg-grey pt-2 border-top px-2 form-transparent">
      <h5 className="fw-semi-bold">Osoba reprezentująca:</h5>
      {fields.map((field, index) => (
        <Row key={field.id} className="align-items-center mt-2">
          <Input type="text" index={index} name="name" placeholder="Imię i nazwisko" colSize={4} />
          <Input type="email" index={index} name="email" placeholder="Adres e-mail" colSize={4} />
          <Input type="tel" index={index} name="phone" placeholder="Numer telefonu" colSize={3} />
          {fields.length > 1 && (
            <i className="uil-trash-alt font-16 col-1 cursor-pointer" onClick={() => remove(index)} />
          )}
        </Row>
      ))}
      <div className="border-top mt-3 py-1 mx-n2">
        <ButtonWithIcon
          handleClick={handleAppendRow}
          icon="uil-plus"
          text="Dodaj osobę do kontaktu"
          btnClass="shadow-none bg-transparent border-0 text-default d-block mx-auto"
        />
      </div>
    </div>
  )
}

const Input = ({ name, placeholder, colSize, index, type }) => (
  <FormInput
    type={type}
    formGroupClassName="mb-0"
    colSize={colSize}
    name={`users.${index}.${name}`}
    placeholder={placeholder}
  />
)

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from '@api/base'
import { extractSelectOptionsValues } from '@helpers/utils'
import { PaginationResponse } from '@models/dashboard'
import { RootState } from '@store/index'
import { TableFilters } from '@components/table/table'
import { createApiTag, provideListTags } from '@api/query-helper'
import { EndlessHolidayInvitation, ProductPackage, ProductPackageDetails } from '@modules/endless-holiday/models'

interface EndlessHolidayCreationPayload {
  url: string
  payload: {
    emails: string
    product_package_set: number
    seller: number
  }[]
}

const ENDLESS_HOLIDAY_TRANSACTION_TAG = 'ENDLESS_HOLIDAY_TRANSACTION'
const ENDLESS_HOLIDAY_TRANSACTION_TAG_DETAILS = 'ENDLESS_HOLIDAY_TRANSACTION_DETAILS'

const ENDLESS_HOLIDAY_INVITATION_TAG = 'ENDLESS_HOLIDAY_INVITATION'

export const endlessHolidayApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'endlessHolidayApi',
  tagTypes: [ENDLESS_HOLIDAY_TRANSACTION_TAG, ENDLESS_HOLIDAY_INVITATION_TAG, ENDLESS_HOLIDAY_TRANSACTION_TAG_DETAILS],
  endpoints: builder => ({
    getEndlessHolidayTransactions: builder.query<PaginationResponse<ProductPackage>, TableFilters>({
      queryFn: apiQueryFunction((state: RootState, data: TableFilters) => ({
        params: { ...(extractSelectOptionsValues(data) || {}) },
        method: 'GET',
        url: state.appState.appData.urls.product_packages.product_package_list,
      })),
      providesTags: ({ results }: PaginationResponse<any>) =>
        provideListTags(ENDLESS_HOLIDAY_TRANSACTION_TAG, ENDLESS_HOLIDAY_TRANSACTION_TAG)(results),
    }),

    getEndlessHolidayTransactionDetails: builder.query<ProductPackageDetails, ProductPackage>({
      queryFn: apiQueryFunction((_, product: ProductPackage) => ({
        method: 'GET',
        url: product.urls.details,
      })),
      providesTags: (result: ProductPackageDetails) => [
        createApiTag(ENDLESS_HOLIDAY_TRANSACTION_TAG_DETAILS, result.id),
      ],
    }),

    getEndlessHolidayInvitations: builder.query<PaginationResponse<EndlessHolidayInvitation>, TableFilters>({
      queryFn: apiQueryFunction((state: RootState, data: TableFilters) => ({
        params: { ...(extractSelectOptionsValues(data) || {}) },
        method: 'GET',
        url: state.appState.appData.urls.product_packages.product_package_invite_list,
      })),
      providesTags: ({ results }: PaginationResponse<EndlessHolidayInvitation>) =>
        provideListTags(ENDLESS_HOLIDAY_INVITATION_TAG, ENDLESS_HOLIDAY_INVITATION_TAG)(results),
    }),
    createEndlessHolidayInvite: builder.mutation<EndlessHolidayInvitation, EndlessHolidayCreationPayload>({
      invalidatesTags: [ENDLESS_HOLIDAY_INVITATION_TAG],
      queryFn: apiQueryFunction((_, { url, payload }: EndlessHolidayCreationPayload) => ({
        data: payload,
        method: 'POST',
        url,
      })),
    }),
  }),
})

export const {
  useGetEndlessHolidayTransactionsQuery,
  useGetEndlessHolidayTransactionDetailsQuery,
  useGetEndlessHolidayInvitationsQuery,
  useCreateEndlessHolidayInviteMutation,
} = endlessHolidayApi

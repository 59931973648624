import * as React from 'react'

import { Apartment, ApartmentBookingFilter } from '@models/apartment'
import TimelineItemTooltip from '@modules/reservations/timeline/item-tooltip/timeline-item-tooltip'
import { CalendarDate, TimelineBooking } from '@store/reducers/timeline-reducers'
import TimelineItemBasicView, { EditModeType } from '@modules/reservations/timeline/timeline-item-basic'
import TimelineItemExtenderView from '@modules/reservations/timeline/timeline-item-extender'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import timelineHelper from '@helpers/timeline-helper'
import { BookingExtendedStayKind } from '@models/reception'
import { ContextMenu, ContextMenuState } from '@components/context-menu'
import { TimelineItemContextMenuItems } from '@modules/reservations/timeline/timeline-item-context-menu-items'
import { TimelineItemEditAbort } from '@modules/reservations/timeline/timeline-item-edit-abort'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { setContextMenuIsVisible } from '@store/actions/timeline-actions'

const BOOKING_FILTERS = [
  'unavailable',
  'confirmed',
  'initial',
  'checkedin',
  'close',
  'unfinished',
  'extended',
  'employee',
  'only_joined',
  'show_excluded_from_sale',
  'hide_unavailable',
]

interface Props {
  booking: TimelineBooking
  apartment: Apartment
  allApartments: Apartment[]
  filters: ApartmentBookingFilter[]
  renderedDates: CalendarDate[]
  joinedBookingIds: number[]
  resortId: string
}

const TimelineItemWrapperView: React.FC<Props> = ({
  booking,
  filters,
  renderedDates,
  apartment,
  allApartments,
  joinedBookingIds,
  resortId,
}) => {
  const editingBookingItem = useAppSelector((state: RootState) => state.timelineState.editingBookingItem)
  const isCompactView = useAppSelector((state: RootState) => state.timelineState.isCompactView)

  const isJoined = joinedBookingIds.includes(booking.id)
  const user = useAuthenticatedUser()

  const [editMode, setEditMode] = React.useState<EditModeType>(null)

  const dispatch = useAppDispatch()

  const bookingFilters = React.useMemo(() => filters.filter(filter => BOOKING_FILTERS.includes(filter)), [filters])

  const isFiltered = React.useMemo(() => {
    if (!bookingFilters.length) return false

    if (
      bookingFilters.includes('show_excluded_from_sale') &&
      (booking.apartment.is_virtual || !booking.apartment.available_for_sale)
    )
      return false

    if (bookingFilters.length === 1 && bookingFilters.includes('hide_unavailable')) return false
    if (bookingFilters.includes('only_joined') && isJoined) return false
    if (bookingFilters.includes('extended') && booking.extended_stay) return false
    if (bookingFilters.includes('employee') && booking.kind === 'employee') return false

    switch (booking.status) {
      case 'system':
      case 'fake':
      case 'blocked':
        return !bookingFilters.includes('unavailable')
      case 'close':
        return !bookingFilters.includes('close')
      case 'initial':
        return !bookingFilters.includes('initial')
      case 'unfinished':
        return !bookingFilters.includes('unfinished')
      case 'confirmed':
        return booking.checked_in ? !bookingFilters.includes('checkedin') : !bookingFilters.includes('confirmed')
      default:
        return false
    }
  }, [bookingFilters, booking, isJoined])

  const isExtendedStay = React.useMemo(
    () =>
      booking.extended_stay &&
      (booking.extended_stay_type === BookingExtendedStayKind.EIGHT_O_CLOCK ||
        booking.extended_stay_type === BookingExtendedStayKind.TWELVE_O_CLOCK),
    [booking.extended_stay, booking.extended_stay_type],
  )

  const isReadOnly =
    !user.resort_ids.includes(parseInt(resortId, 10)) && user.hasPerm(UserPermission.BookingCalendarCanViewAllResorts)

  const handleContextMenuStateChange = (state: ContextMenuState) => {
    dispatch(setContextMenuIsVisible(state === 'open'))
  }

  return (
    <div className="cursor-pointer">
      <ContextMenu
        isDisabled={isReadOnly}
        onMenuStateChange={handleContextMenuStateChange}
        menuItems={
          <TimelineItemContextMenuItems booking={booking} onEditModeChange={setEditMode} isFiltered={isFiltered} />
        }
      >
        <TimelineItemBasicView
          allApartments={allApartments}
          renderedDates={renderedDates}
          apartment={apartment}
          booking={booking}
          isFiltered={isFiltered}
          isCompactView={isCompactView}
          isJoined={isJoined}
          editMode={editMode}
          onEditModeChange={setEditMode}
        />
      </ContextMenu>
      {isExtendedStay && (
        <TimelineItemExtenderView
          isFiltered={isFiltered}
          booking={booking}
          apartment={apartment}
          renderedDates={renderedDates}
          allApartments={allApartments}
          isCompactView={isCompactView}
        />
      )}
      {!editingBookingItem && (
        <TimelineItemTooltip booking={booking} isExtendedStay={isExtendedStay} isReadOnly={isReadOnly} />
      )}
      {!!editMode && (
        <TimelineItemEditAbort
          onEditModeChange={setEditMode}
          position={timelineHelper.getEditAbortIconPosition(
            editMode,
            renderedDates,
            booking.date_from,
            booking.date_to,
            booking.days_count,
            apartment,
            allApartments,
          )}
        />
      )}
    </div>
  )
}

export default TimelineItemWrapperView

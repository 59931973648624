import * as React from 'react'
import classnames from 'classnames'

export const useExpandableItems = <T,>() => {
  const [expandedItems, setExpandedItems] = React.useState<T[]>([])

  const toggleExpanded = (row: T) => () => {
    setExpandedItems(items => (items.includes(row) ? items.filter(el => el !== row) : [...items, row]))
  }

  const setExpanded = (row: T, isExpanded: boolean) => {
    setExpandedItems(items => (isExpanded ? [...new Set([...items, row])] : items.filter(el => el !== row)))
  }

  const isExpanded = (row: T) => expandedItems.includes(row)

  const setIsExpanded = (row: T) => setExpandedItems(expanded => [...expanded, row])

  return {
    toggleExpanded,
    isExpanded,
    setIsExpanded,
    ExpandArrow,
    setExpanded,
  }
}

interface ExpandableArrowProps {
  className?: string
  isExpanded: boolean | undefined
  onExpand?: () => void
  fontSize?: string
}

export const ExpandArrow = ({
  className,
  isExpanded,
  onExpand,
  fontSize = 'font-20',
}: ExpandableArrowProps): JSX.Element => (
  <i
    className={classnames('uil-angle-right cursor-pointer', fontSize, className, { 'rotate-90': isExpanded })}
    onClick={onExpand}
    role="expand-arrow"
  />
)

import * as React from 'react'
import { BenefitProgramDetails } from '@modules/benefit-program/models'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { BenefitProgramInvitesTable } from '@modules/benefit-program/invites/table'
import { IconWithText } from '@components/icon-with-text'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'

interface Props {
  benefitProgram: BenefitProgramDetails
}

export const BenefitProgramDetailsInvites = React.forwardRef(
  ({ benefitProgram }: Props, ref: React.RefObject<HTMLElement>): React.ReactNode => (
    <Card innerRef={ref}>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <ProductCardTitle
            isEditAllowed={false}
            title={
              <IconWithText text="Wysłane zaproszenia" icon="uil-envelope-check font-20" textClass="text-semi-strong" />
            }
          />

          <SearchFilter hideLabel />
        </div>

        <BenefitProgramInvitesTable benefitProgramInvites={benefitProgram.invites} />
      </CardBody>
    </Card>
  ),
)

import { Apartment, ApartmentBookingDayInfo, ApartmentBookingFilter, ApartmentFetchDate } from '@models/apartment'
import {
  CalendarDatesState,
  EditingBookingItem,
  InitialDragData,
  TimelineReceptionBooking,
  TimelineRentDays,
  TimelineScrollPosition,
} from '@store/reducers/timeline-reducers'
import { BookingModalData } from '@modules/reservations/timeline/modals/timeline-booking-modal'
import { ReceptionBookingDetails } from '@models/reception'

export enum TimelineActionTypesKeys {
  SET_CALENDAR_APARTMENTS = 'SET_CALENDAR_APARTMENTS',
  SET_CALENDAR_BOOKINGS = 'SET_CALENDAR_BOOKINGS',
  SET_CALENDAR_RENT_DAYS = 'SET_CALENDAR_RENT_DAYS',
  SET_CALENDAR_DATES = 'SET_CALENDAR_DATES',
  SET_DAY_STATS = 'SET_DAY_STATS',
  SET_BOOKING_MODAL_DATA = 'SET_BOOKING_MODAL_DATA',
  SET_BOOKING_MODAL_DATA_WARNING = 'SET_BOOKING_MODAL_DATA_WARNING',
  SET_EDIT_MODE_FOR_ITEM = 'SET_EDIT_MODE_FOR_ITEM',
  SET_FETCH_DATE = 'SET_FETCH_DATE',
  SET_RESORT_MAP = 'SET_RESORT_MAP',
  UPDATE_CALENDAR_APARTMENTS = 'UPDATE_CALENDAR_APARTMENTS',
  REMOVE_CALENDAR_BOOKING = 'REMOVE_CALENDAR_BOOKING',
  UPDATE_CALENDAR_BOOKINGS = 'UPDATE_CALENDAR_BOOKINGS',
  UPDATE_CALENDAR_BOOKING_DETAILS = 'UPDATE_CALENDAR_BOOKING_DETAILS',
  SET_SCROLL_POSITION = 'SET_SCROLL_POSITION',
  SET_COMPACT_CALENDAR = 'SET_COMPACT_CALENDAR',
  TOGGLE_TIMELINE_MASK = 'TOGGLE_TIMELINE_MASK',
  SET_INITIAL_DRAG_DATA = 'SET_INITIAL_DRAG_DATA',
  SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER',
  RESET_ACTIVE_FILTER = 'RESET_ACTIVE_FILTER',
  SET_SELECTED_COLS = 'SET_SELECTED_COLS',
  SET_SELECTED_ROWS = 'SET_SELECTED_ROWS',
  SET_CONTEXT_MENU_IS_VISIBLE = 'SET_CONTEXT_MENU_IS_VISIBLE',
}

export interface SetEditModeForItemAction {
  type: TimelineActionTypesKeys.SET_EDIT_MODE_FOR_ITEM
  payload: EditingBookingItem | null
}

export interface SetBookingModalDataAction {
  type: TimelineActionTypesKeys.SET_BOOKING_MODAL_DATA
  payload: BookingModalData | null
}

export interface SetBookingModalDataWarningAction {
  type: TimelineActionTypesKeys.SET_BOOKING_MODAL_DATA_WARNING
  payload: boolean
}

export interface SetDayStatsAction {
  type: TimelineActionTypesKeys.SET_DAY_STATS
  payload: ApartmentBookingDayInfo
}

export interface SetFetchDateAction {
  type: TimelineActionTypesKeys.SET_FETCH_DATE
  payload: ApartmentFetchDate
}

export interface SetCalendarBookingsAction {
  type: TimelineActionTypesKeys.SET_CALENDAR_BOOKINGS
  payload: TimelineReceptionBooking[]
}

export interface SetCalendarRentDaysAction {
  type: TimelineActionTypesKeys.SET_CALENDAR_RENT_DAYS
  payload: TimelineRentDays[]
}

export interface UpdateCalendarBookingsAction {
  type: TimelineActionTypesKeys.UPDATE_CALENDAR_BOOKINGS
  payload: ReceptionBookingDetails
}

export interface RemoveCalendarBookingAction {
  type: TimelineActionTypesKeys.REMOVE_CALENDAR_BOOKING
  payload: number
}

export interface UpdateCalendarBookingDetailsAction {
  type: TimelineActionTypesKeys.UPDATE_CALENDAR_BOOKING_DETAILS
  payload: ReceptionBookingDetails
}

export interface SetResortMapAction {
  type: TimelineActionTypesKeys.SET_RESORT_MAP
  payload: string
}

export interface SetCalendarApartmentsAction {
  type: TimelineActionTypesKeys.SET_CALENDAR_APARTMENTS
  payload: Apartment[]
}

export interface UpdateCalendarApartmentsAction {
  type: TimelineActionTypesKeys.UPDATE_CALENDAR_APARTMENTS
  payload: Apartment
}

export interface SetCalendarDatesAction {
  type: TimelineActionTypesKeys.SET_CALENDAR_DATES
  payload: CalendarDatesState
}

export interface SetTimelineScrollPositionAction {
  type: TimelineActionTypesKeys.SET_SCROLL_POSITION
  payload: TimelineScrollPosition
}

export interface SetCompactCalendarAction {
  type: TimelineActionTypesKeys.SET_COMPACT_CALENDAR
  payload: boolean
}

export interface ToggleTimelineMaskAction {
  type: TimelineActionTypesKeys.TOGGLE_TIMELINE_MASK
}

export interface SetInitialDragDataAction {
  type: TimelineActionTypesKeys.SET_INITIAL_DRAG_DATA
  payload: InitialDragData
}

export interface SetActiveFilterAction {
  type: TimelineActionTypesKeys.SET_ACTIVE_FILTER
  payload: ApartmentBookingFilter[]
}

export interface ResetActiveFilterAction {
  type: TimelineActionTypesKeys.RESET_ACTIVE_FILTER
}

export interface SetSelectedColsActions {
  type: TimelineActionTypesKeys.SET_SELECTED_COLS
  payload: string[]
}

export interface SetSelectedRowsActions {
  type: TimelineActionTypesKeys.SET_SELECTED_ROWS
  payload: Apartment[]
}

export interface ContextMenuStateActions {
  type: TimelineActionTypesKeys.SET_CONTEXT_MENU_IS_VISIBLE
  payload: boolean
}

export type TimelineActionTypes =
  | SetCalendarApartmentsAction
  | RemoveCalendarBookingAction
  | SetCalendarBookingsAction
  | SetCalendarDatesAction
  | SetResortMapAction
  | SetDayStatsAction
  | SetFetchDateAction
  | UpdateCalendarBookingsAction
  | UpdateCalendarApartmentsAction
  | SetBookingModalDataAction
  | SetEditModeForItemAction
  | UpdateCalendarBookingDetailsAction
  | SetBookingModalDataWarningAction
  | SetTimelineScrollPositionAction
  | SetCompactCalendarAction
  | ToggleTimelineMaskAction
  | SetInitialDragDataAction
  | SetActiveFilterAction
  | ResetActiveFilterAction
  | SetSelectedColsActions
  | SetSelectedRowsActions
  | ContextMenuStateActions
  | SetCalendarRentDaysAction

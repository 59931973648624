import * as React from 'react'
import { BookingStatsDetails, ReservationStatsTableCells, StatsTableOptions } from '@modules/reservations/models'
import { ReservationStatsTableExpandedRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-expanded-row'

interface ReservationStatsOccupancyRowsProps {
  getCells: (element: string, options?: StatsTableOptions<BookingStatsDetails>) => ReservationStatsTableCells
}

export const ReservationStatsOccupancyRows: React.FC<ReservationStatsOccupancyRowsProps> = ({ getCells }) => {
  const getColoredCells = (element: string) => {
    const greenCells = [
      'occupied_houses',
      'blocked_houses',
      'occupied_apartments_40',
      'blocked_apartments_40',
      'occupied_apartments_55',
      'blocked_apartments_55',
      'object_occupancy',
    ]

    const className = greenCells.includes(element) ? 'text-danger' : 'text-success'
    return getCells(element, {
      tableCellOption: { className },
      tableRowSummaryOption: { className },
    })
  }

  const gerPercentageCells = el =>
    getCells(el, {
      tableCellOption: { unit: '%' },
      tableRowSummaryOption: { unit: '%' },
    })

  return (
    <>
      <ReservationStatsTableExpandedRow title="Zajętych domków" cells={getColoredCells('occupied_houses')} />
      <ReservationStatsTableExpandedRow title="Wolnych domków" cells={getColoredCells('free_houses')} />
      <ReservationStatsTableExpandedRow title="Zablokowanych domków" cells={getColoredCells('blocked_houses')} />
      <ReservationStatsTableExpandedRow
        title="Zajętych apartamentów 40m²"
        cells={getColoredCells('occupied_apartments_40')}
      />
      <ReservationStatsTableExpandedRow
        title="Wolnych apartamentów 40m²"
        cells={getColoredCells('free_apartments_40')}
      />
      <ReservationStatsTableExpandedRow
        title="Zablokowanych apartamentów 40m²"
        cells={getColoredCells('blocked_apartments_40')}
      />
      <ReservationStatsTableExpandedRow
        title="Zajętych apartamentów 55m²"
        cells={getColoredCells('occupied_apartments_55')}
      />
      <ReservationStatsTableExpandedRow
        title="Wolnych apartamentów 55m²"
        cells={getColoredCells('free_apartments_55')}
      />
      <ReservationStatsTableExpandedRow
        title="Zablokowanych apartamentów 55m²"
        cells={getColoredCells('blocked_apartments_55')}
      />
      <ReservationStatsTableExpandedRow title="Obłożenie obiektu" cells={gerPercentageCells('object_occupancy')} />
    </>
  )
}

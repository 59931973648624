import * as React from 'react'
import {
  BookingStatsDetails,
  ReservationStatsTableCells,
  StatsCellContent,
  StatsTableOptions,
} from '@modules/reservations/models'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { useFormContext } from 'react-hook-form'
import { ReservationStatsReportCell } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-report-cell'
import { ReservationStatsTableExpandedRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-expanded-row'
import { ReservationStatsPlanningReportRows } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-planning-report-rows'
import { useAppDispatch } from '@store/index'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { useAppData } from '@components/hooks/use-app-data'
import { asDecimal } from '@helpers/utils'
import { ReservationStatsCashToReturnRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-cash-to-return-row'

interface ReservationStatsPlanningRowsProps {
  getCells: (
    element: keyof BookingStatsDetails,
    options?: StatsTableOptions<BookingStatsDetails>,
  ) => ReservationStatsTableCells
}

export const ReservationStatsPlanningRows: React.FC<ReservationStatsPlanningRowsProps> = ({ getCells }) => {
  const { getValues, setError } = useFormContext()

  const { urls } = useAppData()

  const dispatch = useAppDispatch()

  const { action: handleReportClick } = useReportFormRequest(
    async (content: StatsCellContent<BookingStatsDetails>, url = undefined) => {
      const { resort } = getValues()
      await dispatch(
        createReportTask([
          url || urls.reports.report_task_booking_check,
          {
            date: content.statsDetails.date,
            action_kind: content.element,
            ...(resort?.value && { resort: resort.value }),
          },
        ]),
      )
    },
    setError,
    'notification',
  )

  const getReportDataCells = (field, url: string | undefined = undefined) =>
    getCells(field, {
      tableCellOption: {
        wrapper: content => (
          <ReservationStatsReportCell
            content={content}
            onClick={() => handleReportClick(content, url)}
            color="text-gold-light"
          />
        ),
      },
      tableRowSummaryOption: { className: 'text-gold-light' },
    })

  const getCellsAsFixedValues = (field: keyof BookingStatsDetails) =>
    getCells(field, {
      tableCellOption: {
        wrapper: content => <span>{asDecimal(content.statsDetails[field]).toFixed()}</span>,
      },
    })

  return (
    <>
      <ReservationStatsTableExpandedRow title="Wyjazdo-Przyjazdów" cells={getCells('day_in_out')} />
      <ReservationStatsPlanningReportRows
        apartmentsDataCells={getReportDataCells('apartments_day_in_out')}
        housesDataCells={getReportDataCells('houses_day_in_out')}
      />
      <ReservationStatsTableExpandedRow title="Przyjazdów" cells={getCells('day_in')} />
      <ReservationStatsPlanningReportRows
        apartmentsDataCells={getReportDataCells('apartments_day_in')}
        housesDataCells={getReportDataCells('houses_day_in')}
        lightGray
      />
      <ReservationStatsTableExpandedRow title="Wyjazdów" cells={getCells('day_out')} />
      <ReservationStatsPlanningReportRows
        apartmentsDataCells={getReportDataCells('apartments_day_out')}
        housesDataCells={getReportDataCells('houses_day_out')}
      />
      <ReservationStatsTableExpandedRow
        title="Potrzebny zasób przyjazd-wyjazd 10:00-16:00"
        cells={getCellsAsFixedValues('housekeeping_morning_shift_workers')}
      />
      <ReservationStatsTableExpandedRow
        title="Potrzebny zasób wyjazd 16:00-20:00"
        cells={getCellsAsFixedValues('housekeeping_afternoon_shift_workers')}
      />
      <ReservationStatsTableExpandedRow
        title="Przyjazdy krótsze niż 4 doby (bez VIP)"
        cells={getReportDataCells('arrivals_short', urls.reports.report_task_booking_stats_report)}
      />
      <ReservationStatsTableExpandedRow
        title="Przyjazdy co najmniej 4 dobowe (+ VIP)"
        cells={getReportDataCells('arrivals_premium', urls.reports.report_task_booking_stats_report)}
      />
      <ReservationStatsTableExpandedRow
        title="Rezerwacje kolizyjne (do 12:00/od 13:00)"
        cells={getReportDataCells(
          'arrivals_with_departures_collision',
          urls.reports.report_task_booking_collision_stats_report,
        )}
      />
      <ReservationStatsTableExpandedRow title="Przyjazdy osób dorosłych" cells={getCells('arrival_adults')} />
      <ReservationStatsTableExpandedRow title="Przyjazdy dzieci" cells={getCells('arrival_children')} />
      <ReservationStatsTableExpandedRow title="Przyjazdy niemowląt" cells={getCells('arrival_babies')} />
      <ReservationStatsTableExpandedRow title="Pakiet VIP (przyjazd)" cells={getCells('arrival_package_vips')} />
      <ReservationStatsCashToReturnRow
        getCells={getCells}
        onGenerateReport={handleReportClick}
        url={urls.reports.report_task_booking_stats_report}
      />
    </>
  )
}

import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { useTabs } from '@components/tabs'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { EndlessHolidaySets } from '@modules/endless-holiday/configuration/sets'
import { EndlessHolidayProducts } from '@modules/endless-holiday/configuration/products'
import { EndlessHolidayConfigurationAddButton } from '@modules/endless-holiday/configuration/add-button'

export type EndlessHolidayConfigurationTab = 'sets' | 'products'

const AvailableTabs = [
  { id: 'sets' as const, name: 'Zestawy' },
  { id: 'products' as const, name: 'Produkty' },
]

export const EndlessHolidayConfigurationView: React.FC = () => {
  const navigate = useNavigate()

  const { Tabs, isTabActive, activeTab } = useTabs<EndlessHolidayConfigurationTab>('sets')

  const pageTitle = `Wakacje w nieskończoność — ${isTabActive('sets') ? 'Zestawy' : 'Produkty'}`
  useDocumentTitle(pageTitle)

  const navigateToLists = () => {
    navigate(NavigationPath.EndlessHoliday)
  }

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <div className="d-flex">
            <Tabs tabs={AvailableTabs} className="w-100 mr-2" />

            <ButtonWithIcon
              handleClick={navigateToLists}
              icon="uil-angle-left font-14"
              text="Wróć "
              color="light"
              btnClass="btn-tall mr-2"
            />
            <EndlessHolidayConfigurationAddButton activeTab={activeTab} />
          </div>

          {isTabActive('sets') && <EndlessHolidaySets />}
          {isTabActive('products') && <EndlessHolidayProducts />}
        </CardBody>
      </Card>
    </>
  )
}

import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { useCreateEndlessHolidayInviteMutation } from '@api/endless-holiday'
import * as React from 'react'
import { showStepError } from '@modules/reception/common/reception-booking-check-steps'
import { handleEmailInvitationErrors } from '@components/email-invite/email-invite'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { EndlessHolidayCreateFormInputs } from '@modules/endless-holiday/invites/create/modal'
import { useAppData } from '@components/hooks/use-app-data'

interface Response {
  create: (onCreate: () => void) => (data: EndlessHolidayCreateFormInputs) => Promise<void>
  isLoading: boolean
}

export const useEndlessHolidayCreate = (setError, steps, setStep): Response => {
  const { urls } = useAppData()
  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal({
    title: 'Oferta wysłana',
    content: 'Zaproszenie zostało wysłane do klienta',
    backText: 'Gotowe',
    withDetails: false,
  })

  const [createEndlessHolidayInvitation, { isLoading, error, isError }] = useCreateEndlessHolidayInviteMutation()
  const errorHandler = React.useCallback(
    (key, message) => {
      showStepError(setStep, steps, error)
      handleEmailInvitationErrors(setError)(key, message)
    },
    [error],
  )

  useHandleRtkQueryError(error, isError, errorHandler)

  const create = (onCreate: () => void) => async (data: EndlessHolidayCreateFormInputs) => {
    await createEndlessHolidayInvitation({
      url: urls.product_packages.invite,
      payload: {
        emails: data.client ? [data.client.email] : [data.emails[0].value],
        product_package_set: data.product_package_set?.value,
        seller: data.seller?.value,
      },
    }).unwrap()

    showConfirmationPackageCreation()
    onCreate()
  }

  return {
    create,
    isLoading,
  }
}

import * as React from 'react'
import { Button, Col, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { GastroCard } from '@models/promotions'
import { GastroCardSummary } from '@modules/promotions/gastro-cards/gastro-card-details/gastro-card-summary'
import { useAppDispatch, useAppSelector } from '@store/index'
import { GastroCardDetailsDelete } from '@modules/promotions/gastro-cards/gastro-card-details/gastro-card-details-delete'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { GastroCardWalletItems } from '@modules/promotions/gastro-cards/gastro-card-details/gastro-card-wallet-items'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { useApiRequest } from '@components/hooks/use-api-request'
import { GastroDetailsTabs } from '@modules/promotions/gastro-cards/gastro-card-details/gastro-card-details-dialog/tabs'
import { GastroCardCodes } from '@modules/promotions/gastro-cards/gastro-card-details/card-codes/gastro-card-codes'
import { BaseModalProps } from '@components/modals/types'
import { getGastroCardDetail } from '@store/actions/gastro-card-actions'
import { gastroCardDetailsSelector, setGastroCardDetails } from '@store/slices/gastro-card-slice'
import { useAppData } from '@components/hooks/use-app-data'
import { ProductEmailNotifications } from '@modules/promotions/common/notification/notifications'
import { usePromotionsAppData } from '@modules/promotions/hooks/use-promotions-app-data'
import { createProductCardClient, ProductClientCard } from '@components/products/product-client-card'
import { createInvoiceData, ProductInvoiceCard } from '@components/products/product-invoice-card'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { GastroCardDetailsCancel } from '@modules/promotions/gastro-cards/gastro-card-details/gastro-card-details-cancel'
import { useGastroProductProvider } from '@modules/products/hooks/use-gastro-product-provider'
import { GastroProductKind } from '@models/products'
import { isGastroGenericProduct } from '@modules/promotions/gastro-cards/utils'
import { GastroGenericProductDetailsSummary } from '@modules/promotions/gastro-generic-product/details/gastro-generic-product-details-summary'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { GenericProductPayments } from '@modules/generic-product-payments'
import { UserPermission } from '@models/dashboard'
import { useDetailsModalUnmountParamsClear } from '@components/hooks/use-details-modal'
import { PaymentMethod } from '@models/payments'

interface Props extends BaseModalProps {
  gastroCard: GastroCard
}

export const GastroCardDetailsDialog: React.FC<Props> = ({ toggleIsVisible, gastroCard }) => {
  const gastroCardDetails = useAppSelector(gastroCardDetailsSelector)

  const dataRef = React.useRef<HTMLDivElement>(null)
  const historyRef = React.useRef<HTMLDivElement>(null)
  const codesRef = React.useRef<HTMLDivElement>(null)
  const paymentsRef = React.useRef<HTMLDivElement>(null)
  const transactionsRef = React.useRef<HTMLDivElement>(null)
  const notificationsRef = React.useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()

  useDetailsModalUnmountParamsClear(gastroCard.id)

  const { isLoading, action: fetchGastroCardDetails } = useApiRequest(
    async () => await dispatch(getGastroCardDetail(gastroCard)),
  )

  const gastroProduct = useGastroProductProvider(gastroCard.kind as GastroProductKind)

  const paymentMethods =
    gastroCardDetails?.payment_method === 'cash_card'
      ? [PaymentMethod.cash, PaymentMethod.creditcard]
      : [PaymentMethod.form_bank, PaymentMethod.polcard]

  const { gastro_card_notifications } = usePromotionsAppData()

  const {
    content_types: { gastrocard },
  } = useAppData()

  React.useEffect(() => {
    fetchGastroCardDetails()

    return () => {
      dispatch(setGastroCardDetails(undefined))
    }
  }, [gastroCard])

  const user = useAuthenticatedUser()

  const gastroGenericProductNotifications = isGastroGenericProduct(gastroCard.kind)
    ? gastroProduct.notifications.filter(notification => {
        const notificationKey = notification[0]
        const isSubscriptionOfferNotification = notificationKey.endsWith('subscription_offer')
        return gastroCard.tags?.includes('subscription_offer')
          ? isSubscriptionOfferNotification
          : !isSubscriptionOfferNotification
      })
    : []

  let canAddPayment =
    !gastroCardDetails?.is_started &&
    !gastroCardDetails?.is_removed &&
    gastroCardDetails?.kind !== 'wholesale' &&
    !gastroCardDetails?.read_only

  if (isGastroGenericProduct(gastroCardDetails?.kind)) {
    if (gastroCardDetails?.payment_method === 'online') {
      canAddPayment = false
    }
    if (gastroCardDetails?.payments.length) {
      canAddPayment = false
    }
  }

  const canDeletePayment =
    !gastroCardDetails?.is_started &&
    user.hasPerm(UserPermission.PromotionsGastroCardCanDeletePayment) &&
    !gastroCardDetails?.read_only

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        {gastroProduct ? `Voucher ${gastroProduct.product_name}` : 'Voucher Gastro'} #{gastroCard.number}{' '}
        {gastroCard.code}
      </ModalHeader>
      <ModalBody role="modal-body" className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          {gastroCardDetails && (
            <>
              <GastroDetailsTabs
                dataRef={dataRef}
                gastroCardDetails={gastroCardDetails}
                historyRef={historyRef}
                codesRef={codesRef}
                paymentsRef={paymentsRef}
                transactionsRef={transactionsRef}
                notificationsRef={notificationsRef}
              />
              <div ref={dataRef} />
              <Row>
                <Col md={6}>
                  {isGastroGenericProduct(gastroCard.kind) ? (
                    <GastroGenericProductDetailsSummary gastroCardDetails={gastroCardDetails} />
                  ) : (
                    <GastroCardSummary gastroCardDetails={gastroCardDetails} />
                  )}
                </Col>
                <Col md={6}>
                  {gastroCardDetails.client && (
                    <ProductClientCard
                      clientData={createProductCardClient(gastroCardDetails.client)}
                      canViewClientDetails={!gastroCardDetails.is_removed}
                    />
                  )}

                  {gastroProduct.wallet_kind === 'fiscalization' && (
                    <ProductInvoiceCard invoiceData={createInvoiceData(gastroCardDetails)} />
                  )}
                  <ContentNotesCard
                    readOnly={gastroCardDetails.is_removed || gastroCardDetails.read_only}
                    objectId={gastroCardDetails.id}
                    contentType={gastrocard}
                    onNotesUpdate={fetchGastroCardDetails}
                    notes={gastroCardDetails.notes}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {gastroCardDetails.kind === 'normal' && (
                    <>
                      <div ref={codesRef} />
                      <GastroCardCodes gastroCardDetails={gastroCardDetails} />
                    </>
                  )}
                  <div ref={paymentsRef} />
                  {gastroProduct.wallet_kind === 'fiscalization' && (
                    <GenericProductPayments
                      addUrl={gastroCardDetails.urls.payments}
                      canAddPayment={canAddPayment}
                      payments={gastroCardDetails.payments}
                      onSuccess={fetchGastroCardDetails}
                      canDeletePayment={canDeletePayment}
                      defaultAmount={gastroCardDetails.sell_price}
                      paymentMethods={paymentMethods}
                    />
                  )}
                  <div ref={transactionsRef} />
                  <GastroCardWalletItems wallet={gastroCardDetails.wallet} gastroCardDetails={gastroCardDetails} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <ProductEmailNotifications
                    onSuccess={fetchGastroCardDetails}
                    history={gastroCardDetails.notifications}
                    notificationEmails={
                      isGastroGenericProduct(gastroCardDetails.kind)
                        ? gastroGenericProductNotifications
                        : gastro_card_notifications
                    }
                    readOnly={
                      !user.hasPerm(gastroProduct.crud_permissions.notification) || gastroCardDetails?.read_only
                    }
                    url={gastroCardDetails.urls.notifications}
                    email={gastroCardDetails.client?.email || ''}
                    ref={notificationsRef}
                  />
                </Col>
                <Col md={6}>
                  <HistoryBox history={gastroCardDetails.history} ref={historyRef} />
                </Col>
              </Row>
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter className="d-block">
        <Row>
          <Col md={6}>
            {gastroCardDetails?.kind === 'normal' && (
              <GastroCardDetailsDelete gastroCardDetails={gastroCardDetails} onDelete={toggleIsVisible} />
            )}
            {isGastroGenericProduct(gastroCardDetails?.kind) &&
              gastroCardDetails &&
              ['initial', 'confirmed'].includes(gastroCardDetails.status) && (
                <GastroCardDetailsCancel gastroCardDetails={gastroCardDetails} onCancel={toggleIsVisible} />
              )}
          </Col>
          <Col md={6} className="text-right">
            <Button color="light" onClick={toggleIsVisible}>
              Zamknij
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </>
  )
}

import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { UserPermission } from '@models/dashboard'
import {
  BookingStatsDetails,
  ReservationStatsTableCells,
  StatsCellContent,
  StatsTableOptions,
} from '@modules/reservations/models'
import { ReservationStatsReportCell } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-report-cell'
import { ReservationStatsTableExpandedRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-expanded-row'
import { useAppDispatch } from '@store/index'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { useAppData } from '@components/hooks/use-app-data'

interface Props {
  getCells: (element: string, options?: StatsTableOptions<BookingStatsDetails>) => ReservationStatsTableCells
}

export const ReservationStatsAccommodationParkingVipRow = ({ getCells }: Props): JSX.Element => {
  const { getValues, setError } = useFormContext()
  const user = useAuthenticatedUser()
  const { urls } = useAppData()

  const dispatch = useAppDispatch()

  const { action: handleReportGenerate } = useReportFormRequest(
    async (content: StatsCellContent<BookingStatsDetails>) => {
      const { resort, accommodation_type } = getValues()

      const reportTask = await dispatch(
        createReportTask([
          urls.reports.report_task_booking_parking_vip,
          {
            date: content.statsDetails.date,
            ...(resort?.value && { resort: resort.value }),
            ...(accommodation_type?.value && { accommodation: accommodation_type.value }),
          },
        ]),
      )
      return reportTask
    },
    setError,
    'notification',
  )

  const getReportDataCells = field => {
    if (!user.hasPerm(UserPermission.ReportsBookingParkingVip)) return getCells(field)

    return getCells(field, {
      tableCellOption: {
        wrapper: content => (
          <ReservationStatsReportCell
            content={content}
            onClick={() => handleReportGenerate(content)}
            color="text-default"
          />
        ),
      },
    })
  }

  return (
    <ReservationStatsTableExpandedRow
      title="Parking gwarantowany - miejsca sprzedane"
      cells={getReportDataCells('bought_parking_vip_spots')}
    />
  )
}

import { BenefitProgram, BenefitProgramStatus } from '@modules/benefit-program/models'
import { asDecimal } from '@helpers/utils'

export const getBenefitProgramStatusColor = (status: BenefitProgramStatus) => {
  switch (status) {
    case 'active':
      return 'success'
    case 'inactive':
      return 'danger'
    default:
      return 'danger'
  }
}
export const getBenefitProgramStatusIcon = (status: BenefitProgramStatus) => {
  switch (status) {
    case 'active':
      return 'uil-check-circle'
    case 'inactive':
      return 'uil-ban'
  }
}

export const getBenefitProgramTotal = (benefitProgram: BenefitProgram) => {
  const apartment = asDecimal(benefitProgram.benefit_apartment_price_employer).plus(
    asDecimal(benefitProgram.benefit_apartment_price_worker),
  )

  const house = asDecimal(benefitProgram.benefit_house_price_employer)
    .plus(asDecimal(benefitProgram.benefit_house_price_worker))
    .mul(benefitProgram.active_house_agreements)

  return apartment.plus(house).toString()
}

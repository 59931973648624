import { DashboardActionTypes, DashboardActionTypesKeys } from '@store/actions-types/dashboard-actions-types'
import { DashboardAppData, DashboardStandardPaginator } from '@models/dashboard'

export interface DashboardState {
  standardPaginator: DashboardStandardPaginator | undefined
}

export const emptyAppData: DashboardAppData = {
  user_languages: [],
  units: [],
  unit_kinds: [],
  deposit_payment_deadline: [],
  has_apartment_meter_non_apartments: 0,
  payment_sources: [],
  call_center_groups: [],
  tags: [],
  band_readers: [],
  receipt_unfinished: null,
  reception_workstations: [],
  history_fields: [],
  eservice_pin_pads: [],
  tablets: [],
  countries: [],
  settings: {
    subscription_client_data_change_price: '',
    subscription_disposable_localization_change_price: '',
  },
  content_types: {
    productpackage: 0,
    benefitprogramagreement: 0,
    benefitprogram: 0,
    genericvoucher: 0,
    hrworkerprofile: 0,
    cashbackvoucher: 0,
    technicalorder: 0,
    agreement: 0,
    cafeteria: 0,
    promocodetouristvoucher: 0,
    upsellbooking: 0,
    receipt: 0,
    booking: 0,
    gastrocard: 0,
    package: 0,
    packagevip: 0,
    packagewholesale: 0,
    subscription: 0,
    transferpackage: 0,
    user: 0,
    subscriptioncontract: 0,
    clientoptionpurchase: 0,
  },
  subscription: {
    sources: [],
    statuses: [],
    sellers: [],
  },
  accommodation_types: [],
  resorts: [],
  status: 'unknown',
  menu: [],
  dates: {
    months: [],
    seasons: [],
  },
  booking_sources: [],
  people_kinds: [],
  urls: {
    benefit_programs: {
      benefit_program_agreements: '',
      app_data: '',
      benefit_programs: '',
    },
    product_packages: {
      invite: '',
      product_package_invite_list: '',
      product_package_list: '',
    },
    generic_vouchers: {
      generic_vouchers: '',
    },
    rules: {
      rodo_agreements: '',
    },
    coupons: { list: '', summary: '', types: '', types_configurations: '' },
    ecommerce: { stats: '' },
    upselling: {
      app_data: '',
      upselling_bookings: '',
      upselling_bookings_outdated: '',
    },
    cashback: { cashback_vouchers: '', app_data: '' },
    technical_orders: {
      app_data: '/technical-orders-fake-app-data/',
      guests_technical_orders: '/dashboard-api/technical-orders/guests_technical-orders/',
      technical_orders: '/dashboard-api/technical-orders/technical-orders/',
      reception_technical_orders: '/dashboard-api/technical-orders/reception-technical-orders/',
    },
    cafeteria: { app_data: '', cafeterias: '' },
    hr: {
      agreements: '',
      app_data: '',
      hr_workers: '',
      hr_worker_profile_extended_list: '',
    },
    rental: {
      app_data: '',
      products: '',
      products_import: '',
      product_categories: '',
      warehouses: '',
    },
    barriers: {
      tickets: '',
      tickets_special: '',
    },

    eservice: {
      pin_pad_actions: '',
    },
    client_options: {
      client_options_available_options: '',
      client_options_purchases: '',
    },
    products: {
      app_data: '',
    },
    package_wholesale: {
      app_data: '',
      package_wholesales: '',
      products: '',
    },
    payment: {
      count_money_report: '/dashboard-api/payment/count-money-report/',
      safebox_stats: '',
      payment_day_report: '',
      payment_registers: '',
      payment_app_data: '/fake-payment-app-data/',
    },
    reservation: {
      reservation_calculate_price: '',
      available_apartments: '',
      calendar: '',
      reservation_check_accommodation_type: '',
      reservation_check_dates: '',
      reservation_create_unfinished_booking: '',
    },
    package: {
      package_app_data: '',
      calculate: '',
      packages: '',
    },
    cms: {
      asset_categories: '',
      assets: '',
      improvements_position: '',
      accommodation_types: '',
      app_data: '',
      improvements: '',
      resorts: '',
      resorts_attractions: '',
      resorts_faqs: '',
      resorts_position: '',
      text_blocks: '',
    },
    notifications: {
      sms_templates: '',
      email_templates: '',
    },
    accounting: {
      booking_refunds: '',
      bookings_client_data_notification: '',
      transfer_package_create: '',
      transfer_packages: '',
    },
    feeding: {
      apartments: '',
      app_data: '',
      clients: '',
      clients_calculate: '',
      feeding_price_list: '',
      enter: '',
      print: '',
      search: '',
      special_guests: '',
      special_guests_list: '',
      stats: '',
      worker_guests: '',
      workers_list: '',
    },
    erli: {
      promocode_list: '',
    },
    wallet: {
      account: '',
      virtual_pos: '',
    },
    housekeeping: {
      improvement_orders_provider: '/housekeeping/improvement_orders_provider/',
      cleaning_orders: '/housekeeping/cleaning-orders/',
      units: '/housekeeping/units/',
      app_data: '/housekeeping-fake-app-data/',
      common_tasks_list: '',
      application_image: '',
      applications_aggregation: '',
      bedding_orders_list: '',
      cleaning_order_create: '',
      cleaning_orders_aggregation: '',
      cleaning_orders_list: '',
      fault_orders: '',
      improvement_orders: '',
      issue_order_bookings: '',
      issue_orders: '',
    },
    shop: {
      receipts: '',
      receipt_create: '',
      app_data: '',
      booking_seller_day_visits: '',
      products: '',
      documents: '',
      sell_product_calculation: '',
    },
    bands: {
      band_details: '',
      service_bands: '',
      reader_request: '',
      reader_encode: '',
      encode_band: '',
    },
    reports: {
      report_task_booking_collision_stats_report: '',
      housekeeping_users_report: '',
      report_task_generic_voucher_code_report: '',
      report_task_courtyard_occupancy_report: '',
      report_task_technical_order_report: '',
      media_report_items: '',
      payment_report: '',
      report_package_wholesale_code_report: '',
      report_promocode_group_codes: '',
      report_task_apartment_meter_report: '',
      report_task_booking_check: '',
      report_task_booking_improvement_stats_global_report: '',
      report_task_booking_improvement_stats_report: '',
      report_task_booking_online_check_in_stats_report: '',
      report_task_booking_online_check_out_stats_report: '',
      report_task_booking_parking_vip: '',
      report_task_booking_payment: '',
      report_task_booking_reservation_discount: '',
      report_task_booking_stats_report: '',
      report_task_cafeteria_code: '',
      report_task_cash_register: '',
      report_task_cleaning_order_report: '',
      report_task_clients: '',
      report_task_climatic_tax_report: '',
      report_task_common_meters_report: '',
      report_task_contract_report: '',
      report_task_entrance_ticket_suntago_report: '',
      report_task_entrance_ticket_zoo_report: '',
      report_task_erli_code: '',
      report_task_fault_order: '',
      report_task_feeding_alternative: '',
      report_task_feeding_client: '',
      report_task_feeding_sale: '',
      report_task_feeding_special_guest: '',
      report_task_feeding_workers: '',
      report_task_floor_heating_report: '',
      report_task_gastro_card: '',
      report_task_gastro_card_boost_report: '',
      report_task_gastro_card_vat_report: '',
      report_task_gastro_coupon_report: '',
      report_task_housekeeping_user_report: '',
      report_task_housekeeping_users_report: '',
      report_task_issue_order: '',
      report_task_load_note: '',
      report_task_marketing_rodo_cancel_report: '',
      report_task_marketing_rodo_report: '',
      report_task_package: '',
      report_task_package_code: '',
      report_task_package_full_code: '',
      report_task_package_hpi: '',
      report_task_package_hpi_code: '',
      report_task_package_hpi_full_code: '',
      report_task_package_vip: '',
      report_task_package_wholesale: '',
      report_task_payment_move: '',
      report_task_payment_register: '',
      report_task_permission: '',
      report_task_promocode_tourist_voucher_report: '',
      report_task_receipt: '',
      report_task_receipt_product: '',
      report_task_reservation_bill_item_report: '',
      report_task_reservation_bill_report: '',
      report_task_reservation_late_payment_report: '',
      report_task_reservation_stats: '',
      report_task_reservations: '',
      report_task_resort_bands_report: '',
      report_task_shop_product: '',
      report_task_sms_report: '',
      report_task_stats_sale_items: '',
      report_task_subscription: '',
      report_task_subscription_code: '',
      report_task_subscription_contract_report: '',
      report_task_subscription_conversion_report: '',
      report_task_subscription_payment: '',
      report_task_cashback_voucher_report: '',
      reports_app_data: '',
      stats_sale_item: '',
      stats_sale_item_dashboard: '',
      stats_sale_item_dashboard_report: '',
      stats_sale_item_user: '',
      user_reports: '',
    },
    sale: {
      app_data: '',
      nip_search: '',
      cash_box_print: '',
      cash_box_status: '',
      cash_registers: '',
    },
    promotions: {
      client_wallet_code_details: '',
      promocode_tourist_vouchers: '',
      app_data: '',
      client_wallet_assigment: '',
      gastro_cards: '',
      gastro_coupon_validate_booking: '',
      gastro_generic: '',
      gastro_cards_code_add: '',
      package_vip: '',
      package_vip_booking_improvement: '',
      booking_option: '',
    },
    subscription_contract: {
      subscription_contracts: '',
    },
    crm: {
      sellers: '',
      app_data: '/crm/app-data/',
      recommendation_gifts: '',
    },
    rcp: {
      users: '',
      export_xls: '',
      export_pdf: '',
    },
    reception: {
      app_data: '',
      booking_details: '',
      booking_export: '',
      bookings: '',
      bookings_aggregation: '',
      mass_sms_notification: '',
      reset_kiosk: '',
    },
    rent: {
      apartment_block: '',
      apartment_list: '',
      available_apartments: '',
      booking_autocomplete: '',
      booking_export: '',
      booking_improvement_stats: '',
      booking_list: '',
      booking_stats: '',
      booking_stats_app_data: '',
      booking_stats_board: '',
      calendar_bookings: '',
      promocode_create: '',
      promocode_email_templates: '',
      promocode_groups: '',
      promocode_list: '',
      promocode_prefixes: '',
    },
    clients: {
      clients: '',
    },
    account: {
      receptionist_users: '',
      call_center_users: '',
      housekeeping_users: '',
      details: '',
      logout: '',
      password: '',
      groups: '',
      profile: '',
      users: '',
    },
    dashboard: {
      global_search: '',
      hijack_release: '',
      quick_search_url: '',
      content_note_create: '',
      system_notification_mass_api: '',
    },
    subscription: {
      subscription_app_data: '',
      subscription_products: '',
      subscriptions: '',
    },
    meter: {
      common_meters_locations: '',
      common_meters_devices: '',
      floor_heating_scenarios_list: '',
      floor_heating_windows_list: '',
      floor_heating_measurements_list: '',
      floor_heating_app_data: '',
      floor_heating_measurements: '',
    },
    datasets: {
      address: '',
    },
  },
  check_stats: [
    {
      day_in: 0,
      day_out: 0,
      day_in_realized: 0,
      day_out_realized: 0,
      day_in_remained: 0,
      day_out_in: 0,
      day_out_remained: 0,
      resort_id: 1,
    },
  ],
  configuration: {
    vzk_enabled: true,
    reservation_deposit_available_values: [],
    reservation_deposit_default_amount: 750,
    reservation_booking_damage_keys_price_brutto: '',
    reservation_booking_damage_bands_price_brutto: '',
    cashback_vouchers: true,
  },
  voucher_scopes: [],
}

const initialState: DashboardState = {
  standardPaginator: undefined,
}

const dashboardState = (state: DashboardState = initialState, action: DashboardActionTypes): DashboardState => {
  switch (action.type) {
    case DashboardActionTypesKeys.SET_STANDARD_PAGINATOR:
      if (!action.payload.skip_paginator) {
        return { ...state, standardPaginator: action.payload }
      }
      return { ...state }

    default:
      return state
  }
}

export default dashboardState

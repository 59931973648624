import { useAppDispatch } from '@store/index'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { TypedQueryResult } from '@api/base'

type Response<T> = TypedQueryResult<T> & { data: T[] }

export const useQueryWithPaginationResponse = <T = unknown>(queryResponse: TypedQueryResult<any>): Response<T> => {
  const dispatch = useAppDispatch()

  const { data, ...queryMethods } = queryResponse

  if (data && typeof data.count !== 'undefined' && typeof data.results !== 'undefined') {
    dispatch(setDashboardStandardPaginator(data))
  }

  return { data: data?.results ?? [], ...queryMethods } as Response<T>
}

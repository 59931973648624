import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { CommonTableFilters } from '@components/table/common-table-filters'

interface Props {
  filters: BaseTableFilters
  defaultFilters: BaseTableFilters
  setFilters: (filters: BaseTableFilters) => void
}

export const EndlessHolidayTransactionsFilters = ({ setFilters, filters, defaultFilters }: Props) => {
  const methods = useForm<BaseTableFilters>({ defaultValues: filters })

  return (
    <FormProvider {...methods}>
      <CommonTableFilters
        filters={filters}
        defaultFilters={defaultFilters}
        setFilters={setFilters}
        searchParams={{ hideLabel: true, formGroupClassName: 'mb-0' }}
        searchWrapperParams={{ className: 'col-auto' }}
        clearParams={{ className: 'mt-0 ml-auto' }}
        rowClassName="justify-content-end align-items-center mb-2"
      />
    </FormProvider>
  )
}

import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import * as React from 'react'
import { CustomReactSelectOption } from '@components/custom-react-select'

export const useGroupedSources = (): CustomReactSelectOption[] => {
  const { sources } = useReportsAppData()

  const sourceList = (sources || [])
    .filter(row => row.name)
    .map(source => ({
      label: source.name,
      value: source.id,
    }))

  return React.useMemo(() => [{ label: 'Wszystkie', value: null }, ...sourceList], [sourceList])
}

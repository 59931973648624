import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { EmailNotification, NotificationKind } from '@models/notifications'
import { ContentLoader } from '@components/content-loader'
import { CustomHTML } from '@components/custom-html'
import { SaveButton } from '@hyper/button'
import { useNotificationsTemplate } from '@components/notification-box/use-notifications-template'

interface Props extends BaseModalProps {
  previewUrl: string
  notification?: EmailNotification
  notificationId: number
  notificationKind: NotificationKind
  onSend?: () => void
}

export const NotificationPreviewModal: React.FC<Props> = ({
  previewUrl,
  notificationId,
  toggleIsVisible,
  notificationKind,
  onSend,
  notification,
}) => {
  const { preview, fetchPreview, isFetching, isSending, send } = useNotificationsTemplate(
    previewUrl,
    notificationId,
    notificationKind,
  )

  const handleSend = async () => {
    await send(() => {
      onSend?.()
      toggleIsVisible()
    })
  }

  React.useEffect(() => {
    if (notification) return

    fetchPreview()
  }, [])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Podgląd treści powiadomienia</ModalHeader>
      <ModalBody className="overflow-hidden">
        <ContentLoader isLoading={isFetching}>
          {notification ? (
            <>
              <NotificationDataRows
                content={notification.subject}
                title="Tytuł wiadomości"
                rows={[
                  { title: 'Wysłane do', content: notification.send_to },
                  ...(notification.suspended
                    ? [{ title: 'Doręczenie', content: 'Notyfikacje były wyłączone, wiadomość nie została wysłana.' }]
                    : []),
                ]}
              />
              <iframe src={previewUrl} width="100%" height={600} className="overflow-hidden border-0 mt-3" />
            </>
          ) : (
            preview && (
              <>
                {preview.subject && <NotificationDataRows content={preview.subject} title="Tytuł wiadomości" />}
                <CustomHTML content={preview.content} />
              </>
            )
          )}
        </ContentLoader>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        {!!onSend && (
          <SaveButton
            className="btn btn-green"
            isSaving={isSending}
            onClick={handleSend}
            labelSaving="Proszę czekać..."
            label="Wyślij"
          />
        )}
      </ModalFooter>
    </>
  )
}

const NotificationDataRows = ({
  title,
  content,
  rows = [],
}: {
  title: string
  content: string
  rows?: { title: string; content: string }[]
}) => (
  <>
    <div className="border-bottom mt-n2 mx-n3 px-3 pb-2 pt-1">
      {title}:<span className="fw-semi-bold"> {content}</span>
    </div>
    {rows.map(row => (
      <div key={row.title} className="border-bottom mx-n3 px-3 py-2">
        {row.title}:<span className="fw-semi-bold"> {row.content}</span>
      </div>
    ))}
  </>
)

import * as React from 'react'
import { BaseHistory } from '@models/dashboard'
import { dateWithFullTextMonth, formatDate, toDefaultTimeFormat } from '@helpers/date-helper'
import { ExpandArrow, useExpandableItems } from '@components/hooks/use-expandable-items'
import classNames from 'classnames'
import { ClientHistoryChanges } from '@components/client-history/changes/client-history-changes'

const DefaultIcon = 'uil-circle'

export interface HistoryIcon {
  [key: string]: string
}

interface Props {
  history: Omit<BaseHistory, 'keyword'> & { keyword?: string }
  icons?: HistoryIcon
}

export const ClientHistoryRow: React.FC<Props> = ({ history, icons }) => {
  const { toggleExpanded, isExpanded } = useExpandableItems()

  const icon = history.keyword ? icons?.[history.keyword] || DefaultIcon : DefaultIcon

  const isExpandable = !!history.params?.changes?.length || !!history.message

  return (
    <div className="reservation-details__history__row">
      <div className="d-flex">
        <i className={classNames('pl-0 font-15', icon)} />
        <div className="pl-0 ml-1 w-100">
          <span className="fw-semi-bold">{history.keyword_display}</span>
          {history.link && (
            <a href={history.link} target="_blank" className="text-default ml-1">
              <i className="uil-external-link-alt" />
            </a>
          )}
          {isExpandable && (
            <ExpandArrow isExpanded={isExpanded('details')} onExpand={toggleExpanded('details')} fontSize="font-15" />
          )}
          <div className="reservation-details__history__row__content">
            <div className="font-11">
              {formatDate(history.created_at, dateWithFullTextMonth)}, {toDefaultTimeFormat(history.created_at)}
              {history.author ? `, ${history.author}` : ''}
            </div>
            {isExpandable && <ClientHistoryChanges isExpanded={isExpanded('details')} history={history} />}
          </div>
        </div>
      </div>
    </div>
  )
}

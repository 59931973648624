import * as React from 'react'
import { ReceptionBookingClientWallet } from '@models/reception'
import { ReservationDropdown } from '@modules/reservations/details/common/reservation-dropdown'
import { ReservationVoucherRemove } from '@modules/reservations/details/vouchers/reservation-voucher-remove'

import { ReservationVoucherActivationToggle } from '@modules/reservations/details/vouchers/reservation-voucher-activation-toggle'

interface GuestReservationDataActionsProps {
  wallet: ReceptionBookingClientWallet
}

export const ReservationVoucherDropdown: React.FC<GuestReservationDataActionsProps> = ({ wallet }) => (
  <ReservationDropdown wrapperClassName="ml-auto" toggleClassName="btn-flat px-2">
    <ReservationVoucherActivationToggle wallet={wallet}>
      <ReservationDropdown.Item>{wallet.is_active ? 'Dezaktywuj voucher' : 'Aktywuj voucher'}</ReservationDropdown.Item>
    </ReservationVoucherActivationToggle>
    <ReservationVoucherRemove wallet={wallet}>
      <ReservationDropdown.Item>Odepnij voucher</ReservationDropdown.Item>
    </ReservationVoucherRemove>
  </ReservationDropdown>
)

import * as React from 'react'
import Table from '@components/table/table'
import { EndlessHolidayInvitation } from '@modules/endless-holiday/models'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface Props {
  invitation: EndlessHolidayInvitation
  lp: number
}

export const EndlessHolidayInvitationsTableRow = ({ invitation, lp }: Props): React.ReactNode => (
  <Table.Row>
    <Table.Cell width={50}>{lp}.</Table.Cell>
    <Table.Cell>{invitation.email}</Table.Cell>
    <Table.Cell>
      <StatusBadge element={invitation} />
    </Table.Cell>
    <Table.Cell>{toDefaultDateTimeFormat(invitation.created)}</Table.Cell>
    <Table.Cell>{toDefaultDateTimeFormat(invitation.auto_cancel_after_date)}</Table.Cell>
    <Table.Cell style={{ width: 40 }}>
      <a target="_blank" href={invitation.urls.sale} className="text-default">
        <i className="uil-globe font-14 lh-1" />
      </a>
    </Table.Cell>
  </Table.Row>
)

import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { BookingStatsParams } from '@modules/reservations/models'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ContentLoader } from '@components/content-loader'
import {
  CommonStatsBoxDetailsFilters,
  StatsBoxDetailsFiltersFormInput,
} from '@modules/reservations/statistics/common/common-stats-box-details-filters'
import { useResortsAndAccommodationTypesOptions } from '@components/hooks/use-resorts-and-accommodation-types-options'
import { UserPermission } from '@models/dashboard'

interface FormInputs {
  resort: CustomReactSelectOption | null
  accommodation_type: CustomReactSelectOption | null
  date_after: Date
  date_before: Date
}

interface InitialDates {
  date_after: Date
  date_before: Date
}

interface ReservationStatsBoxDetailsProps {
  children: React.ReactNode
  onFilter: (params: Partial<BookingStatsParams>) => void
  isLoading: boolean
  resortViewPermission: UserPermission
  initialDates: InitialDates
}

export const CommonStatsBoxDetails = ({
  children,
  onFilter,
  isLoading,
  resortViewPermission,
  initialDates,
}: ReservationStatsBoxDetailsProps) => {
  const defaultValues: StatsBoxDetailsFiltersFormInput = {
    resort: null,
    accommodation_type: null,
    ...initialDates,
  }

  const methods = useForm<FormInputs>({ defaultValues })
  const [selectedResort, selectedAccommodationType] = useWatch({
    control: methods.control,
    name: ['resort', 'accommodation_type'],
  })

  const { getAccommodationTypesOptions, getResortOptions } = useResortsAndAccommodationTypesOptions()
  const accommodationTypeOptions = getAccommodationTypesOptions(selectedResort?.value)
  const resortOptions = getResortOptions(resortViewPermission, selectedAccommodationType?.value)

  const handleFilter = () => {
    const { date_after, date_before, resort, accommodation_type } = methods.getValues()
    const params = {
      date_after,
      date_before,
      ...(resort?.value && { resort: resort.value }),
      ...(accommodation_type?.value && { accommodation_type: accommodation_type.value }),
    }

    onFilter(params)
  }

  return (
    <FormProvider {...methods}>
      <CommonStatsBoxDetailsFilters
        resortOptions={resortOptions}
        accommodationTypeOptions={accommodationTypeOptions}
        onFilter={handleFilter}
        defaultValues={defaultValues}
      />
      <ContentLoader isLoading={isLoading}>{children}</ContentLoader>
    </FormProvider>
  )
}

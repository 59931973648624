import * as React from 'react'
import DateRangeComponent from '@components/date/date-range'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import classNames from 'classnames'
import { parseISODate } from '@helpers/date-helper'
import { DateRangeNavigatorYears } from '@components/date/data-range-navigator-renderer'
import { useFormContext, useWatch } from 'react-hook-form'

interface DateRangeInputProps {
  startDateName: string
  endDateName: string
  minDate?: Date | null
  maxDate?: Date | null
  placeholder?: string
  placeholderClassName?: string
  placeholderComponent?: React.ReactElement | null
  isClearable?: boolean
  inputGroupClassName?: string
  inputClassName?: string
  wrapperClassName?: string
  iconWrapperClassName?: string
  dateFormat?: string
  availableDates?: string[]
  onRangeFocusChange?: (rangeFocus) => void
  navigatorYearsRenderer?: DateRangeNavigatorYears
  isInvalid?: boolean
}

const DateRangeInput: React.FC<DateRangeInputProps> = ({
  isInvalid,
  placeholder,
  placeholderClassName,
  placeholderComponent,
  isClearable,
  inputGroupClassName,
  inputClassName,
  wrapperClassName,
  iconWrapperClassName,
  minDate,
  maxDate,
  startDateName,
  endDateName,
  dateFormat,
  availableDates,
  onRangeFocusChange,
  navigatorYearsRenderer,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  const startDate = parseISODate(useWatch({ control, name: startDateName }))
  const endDate = parseISODate(useWatch({ control, name: endDateName }))

  const setDate = ({ selection }) => {
    setValue(startDateName, selection.startDate)
    setValue(endDateName, selection.endDate)
  }

  isInvalid = isInvalid || !!(errors[startDateName] || errors[endDateName])

  return (
    <InputGroup className={`${inputGroupClassName} mb-2`}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText
          className={classNames('bg-transparent border-right-0', iconWrapperClassName, { 'is-invalid': isInvalid })}
        >
          <i className="uil-calendar-alt text-muted " />{' '}
        </InputGroupText>
      </InputGroupAddon>
      <DateRangeComponent
        isClearable={isClearable}
        startingDate={startDate}
        endingDate={endDate}
        onChange={setDate}
        placeholder={placeholder || 'Wybierz datę'}
        placeholderClassName={placeholderClassName}
        placeholderComponent={placeholderComponent}
        isInvalid={isInvalid}
        wrapperClassName={wrapperClassName}
        inputClassName={`border-radius-left-0 border-left-0 ${inputClassName || ''}`}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
        availableDates={availableDates}
        onRangeFocusChange={onRangeFocusChange}
        navigatorYearsRenderer={navigatorYearsRenderer}
      />
    </InputGroup>
  )
}

export default DateRangeInput

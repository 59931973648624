import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { useTabs } from '@components/tabs'
import { EndlessHolidayTransactions } from '@modules/endless-holiday/transactions'
import { EndlessHolidayInvitations } from '@modules/endless-holiday/invites'
import { EndlessHolidayAddButton } from '@modules/endless-holiday/add-button'

export type EndlessHolidayTab = 'invitations' | 'transactions'

const AvailableTabs = [
  { id: 'transactions' as const, name: 'Transakcje' },
  { id: 'invitations' as const, name: 'Zaproszenia' },
]

export const EndlessHolidayView: React.FC = () => {
  const { Tabs, isTabActive, activeTab } = useTabs<EndlessHolidayTab>('transactions')

  const activeTabTitle = isTabActive('invitations') ? 'Zaproszenia' : 'Transakcje'
  const pageTitle = `Wakacje w nieskończoność — ${activeTabTitle}`

  useDocumentTitle(pageTitle)

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: 'Wakacje w nieskończoność', path: '', active: true },
          { label: activeTabTitle, path: '', active: true },
        ]}
        title={pageTitle}
      />
      <Card>
        <CardBody>
          <div className="d-flex position-relative">
            <Tabs tabs={AvailableTabs} className="w-100" />
            {activeTab === 'invitations' && <EndlessHolidayAddButton />}
          </div>

          {isTabActive('transactions') && <EndlessHolidayTransactions />}
          {isTabActive('invitations') && <EndlessHolidayInvitations />}
        </CardBody>
      </Card>
    </>
  )
}

import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { GastroCardDetails } from '@models/promotions'
import { InvoiceForm } from '@components/invoice-form'

import { GastroProductInfo, GastroProductKind, ProductsAppDataGastroProduct } from '@models/products'
import { ClientSelect } from '@components/client-select'
import { useModal } from '@components/modals/use-modal'
import { ClientUser } from '@models/clients'
import { useFormContext, useWatch } from 'react-hook-form'
import { GastroGenericProductDialogBookingSelect } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-product-dialog-booking-select'
import { GastroGenericProductDialogForm } from '@modules/promotions/gastro-generic-product/dialog/form'
import { GastroGenericProductDialogFormInputs } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-product-dialog'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import { useGastroProductProvider } from '@modules/products/hooks/use-gastro-product-provider'
import { FormPlain } from '@hyper/forms'
import { GuestWalletScope } from '@models/booking'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { GastroGenericProductData } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-product-data'

export const gastroGenericScopes: { scope: GuestWalletScope; scope_display: string }[] = [
  { scope: 'shop', scope_display: 'Sklep' },
  { scope: 'bistro', scope_display: 'Bistro' },
  { scope: 'cafe', scope_display: 'Kawiarnia' },
  { scope: 'sweet_corner', scope_display: 'Słodki Kącik' },
  { scope: 'feeding', scope_display: 'Restauracja' },
  { scope: 'beach_bar', scope_display: 'Beach Bar' },
  { scope: 'itinerant_trade', scope_display: 'Wózek' },
  { scope: 'vending_machines', scope_display: 'Automaty (Vendingowe)' },
  { scope: 'vending_machines_gastronomy', scope_display: 'Automaty (gastronomiczne)' },
  { scope: 'vending_machines_entertainment', scope_display: 'Automaty (rozrywkowe)' },
  { scope: 'improvements', scope_display: 'Ulepszenia' },
]

interface Props {
  gastroCoupon: GastroCardDetails | undefined
  onSubmitDisabledChange: (disabled: boolean) => void
  gastroProduct: ProductsAppDataGastroProduct
  gastroProductInfo: GastroProductInfo | null
  kind: GastroProductKind
}

export const GastroGenericProductDialogDataStep = ({
  kind,
  gastroProduct,
  gastroCoupon,
  onSubmitDisabledChange,
  gastroProductInfo,
}: Props): JSX.Element => {
  const { formState, setValue, control } = useFormContext<GastroGenericProductDialogFormInputs>()

  const client = useWatch({ control, name: 'client' })
  const { gastro_card_reason_for_creation } = useProductsAppData()
  const { wallet_kind, can_define_scopes } = useGastroProductProvider(kind)

  const setClient = (client: ClientUser) => {
    setValue('client', client)
  }

  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient })

  const isGastroCardCompensation = kind === 'gastro_card_compensation'
  const gastroCardReasonCreationOptions = gastro_card_reason_for_creation.map(reason =>
    createSelectOption(reason.name, reason.id),
  )

  return (
    <Row>
      <Col md={7} className="pl-0 mb-2">
        {gastroProduct.assign_type === 'booking' && (
          <GastroGenericProductDialogBookingSelect
            onSubmitDisabledChange={onSubmitDisabledChange}
            isDisabled={!!gastroCoupon}
          >
            {gastroProductInfo?.tags.length && (
              <GastroGenericProductData className="w-100" gastroProductInfo={gastroProductInfo} />
            )}
          </GastroGenericProductDialogBookingSelect>
        )}

        {gastroProduct.assign_type === 'client' && (
          <>
            <ClientSelect
              formGroupClassName="mb-0"
              isDisabled={!!gastroCoupon}
              setClient={setClient}
              client={client ?? undefined}
              handleToggleClientDialog={handleToggleClientDialog}
              error={formState.errors.client}
              colSize={12}
            />

            {!!gastroProductInfo?.tags.length && (
              <GastroGenericProductData withSubscriptionContract={false} gastroProductInfo={gastroProductInfo} />
            )}
          </>
        )}
      </Col>

      {!gastroCoupon && wallet_kind === 'fiscalization' && (
        <Col md={12} className="mb-2">
          <InvoiceForm />
        </Col>
      )}

      <GastroGenericProductDialogForm kind={kind} gastroCoupon={gastroCoupon} gastroProduct={gastroProduct} />
      {can_define_scopes && (
        <Col md={12} className="px-0 my-2">
          <FormPlain name="scopes" label="Dostęp do zakupów">
            <Row className="mx-0">
              {gastroGenericScopes.map((row, index) => (
                <FormCheckbox
                  key={row.scope}
                  name={`scopes[${index}]`}
                  value={row.scope}
                  label={row.scope_display}
                  className="col-4"
                />
              ))}
            </Row>
          </FormPlain>
        </Col>
      )}
      {isGastroCardCompensation && (
        <FormSelect
          label="Powód utworzenia vouchera"
          formPlainProps={{ colSize: 5 }}
          selectProps={{ isClearable: true }}
          options={gastroCardReasonCreationOptions}
          name="reason_for_creation"
        />
      )}
    </Row>
  )
}

import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import {
  BookingStatsDetails,
  ReservationStatsTableCells,
  StatsCellContent,
  StatsCellSummary,
  StatsTableOptions,
} from '@modules/reservations/models'
import { ReservationStatsTableExpandedRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-expanded-row'
import { useAppDispatch } from '@store/index'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { useAppData } from '@components/hooks/use-app-data'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { ReservationStatsReportCell } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-report-cell'

interface ReportData {
  title: string
  url: string
}

interface Props {
  field: string
  getCells: (element: string, options?: StatsTableOptions<BookingStatsDetails>) => ReservationStatsTableCells
}

export const ReservationStatsOnlineCheckRow = ({ field, getCells }: Props): JSX.Element => {
  const { getValues, setError } = useFormContext()
  const { urls } = useAppData()

  const dispatch = useAppDispatch()

  const reportData = {
    arrivals_checked_in_online: {
      title: 'Zameldowania online',
      url: urls.reports.report_task_booking_online_check_in_stats_report,
    },
    departures_checked_out_online: {
      title: 'Wymeldowania online',
      url: urls.reports.report_task_booking_online_check_out_stats_report,
    },
  }[field] as ReportData

  const { action: handleReportGenerate } = useReportFormRequest(
    async (content: StatsCellContent<BookingStatsDetails>) => {
      const { resort, accommodation_type } = getValues()

      const reportTask = await dispatch(
        createReportTask([
          reportData.url,
          {
            date: content.statsDetails.date,
            ...(resort?.value && { resort: resort.value }),
            ...(accommodation_type?.value && { accommodation: accommodation_type.value }),
          },
        ]),
      )
      return reportTask
    },
    setError,
    'notification',
  )

  const reportDataCells = getCells(field, {
    tableCellOption: {
      wrapper: (content: StatsCellContent<BookingStatsDetails>) => (
        <CellWrapper content={content} field="statsDetails">
          <ReservationStatsReportCell
            content={content}
            onClick={() => handleReportGenerate(content)}
            color="text-default"
          />
        </CellWrapper>
      ),
    },
    tableRowSummaryOption: {
      wrapper: (content: StatsCellSummary<BookingStatsDetails>) => (
        <CellWrapper field="summaryDetails" content={content}>
          {content.summaryDetails[content.element]}
        </CellWrapper>
      ),
    },
  })

  return (
    <ReservationStatsTableExpandedRow title={<TitleWithTooltip title={reportData.title} />} cells={reportDataCells} />
  )
}

const CellWrapper = ({
  children,
  content,
  field,
}: {
  children: React.ReactNode
  content: StatsCellContent<BookingStatsDetails> | StatsCellSummary<BookingStatsDetails>
  field: 'statsDetails' | 'summaryDetails'
}) => (
  <>
    {children}
    <small className="d-block">({content[field][`${content.element}_percentage`]}%)</small>
  </>
)

const TitleWithTooltip = React.memo(({ title }: { title: string }) => (
  <IconWithTooltip
    color="text-secondary"
    icon="uil-exclamation-circle ml-1"
    tooltipId={title.replace(' ', '-')}
    className="flex-row-reverse"
    tooltipPlacement="right"
    tooltipMessage={<span className="font-11 text-left">Ilość i stosunek procentowy</span>}
  >
    {title}
  </IconWithTooltip>
))

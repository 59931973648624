import * as React from 'react'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'
import { useGetEndlessHolidayTransactionsQuery } from '@api/endless-holiday'
import { EndlessHolidayProductsFilters } from '@modules/endless-holiday/configuration/products/filters'
import { EndlessHolidayProductsTable } from '@modules/endless-holiday/configuration/products/table'

const defaultFilters: BaseTableFilters = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
}

export const EndlessHolidayProducts: React.FC = () => {
  const [filters, setFilters] = React.useState<BaseTableFilters>(defaultFilters)

  const { data: transactions, isLoading } = useQueryWithPaginationResponse<any>(
    useGetEndlessHolidayTransactionsQuery(filters),
  )

  return (
    <>
      <EndlessHolidayProductsFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
      <EndlessHolidayProductsTable
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
        transactions={transactions}
      />
    </>
  )
}

import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { EmailNotification } from '@models/notifications'
import { ProductEmailNotificationsForm } from '@modules/promotions/common/notification/notifications-form'
import { LoadMoreList } from '@components/load-more-list'
import { NotificationMessage } from '@components/notification-box/notification-message'

interface Props {
  notificationEmails: [string, string][]
  history: EmailNotification[]
  email: string
  url: string
  onSuccess?: () => void
  readOnly?: boolean
}

export const ProductEmailNotifications = React.forwardRef<HTMLElement, Props>(
  ({ onSuccess, readOnly, history, url, email, notificationEmails }, ref) => (
    <Card innerRef={ref}>
      <CardBody>
        <h4 className="mt-0 text-secondary mb-3">Historia powiadomień e-mail</h4>
        <LoadMoreList limit={5} options={history} className="client-history-list">
          {row => (
            <NotificationMessage
              title="Historia powiadomień e-mail"
              notification={row}
              notificationKind="email"
              key={row.id}
              icon="uil-envelope"
            />
          )}
        </LoadMoreList>
        {!readOnly && (
          <ProductEmailNotificationsForm
            onSuccess={onSuccess}
            url={url}
            email={email}
            notificationEmails={notificationEmails}
          />
        )}
      </CardBody>
    </Card>
  ),
)

import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { EndlessHolidayProductsTableRow } from '@modules/endless-holiday/configuration/products/table-row'

interface Props {
  transactions: any[]
  setFilters: (filters: BaseTableFilters) => void
  filters: TableFilters
  isLoading: boolean
}

const headerValues: TableHeaderValue[] = [
  { sortField: 'name', title: 'Numer' },
  { title: 'Źródło' },
  { title: 'Klient' },
  { title: 'Status' },
  { title: 'Sprzedawca' },
  { title: '' },
]

export const EndlessHolidayProductsTable: React.FC<Props> = ({ setFilters, transactions, filters, isLoading }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {transactions.map((transaction: any) => (
      <EndlessHolidayProductsTableRow key={transaction.id} transaction={transaction} />
    ))}
  </Table>
)

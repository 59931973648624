import * as React from 'react'
import { ReceptionBookingClientWallet, ReceptionBookingWalletDetails } from '@models/reception'

import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateBookingWalletDetails } from '@store/slices/reservations-slice'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  wallet: ReceptionBookingClientWallet
  children: React.ReactNode
}

export const ReservationVoucherRemove = ({ wallet, children }: Props): React.ReactNode => {
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleRemove } = useApiRequest(async () => {
    addSuccessNotification('Voucher został odpięty od rezerwacji')
    dispatch(updateBookingWalletDetails(await commonObjectDelete<ReceptionBookingWalletDetails>(wallet.urls.assigment)))
  })

  if (!wallet.can_remove) return null

  return (
    <CommonObjectConfirmAction
      isLoading={isLoading}
      title="Odpenij voucher"
      handleAccept={handleRemove}
      submitLabel="Odepnij"
      buttonAcceptClassName="btn btn-danger"
      message={
        <div className="d-flex flex-column">
          <div>Czy na pewno chcesz odpiąć</div>
          <IconWithText
            icon="uil-tag-alt"
            wrapperClassNames="my-1"
            text={
              <span>
                <strong>{wallet.name}</strong>
                <span className="fw-semi-bold"> {wallet.code}</span>
              </span>
            }
          />
          od rezerwacji?
        </div>
      }
    >
      {children}
    </CommonObjectConfirmAction>
  )
}

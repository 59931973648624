import { useHandleAxiosFormErrors } from '@helpers/handle-axios-errors'
import * as React from 'react'

export const useHandleRtkQueryError = (
  error,
  isError,
  setError: (key: string, message: { type: string; message: string }) => void,
) => {
  const handleAxiosFormErrors = useHandleAxiosFormErrors()

  React.useEffect(() => {
    if (isError) {
      handleAxiosFormErrors(error, setError)
    }
  }, [isError, error, setError])
}

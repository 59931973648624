import * as React from 'react'
import { Button, Col, CustomInput, Row } from 'reactstrap'
import { FormInput, FormPlain } from '@hyper/forms'
import { GusButton } from '@components/gus-button'
import { CompanyDataFromGus } from '@models/sale'
import { useFormContext, useWatch } from 'react-hook-form'
import { ClientUser } from '@models/clients'
import { extractInnerRef } from '@helpers/forms'
import { InvoiceData } from '@components/products/product-invoice-card'
import classNames from 'classnames'

interface Props {
  client?: ClientUser | undefined
  invoiceData?: InvoiceData | undefined
  label?: string
  initHasInvoice?: boolean
  allowChangeInvoiceType?: boolean
  hideInvoiceCheckbox?: boolean
  hideInvoiceCompany?: boolean
  wrapperClassName?: string
}

export const InvoiceForm: React.FC<Props> = ({
  client,
  invoiceData,
  initHasInvoice = false,
  label = 'Chcę fakturę VAT',
  allowChangeInvoiceType = true,
  hideInvoiceCheckbox,
  hideInvoiceCompany,
  wrapperClassName,
}) => {
  const [hasInvoice, setHasInvoice] = React.useState(() => initHasInvoice || false)

  const { register, getValues, setValue, control } = useFormContext()

  const clearValue = () => {
    setValue('invoice_nip', '')
    setValue('invoice_company', '')
    setValue('invoice_street', '')
    setValue('invoice_postcode', '')
    setValue('invoice_city', '')
  }

  const toggleInvoice = () => setHasInvoice(state => !state)

  const setInvoiceData = () => {
    const data = invoiceData ?? client?.profile
    if (!data) return
    setValue('invoice_type', data.invoice_type || 'company')
    setValue('invoice_nip', data.invoice_nip)
    setValue('invoice_company', data.invoice_company)
    setValue('invoice_street', data.invoice_street)
    setValue('invoice_postcode', data.invoice_postcode)
    setValue('invoice_city', data.invoice_city)
  }

  const handleGusDataSave = (companyData: CompanyDataFromGus) => {
    setValue('invoice_company', companyData.name)
    setValue('invoice_street', companyData.street_address)
    setValue('invoice_postcode', companyData.postal_code)
    setValue('invoice_city', companyData.city)
  }

  React.useEffect(() => {
    if (hasInvoice) {
      setInvoiceData()
    } else {
      clearValue()
    }
  }, [hasInvoice])

  React.useEffect(() => {
    if (!client || !client.profile?.invoice) return

    setValue('invoice_type', client.profile.invoice_type)
    setValue('invoice_nip', client.profile.invoice_nip)
    setValue('invoice_company', client.profile.invoice_company)
    setValue('invoice_street', client.profile.invoice_street)
    setValue('invoice_postcode', client.profile.invoice_postcode)
    setValue('invoice_city', client.profile.invoice_city)

    setHasInvoice(true)
  }, [client])

  React.useEffect(() => {
    if (!hasInvoice) setHasInvoice(!!invoiceData?.invoice_nip)
  }, [invoiceData])

  const getNip = () => getValues('invoice_nip')
  const invoiceType = useWatch({ control, name: 'invoice_type' })

  return (
    <Row
      className={classNames('bg-grey form-transparent border-top pt-2', wrapperClassName)}
      style={{ marginLeft: -15, marginRight: -15 }}
    >
      {!hideInvoiceCheckbox && (
        <Col md={12} className="mb-2">
          <CustomInput
            type="checkbox"
            id="invoice"
            checked={hasInvoice}
            onChange={toggleInvoice}
            name="invoice"
            label={label}
          />
        </Col>
      )}

      {hasInvoice && (
        <>
          {allowChangeInvoiceType && (
            <FormPlain name="invoice_type" formGroupClassName="d-flex mt-2">
              <label className="mr-3">Rodzaj faktury: </label>
              <div className="d-flex">
                <CustomInput
                  className="mr-2"
                  type="radio"
                  value="company"
                  {...extractInnerRef(register('invoice_type'))}
                  id="invoice_type_company"
                  label="Firma"
                />
                <CustomInput
                  type="radio"
                  value="individual"
                  {...extractInnerRef(register('invoice_type'))}
                  id="invoice_type_individual"
                  label="Osoba prywatna"
                />
              </div>
            </FormPlain>
          )}
          <Col md={hideInvoiceCompany ? 12 : 5} className="px-0 d-flex">
            <FormInput
              colSize={12}
              label={invoiceType !== 'company' ? 'PESEL' : 'NIP'}
              name="invoice_nip"
              placeholder="Wpisz NIP"
            />
            {invoiceType === 'company' && (
              <GusButton
                className="mt-label text-nowrap"
                setCompanyData={handleGusDataSave}
                getNip={getNip}
                button={<Button color="primary">Pobierz dane z GUS</Button>}
              />
            )}
          </Col>

          {!hideInvoiceCompany && (
            <FormInput
              colSize={8}
              label={invoiceType === 'company' ? 'Nazwa firmy' : 'Imię i nazwisko'}
              name="invoice_company"
              placeholder={invoiceType === 'company' ? 'Wpisz nazwę firmy' : 'Wpisz imię i nazwisko'}
            />
          )}

          <FormInput colSize={5} label="Ulica" name="invoice_street" placeholder="Wpisz adres" />
          <FormInput colSize={3} label="Kod pocztowy" name="invoice_postcode" placeholder="_ _ - _ _ _" />
          <FormInput colSize={4} label="Miasto" name="invoice_city" placeholder="Wpisz miasto" />
        </>
      )}
    </Row>
  )
}

import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { SubscriptionCreateFormInputs } from '@modules/subscription/create/modal'
import { PackageSummarySection, PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { Row } from 'reactstrap'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { formatPrice } from '@helpers/utils'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useAppData } from '@components/hooks/use-app-data'
import { ClientUser } from '@models/clients'
import { ProductSaleSummaryInvoice } from '@components/sale/product-sale-summary-invoice'
import { SubscriptionCreateFormSummaryAdditionalOption } from '@modules/subscription/create/steps/summary-additional-options'

export const SubscriptionCreateFormSummary = (): JSX.Element => {
  const subscriptionAppData = useSubscriptionAppData()
  const appData = useAppData()
  const methods = useFormContext<SubscriptionCreateFormInputs>()

  const data = methods.getValues()

  const selectedProduct = subscriptionAppData?.products.find(row => row.id === parseInt(data.product, 10))

  const [recommender, setRecommender] = React.useState<ClientUser | null>(null)

  const { action: fetchClients } = useApiRequest(async () => {
    setRecommender(await commonObjectGet(`${appData.urls.clients.clients}/${data.recommender_client}`))
  })

  React.useEffect(() => {
    if (data.recommender_client) {
      fetchClients()
    }
  }, [data.recommender_client])

  const isOptionAdded =
    data.allow_subscription_code_localization_convert || data.allow_change_client_data || data.with_zoo_borysew_tickets

  const hasAdditionalClient = !!data.clients.length

  return (
    <div className="pb-2">
      <h4 className="mt-0 text-secondary">Podsumowanie sprzedaży</h4>
      <Row>
        <PackageSummarySection title="Dane klienta" className="col-6">
          <PackageSummarySectionDataRow title="Imię i nazwisko:" content={data.client?.name} />
          <PackageSummarySectionDataRow title="Adres e-mail:" content={data.client?.email} />
          <PackageSummarySectionDataRow title="Numer telefonu:" content={data.client?.phone} />
        </PackageSummarySection>
        {data.invoice_nip && <ProductSaleSummaryInvoice />}

        {hasAdditionalClient && (
          <div className="col-12 px-0">
            <PackageSummarySection title="Dane dodatkowego klienta" className="col-6">
              <PackageSummarySectionDataRow title="Imię i nazwisko:" content={data.clients[0].name} />
              <PackageSummarySectionDataRow title="Adres e-mail:" content={data.clients[0].email} />
              <PackageSummarySectionDataRow title="Numer telefonu:" content={data.clients[0].phone} />
            </PackageSummarySection>
          </div>
        )}
      </Row>

      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Parametry sprzedaży" className="col-12">
          <PackageSummarySectionDataRow
            title="Produkt:"
            content={selectedProduct?.name ?? 'Brak'}
            titleFlex="col-3"
            contentFlex="col-9"
          />
          <PackageSummarySectionDataRow
            title="Cena brutto:"
            content={formatPrice(selectedProduct?.price_brutto ?? 0)}
            titleFlex="col-3"
            contentFlex="col-9"
          />
          {isOptionAdded && selectedProduct && (
            <SubscriptionCreateFormSummaryAdditionalOption selectedProduct={selectedProduct} />
          )}

          <PackageSummarySectionDataRow
            title="Data ważności:"
            content={toDefaultDateFormat(data.expire_after)}
            titleFlex="col-3"
            contentFlex="col-9"
          />

          {data.auto_cancel_after_date && (
            <PackageSummarySectionDataRow
              title="Auto anulowanie po:"
              content={toDefaultDateFormat(data.auto_cancel_after_date)}
              titleFlex="col-3"
              contentFlex="col-9"
            />
          )}
        </PackageSummarySection>
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Informacje handlowe" className="col-12">
          <Row>
            <div className="col-6">
              <PackageSummarySectionDataRow title="Sprzedawca:" content={data.seller?.label ?? 'Brak'} />
              <PackageSummarySectionDataRow
                title="Nazwa promocji:"
                content={data.promotion_marketing?.label ?? 'Brak'}
              />
            </div>
            <div className="col-6">
              <PackageSummarySectionDataRow title="Źródło:" content={data.source_marketing?.label ?? 'Brak'} />
              <PackageSummarySectionDataRow title="Polecający:" content={recommender?.name ?? 'Brak'} />
            </div>
          </Row>
        </PackageSummarySection>
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Link do płatności" className="col-12">
          <p className="opacity-75">
            Link do opłacenia sprzedaży zostanie wysłany na adres e-mail Gościa{' '}
            <strong className="text-dark">{data.client?.email}</strong>
          </p>
        </PackageSummarySection>
      </Row>
    </div>
  )
}

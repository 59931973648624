import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { useForm } from 'react-hook-form'
import { Form } from '@hyper/forms/form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { FormRadio, FormRadioGroup } from '@hyper/forms/form-radio'
import { useBandReadersList } from '@modules/reception/common/band-reader-select/use-band-readers-list'
import { getIntValue } from '@helpers/utils'
import { BandReader } from '@models/booking'

interface FormInputs {
  band_reader: string
}

interface Props extends BaseModalProps {
  bandReader: number
  onBandReaderChange: (bandReader: number) => void
}

export const BandScanningScannerConfigurationModal: React.FC<Props> = ({
  toggleIsVisible,
  bandReader,
  onBandReaderChange,
}) => {
  const bandReaders = useBandReadersList()

  const methods = useForm<FormInputs>({
    defaultValues: {
      band_reader: bandReader.toString(),
    },
  })

  const newScanFlowBandReaders = bandReaders.filter((reader: BandReader) => reader.new_scan_flow)

  const onSubmit = async (payload: FormInputs) => {
    onBandReaderChange(getIntValue(payload.band_reader))
    toggleIsVisible()
  }

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>Wybór czytnika kodowania</ModalHeader>
      <ModalBody className="pb-1">
        <div className="d-flex">
          <div className="col-5 font-weight-bold mt-2">Wybierz czytnik:</div>
          <div className="col-7">
            <FormRadioGroup
              name="band_reader"
              className="d-flex flex-column"
              formPlainProps={{ formGroupClassName: 'pb-0' }}
            >
              {newScanFlowBandReaders.map(reader => (
                <FormRadio key={reader} name="band_reader" value={reader.id} label={reader.name} className="mt-2" />
              ))}
            </FormRadioGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <Button className="btn btn-green">Zatwierdź</Button>
      </ModalFooter>
    </Form>
  )
}

import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { PackageWholesaleDetailsSubscriptionsRow } from '@modules/package-wholesale/details/subscriptions/row'
import { Subscription } from '@modules/subscription/models'
import { Card, CardBody } from 'reactstrap'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'

interface Props {
  subscriptions: Subscription[]
}

const headerValues: TableHeaderValue<Subscription>[] = [
  { title: 'Numer', sortField: 'number' },
  { title: 'Klient', sortField: 'email' },
  { title: 'Status', sortField: 'status' },
  { title: 'Kwota brutto' },
  { title: 'Pozostało do zapłaty' },
  { title: 'Produkt' },
  { title: 'Ilość kodów', sortField: 'type_value' },
  { title: 'Opcje' },
  { title: 'Data założenia', sortField: 'created' },
]

export const PackageWholesaleDetailsSubscriptions = ({ subscriptions }: Props): JSX.Element => {
  const setFilters = () => console.log('fake SetFilters')
  const filters: TableFilters = {
    search: '',
    ordering: '-created',
    page: 1,
    page_size: 9999,
  }

  const { products } = useSubscriptionAppData()

  return (
    <Card>
      <CardBody>
        <h4 className="mt-0 text-secondary mb-3">Pakiety prywatne</h4>

        <Table
          className="table-hover"
          headerValues={headerValues}
          isLoading={false}
          showPagination={false}
          filters={filters}
          tableWrapperClassName="table-responsive"
          setFilters={setFilters}
        >
          {subscriptions.map(subscription => (
            <PackageWholesaleDetailsSubscriptionsRow
              products={products}
              key={subscription.id}
              subscription={subscription}
            />
          ))}
        </Table>
      </CardBody>
    </Card>
  )
}

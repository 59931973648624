import * as React from 'react'

import TimelineMask from '@modules/reservations/timeline/timeline-mask'

import timelineHelper from '@helpers/timeline-helper'
import dateHelper from '@helpers/date-helper'
import { Apartment, ApartmentBookingFilter } from '@models/apartment'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import TimelineContentDroppable from './timeline-content-droppable'
import TimelineDragLayer from '@modules/reservations/timeline/timeline-drag-layer'
import TimelineBookingModal from '@modules/reservations/timeline/modals/timeline-booking-modal'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { setCalendarDates } from '@store/actions/timeline-actions'
import TimelineBackground from '@modules/reservations/timeline/timeline-background'
import TimelineCols from '@modules/reservations/timeline/timeline-cols'
import TimelineSidebarView from '@modules/reservations/timeline/timeline-sidebar'
import TimelineScrollButtons from '@modules/reservations/timeline/timeline-scroll-buttons'
import TimelineVirtualizeItems from '@modules/reservations/timeline/timeline-virtualize-items'
import TimelineDaysNavbarWrapper from '@modules/reservations/timeline/timeline-days-navbar-wrapper'
import TimelineScroller from '@modules/reservations/timeline/timeline-scroller'
import TimelineWarningBookingModal from '@modules/reservations/timeline/modals/timeline-warning-booking-modal'
import TimelineScrollbar from '@modules/reservations/timeline/timeline-scrollbar'
import { TimelineRows } from '@modules/reservations/timeline/timeline-rows'
import { TimelineNotRentableDaysMask } from '@modules/reservations/timeline/timeline-not-rentable-days-mask'

interface Props {
  apartments: Apartment[]
  filters: ApartmentBookingFilter[]
  accommodationTypeIds: string[]
  resort: string
}

export const TimelineContentView: React.FC<Props> = ({ apartments, filters, resort, accommodationTypeIds }) => {
  const dispatch = useAppDispatch()
  const infiniteScrollerRef = React.useRef<HTMLDivElement>(null)
  const dayWrapperRef = React.useRef<HTMLDivElement>(null)

  const [isSticky, setSticky] = React.useState(false)

  const calendarDates = useAppSelector((state: RootState) => state.timelineState.calendarDates)
  const bookings = useAppSelector((state: RootState) => state.timelineState.bookings).filter(bk => bk.apartment_id)

  React.useEffect(() => {
    if (calendarDates.position === 'today') {
      scrollToToday()
    }
  }, [calendarDates, infiniteScrollerRef.current])

  React.useEffect(() => {
    const onScroll = () => {
      setSticky(!!infiniteScrollerRef.current && infiniteScrollerRef.current.getBoundingClientRect().top < 70)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  function scrollToToday(): void {
    if (infiniteScrollerRef.current) {
      const { month, year } = calendarDates.dates[0]
      infiniteScrollerRef.current.scrollLeft = timelineHelper.getScrollTodayPos(year, month)
    }
  }

  const onReachLeft = (): void => {
    dispatch(
      setCalendarDates(
        dateHelper.getPrevCalendarDate(dateHelper.getMiddleCalendarDate(calendarDates.dates)),
        'reach-left',
      ),
    )
  }

  const onReachRight = (): void => {
    dispatch(
      setCalendarDates(
        dateHelper.getNextCalendarDate(dateHelper.getMiddleCalendarDate(calendarDates.dates)),
        'reach-right',
      ),
    )
  }

  const sortedApartments = React.useMemo(() => {
    const result = [...apartments]
    return result.sort((a, b) => Number(a.is_virtual) - Number(b.is_virtual))
  }, [apartments])

  return calendarDates.dates.length ? (
    <DndProvider backend={HTML5Backend}>
      <div className="d-flex">
        <TimelineSidebarView apartments={sortedApartments} resort={resort} />
        <div id="timeline-content" className="calendar__timeline-content">
          <TimelineScroller
            onReachRight={onReachRight}
            onReachLeft={onReachLeft}
            infiniteScrollerRef={infiniteScrollerRef}
            dayWrapperRef={dayWrapperRef}
            width={timelineHelper.getRowWidth(calendarDates.dates)}
          >
            <TimelineDaysNavbarWrapper
              accommodationTypeIds={accommodationTypeIds}
              isSticky={isSticky}
              infiniteScrollerRef={infiniteScrollerRef}
              dayWrapperRef={dayWrapperRef}
              calendarDates={calendarDates.dates}
              resort={resort}
            />
            <TimelineContentDroppable allApartments={apartments} isSticky={isSticky}>
              <>
                <TimelineBackground
                  width={timelineHelper.getRowWidth(calendarDates.dates)}
                  height={timelineHelper.getColHeight(apartments)}
                />
                <TimelineMask apartments={apartments} renderedDates={calendarDates.dates} />
                <TimelineNotRentableDaysMask
                  renderedDates={calendarDates.dates}
                  colHeight={timelineHelper.getColHeight(apartments)}
                  colWidth={timelineHelper.getColWidth()}
                />
                <TimelineCols
                  renderedDates={calendarDates.dates}
                  colHeight={timelineHelper.getColHeight(apartments)}
                  colWidth={timelineHelper.getColWidth()}
                />
                <TimelineRows renderedDates={calendarDates.dates} apartments={apartments} />
                <div className="calendar-content__items">
                  <TimelineDragLayer allApartments={apartments} />
                  <TimelineVirtualizeItems
                    bookings={bookings}
                    renderedDates={calendarDates.dates}
                    allApartments={apartments}
                    infiniteScrollerRef={infiniteScrollerRef}
                    filters={filters}
                    resortId={resort}
                  />
                </div>
              </>
            </TimelineContentDroppable>
          </TimelineScroller>
          <TimelineScrollButtons />
          <TimelineScrollbar infiniteScrollerRef={infiniteScrollerRef} />
          <TimelineBookingModal />
          <TimelineWarningBookingModal />
        </div>
      </div>
    </DndProvider>
  ) : null
}

import * as React from 'react'
import { CustomInput } from 'reactstrap'
import { FormPlain, FormPlainError, FormPlainProps } from '@hyper/forms/index'
import { CustomInputProps } from 'reactstrap/es/CustomInput'
import { extractInnerRef } from '@helpers/forms'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'

interface Props extends Omit<CustomInputProps, 'type' | 'id'> {
  name: string
  value: string | boolean
  defaultValue?: string | number
  id?: string | number
  label?: React.ReactNode
}

export const FormRadio = ({ name, value, defaultValue, label, ...inputProps }: Props): React.ReactElement => {
  const { register } = useFormContext()
  const id = `${name}${inputProps.id ? `-${inputProps.id}` : `-${value}`}`

  return (
    <CustomInput
      {...inputProps}
      id={id}
      type="radio"
      label={label}
      value={value}
      {...extractInnerRef(register(name))}
    />
  )
}

interface FormRadioGroup {
  children: React.ReactNode
  label?: React.ReactNode
  name: string
  className?: string
  formPlainProps?: Omit<FormPlainProps, 'name'>
  withErrorRow?: boolean
}

export const FormRadioGroup = ({ children, className, withErrorRow, formPlainProps, label, name }: FormRadioGroup) => (
  <>
    <FormPlain name={name} {...formPlainProps}>
      {label && <label className="mb-2 fw-semi-bold">{label}</label>}
      <div className={classNames('d-flex mb-2', className)}>{children}</div>
    </FormPlain>
    {withErrorRow && <FormPlainError name={name} />}
  </>
)
